<template>
    <div>
      <Preloader v-if="preloader" />
      <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
      <v-card>
        <v-card flat>
          <v-snackbar v-model="snackbar" center top timeout="10000" type="success">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ lang.Ok }}
        </v-btn>
      </template>
    </v-snackbar>
  
          <v-form ref="form" @submit.prevent="addLatestVisitReportSettings"  v-model="valid" lazy-validation>
            <v-card class="pa-5">
              <p class="reportinfo">
                Latest Visit Report
              </p>
              <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                v-model="latestVisitReportStatus"
                label="Enable/Disable Latest Visit Report"
                ></v-checkbox>
                <v-text-field
                  v-model="emails"
                  :label="lang.Email"
                  required
                  :rules="emailrules"
                ></v-text-field>
              </v-col>
               </v-row>
              <v-btn class="mr-4" type="submit" color="mylightbutton">
                {{lang.submit }}
              </v-btn>
            </v-card>
          </v-form>
        </v-card>
      </v-card>
    </div>
  </template>
  <script>
  
  import axios from "axios";
  import Preloader from "../../components/PreLoader";
  import { locals } from "../../utils/locals";
  import rotate from '../../assets/rotedmobilepsd.jpg';
  export default {
    components: {
      Preloader,
    },
    name: "latest_visit_report",
    data() {
      return {
        rotate: rotate,
        currentProject: {},
        current_cam: {},
        preloader: false,
        alert: false,
        alertmessage: "",
        alertType: "",
        tab: 1,
        snackbar: false,
        text: "Something went wrong. Please reload and try again.",
        reporttype: "",
        emails: "",
        emailrules: [
           v => !!v || this.lang.required,
          v => /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[^\s,]+\.[A-Za-z]{2,4}){0,100}$/.test(v) || this.lang.EmailValid
         ],
        valid: true,
        Local :locals,  
        lang : {},
        hasEntryInDB:"",
        latestVisitReportStatus: 0,
        checkbox: false,
      };
    },
    mounted() {
       var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
  
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.current_cam = this.$store.state.currentCamera;
      this.userdata = JSON.parse(localStorage.getItem('userdata'))
       if(this.userdata.free_access == 0){
            this.useraccess = true
          }else{
            this.$router.push('/')
      }
      this.emails = this.userdata.email;
      this.fetchLatestReportSetting();
        },
    computed: {
    params() {
      return {
        user_id: this.userdata.id,
        emails: this.emails,
        report_status: this.latestVisitReportStatus,
      };
    },
  //   isResetButtonDisabled() {
  //   return this.hasEntryInDB.length === 0 ;
  // },
  // isButtonDisabled() {
  //   return this.reporttype.length === 0 ;
  // }
  },
    watch: {
      "$store.state.currentCamera": function () {
         let currentProject = JSON.parse(localStorage.getItem("currentProject"));
         this.currentProject = currentProject;
        this.current_cam = this.$store.state.currentCamera;
      },
    },
    methods: {
      addLatestVisitReportSettings() {
        console.log(this.params, "params");
        axios({
          url: "/drone/" + this.currentProject.id + "/save_latest_visit_report_settings",
          data: this.params,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.success) {
              if(this.latestVisitReportStatus == 0){
                this.snackbar = true;
                this.text = "Latest Visit Report has been disabled.";
              }else{
              this.snackbar = true;
              this.text = resp.data.success;
            }
              this.fetchLatestReportSetting();
            } else {
              this.snackbar = true;
              this.text = "Something went wrong, please try again.";
            }
          })
          .catch((err) => {
            console.log(err);
            this.snackbar = true;
            this.text = "Something went wrong. Please reload page and try again.";
          });
      },
      fetchLatestReportSetting() {
        axios({
          url:
            "/drone/"+ this.currentProject.id + "/fetch_latest_visit_report_settings",
          method: "POST",
          data: {user_id: this.userdata.id},
        }).then((resp) => {
          if (resp.data.success) {
            this.emails = resp.data.data[0].email;
            this.latestVisitReportStatus = resp.data.data[0].status;
          }
        }).catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = "Something went wrong. Please reload page and try again.";
        });
      },
    },
  };
  </script>
  <style>
  .reportinfo {
    font-size: larger;
    font-weight: 500;
    color: #ffc400;
  }
  .info_rec {
    position: relative;
    left: 2%;
  }
  .mdi-progress-question {
    font-size: 28px !important;
  }
  </style>