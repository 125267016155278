<template>
<div>
    <Preloader v-if="preloader" />
       
    <v-row>
          
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                  <template>
                   
                      <v-btn
      class="ma-2"
     color="mylightbutton"
     @click="goback"
    >
      {{lang.goback}}
       <v-icon right>mdi-undo-variant</v-icon>
        
    </v-btn>

                      <h3 v-if="vei.length > 0"> {{lang.VehicleEntry}} </h3>
                    <v-row v-if="vei.length > 0">

                       <v-col cols="4" md="4" v-for="i in vei"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      

                   <h3 v-if="dci.length > 0"> {{lang.deliverychalaimage}} </h3>
                    <v-row v-if="dci.length > 0">

                       <v-col cols="4" md="4" v-for="i in dci"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      

                   <h3 v-if="vnpi.length > 0"> {{lang.VehicleNumberPlate}} </h3>
                    <v-row v-if="vnpi.length > 0">

                       <v-col cols="4" md="4" v-for="i in vnpi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="vui.length > 0"> {{lang.vehivleunloading}} </h3>
                    <v-row v-if="vui.length > 0">

                       <v-col cols="4" md="4" v-for="i in vui"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="vbi.length > 0"> {{lang.VehicleEmpty}} </h3>
                    <v-row v-if="vbi.length > 0">

                       <v-col cols="4" md="4" v-for="i in vbi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="mi.length > 0"> {{lang.other}} </h3>
                    <v-row v-if="mi.length > 0">

                       <v-col cols="4" md="4" v-for="i in mi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                </template>

            </v-card>
        </v-col>
    </v-row>

    <v-snackbar
      v-model="alert"
      top
      right
      timeout="3000"
      :type="alertType"
    >
     {{ alertmessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          
          @click="alert = false"
        >
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>
</div>
</template>



<script>

import axios from 'axios'
import Preloader from '../../components/PreLoader'
import { locals } from "../../utils/locals";
export default {
   
    components: {
        Preloader 
    },
    computed: {
    // a computed getter
    
  },
    name: "EditTask",
    data() {
        return {
            alert: false,
          
            alertmessage :'',
            alertType : "error",
            preloader: false,
            project_name : 'ProjectName',
            projectid: 0,
            vei : [],
            dci : [],
            vnpi : [],
            vui : [],
            vbi : [],
            mi : [],
            latitude :  '24.7498085',
            longitude : '78.8139369',
            Local :locals,  
             lang : {},
           
        };
    },
    created() {

         var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];


           console.log(this.$route.params.id);
              
         this.userdata = JSON.parse(localStorage.getItem('userdata'));   


        let currentProject = JSON.parse(localStorage.getItem('currentProject'));
         console.log(currentProject);

        this.projectid = currentProject.id;
          this.latitude = currentProject.latitude;
          this.longitude = currentProject.longitude;
        this.initViewer();
        if(currentProject.material == 1){
          this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
        if (this.userdata.material_access == 1) {
            this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
         if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
             console.log(currentProject);
            this.projectid = currentProject.id;
             this.project_name = currentProject.project_name;
             this.latitude = currentProject.latitude;
             this.longitude = currentProject.longitude;
      
                   

           
        }
    },
  // mounted() {
  //   this.userdata = JSON.parse(localStorage.getItem('userdata'));
  //   if (this.userdata.open_access == 1) {
  //     this.$router.push('/dashboard')
  //   }
  // },
    methods: {
        validate() {
            this.$refs.form.validate()
             
            
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false;
             var entery_id = this.$route.params.id;

            this.vei = []
            this.dci = []
            this.vnpi = []
            this.vui = []
            this.vbi = []
            this.mi = []
           

            axios({
                    url: 'material/inventory/images',
                    method: 'get',
                    params : { enteryid : entery_id}
                })
                .then(resp => {
                     console.log("headings",resp);

                      resp.data.forEach(item => {
                             
                       
                    var url = item.imagepath;
           var s = url.split(".001/");

                var s1 = s[1];

                 console.log(s1);

                          var p = {
                                  "key" : s1,
                                  "exp" : "30 minutes",
                                  "project_id" : this.projectid
                                  }
           axios({
                    url: '/get_sign_url_by_key',
                    params : p,
                    method: 'GET'
                })
                .then(res => {

                     console.log(res.data.success.url);

                      item.imagepath = res.data.success.url;

                     //return "https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001/PRJCT372/Inv/vei/Img_1624863593123.jpg?X-Amz-Content-Sha256=e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASKGTOTLMJMGHFLXM%2F20210701%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20210701T162921Z&X-Amz-SignedHeaders=Host&X-Amz-Expires=172800&X-Amz-Signature=1a1da14fc5fa9a5088017b515fb053e145dbac615533143d3bbe01f13db6ec7b";
                      if(item.dc_no == "vei"){
                                 this.vei.push(item);
                         }

                         if(item.dc_no == "dci"){
                                 this.dci.push(item);
                         }

                         if(item.dc_no == "vnpi"){
                                 this.vnpi.push(item);
                         }
                         
                         if(item.dc_no == "vui"){
                                 this.vui.push(item);
                         }

                         if(item.dc_no == "vbi"){
                                 this.vbi.push(item);
                         }

                         if(item.dc_no == "mi"){
                                 this.mi.push(item);
                         }
             
             })    
                    });

                    console.log(this.vei);
                      
                })
                .catch(err => {
                     console.log(err);
                     this.alert = true;
                    this.alertmessage = "Something is went wrong please try again.";
                    this.alertType = "error"
                })
            
        },

        dateFormate(date){
             var dateIST = new Date(date).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
             return dateIST;
        },

            getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
            var dLon = this.deg2rad(lon2-lon1); 
            var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = (R * c); // Distance in km
             var v = d.toFixed(2);
             console.log(lat1, lon1, lat2, lon2);
             console.log(v)
            return v;
            },

    deg2rad(deg) {
    return deg * (Math.PI/180)
    },
    goback(){
        this.$router.push("/dashboard/material/inventories")
    }
     
       
    }
};
</script>

<style>
.remark{
    position:absolute;
    
    right: 0;
}
.remarkbox{
    position:relative;
}
.col-md-4.col-4 img{
    max-width:100%;
}
</style>
