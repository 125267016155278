<template>
    <div>
        <Preloader v-if="preloader" />
        <div class="secondheader">
            <div class="searchbar">
                <input type="text"   v-model="searchtext"  @keyup="filterObjects"
            @keydown="filterObjects"
             class="textsearch_box poppins-medium" placeholder="Search for Project/Location">
                <button class="seach-bittons tbutton poppins-medium"> SEARCH </button>
            </div>
            <div class="viewbuttuns">
                <span class="mapview iconn poppins-medium" :class="gridViewtoggle?'':'active'" @click="viewswitch('map')">
                    Map
                </span>
                <span class="sepratore"></span>
                <span class="gridview iconn poppins-medium" :class="gridViewtoggle?'active':''" @click="viewswitch('grid')">
                    Grid
                </span>
            </div>
        </div>
        <div class="maincard">

            <div class="card-box">

                <div class="haadtile">
                    <p class="poppins-semibold projecttitle "> Projects</p>

                </div>
                <div class="filter">
                  <label class="chech_container filtered greenf poppins-medium"> In Progress Projects <img :src="bluepin" class="pin"> ({{runnintcount}})
                  <input type="checkbox" v-model="runnibgcheck" @change="selectprojectfilteritem('Running')">
                   <span class="checkmarkt"></span>
               </label>

               <label class="chech_container filtered redf poppins-medium"> Completed Projects <img :src="whitepin" class="pin"> ({{completed}})
                  <input type="checkbox" v-model="completecheck" @change="selectprojectfilteritem('Completed')">
                   <span class="checkmarkt"></span>
               </label>
  <div class="dropdown">

    <div class="dropdown-toggle poppins-semibold" >
       
         <label class="chech_container">
            <input type="checkbox" v-model="selectall" @change="selectItemall(event)">
            <span class="checkmarkt"></span>
            </label>
            <span @click="toggleDropdown" class="selectlable"> Show All Projects  <span class="openner"></span></span>
    </div>
    <div v-if="isOpen" class="dropdown-menu poppins-medium">
      <ul>
        <template v-for="(item,index) in items" >
          <li  :key="index" class="sepratoree">
                        <label class="chech_container"> {{ item.cat.name }}
            <input type="checkbox" v-model="item.cat.checked"  @change="selectItem(item)">
            <span class="checkmarkt"></span>
            </label>
            <ul v-if="item.subcate && item.subcate.length">
              <li v-for="child in item.subcate" :key="child.value">
                <label class="chech_container"> {{ child.value }}
            <input type="checkbox" v-model="child.checked" @change="selectItem(child)">
            <span class="checkmarkt"></span>
            </label>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </div>
                </div>

                <div class="card-inner-box">
                    <div
        cols="12"
        md="6"
        class="maps"
        v-if="loopcout && !gridViewtoggle"
      >
         <gmap-map :center="center" :zoom="5" class="mapite" map-type-id="hybrid" :labels="true">
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window>

      <gmap-marker :key="i" v-for="(m,i) in markers"  :position="m.position" :clickable="true"  :icon="m.project_active == 1?bluepin:whitepin" @click="toggleInfoWindow(m,i)"></gmap-marker>
    </gmap-map>
</div>

<v-row v-if="loopcout && gridViewtoggle" class="tdash">
    <v-col
      v-for="(n, index) in projects"
      :key="index"
      :class="n.active == '1'? 'active'+actt  :'notactive' "
      class="proejectitems"
      cols="3"
    >
    <a  :href="`/tpls/modules?p=${n.id}`">
      <span class="indicatetore" :class="n.project_active == '1'? 'active'  :'notactive' "></span>
        <v-img
         :src="n.thumbnail_name"
         aspect-ratio="1.5"
         class="grey lighten-2"
         v-if="actt == n.active || both"

      > 
            <!-- <v-icon
      class="projectcamera"
      v-for="(c, indexOf ) in n.camm"
      @click="selectCamere(c,n,indexOf)"
      :key="c.id"
    >
      mdi-camera
    </v-icon>

      <v-icon
      class="projectcamera"
      
      v-for="(c, indexOf ) in n.camm1"
      
      @click="opentimelapsevideo(c,n,indexOf)"
      :key="c.id"
     >
      mdi-video-outline
    </v-icon> -->
    

    <!-- <span class="indicatetore" :class="n.active == '1'? 'active'  :'notactive' "></span> -->
       <span class="pnamejtri poppins-semibold "> {{n.project_name }} </span>
       
      </v-img>
      </a>
     </v-col>
    </v-row>
   
     <div class="paymentinfobox" v-if="paymentinfoshow">
           <div class="infoc poppins-semibold"> 
            Please process the pending payments to continue using the services smoothly. Contact admin for more information.
           </div>
     </div>
                </div>
            </div>

        </div>
        
        <TataFooter />
    </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
//import Sidebar from '../../components/SettingSidebar'
import loading from '../../assets/done.gif'
 import { locals } from "../../utils/locals"
 import bluepin from "../../assets/tata/icons/green.png"
 import whitepin from "../../assets/tata/icons/red.png"
 import TataFooter from '../../components/Headers/TataFooter'
export default {
    components: {
        Preloader,
        TataFooter
        
    },
    name: "Viewer",
    data() {
        return {
          whitepin:whitepin,
          bluepin:bluepin,
            loading: loading,
       preloader: false,
       projectdta : [],
       coi: 0,
       loopcout : false,
       dats : [],
       center: { lat: 20.5937, lng:  78.9629},
       zoom: 5,
       markers: [],
       currentPlace: null,
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
        	content: '',
            //optional: offset infowindow so it visually sits nicely on top of our marker
            pixelOffset: {
              width: 0,
              height: -35
            }
          },
          both : true,
          actt : '',
          projectsType : " ",
          vv : '',
          messagetype : '',
          message : '',
          messagealert : false,
          videoo : false, 
           Local :locals,  
           lang : {},
            isOpen: false,
      items: [],
      selectall: true,
      gridViewtoggle : false,
      searchtext :'',
      projects : {},
      organization_id :0,
      selectedcheckItems : [],
      finalfilterarray : [],
      isOpenp : false,
      selecteditem :'All Projects',
      checkprogress : false,
      runnibgcheck: true,
      completecheck:true,
      runnintcount : 0,
      completed : 0,
      paymentinfoshow : false,

        }
    },
    mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 

       //let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
    // if (this.userdata.open_access == 1) {
    //   this.$router.push('/dashboard')
    // }        
      //  this.projectid = currentProject.id;
      //  this.organization_id = currentProject.organization_id;
    
      //  this.activelink = this.$route.path;
      //  this.current_cam = this.$store.state.currentCamera;
        this.initViewer();
        // this.geolocate();
  },
  watch: {
  '$store.state.currentCamera': function() {

     let currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.projectid = currentProject.id;
     this.organization_id = currentProject.organization_id;
     this.current_cam = this.$store.state.currentCamera;
    
  }
  },
    computed: {
    selectedItems() {
      return this.flattenItems.filter(item => item.checked);
    },
    flattenItems() {
      const flatten = (items) => {
        return items.reduce((acc, item) => {
          acc.push(item);
          if (item.children && item.children.length) {
            acc.push(...flatten(item.children));
          }
          return acc;
        }, []);
      };
      return flatten(this.items);
    }
  },
    methods: {
        initViewer() {
     // console.log("init Viewer");
       this.preloader = false;
       this.getprojects(); 
       
      
    },
    viewswitch(view){

        if(view == 'grid'){
            
            this.gridViewtoggle = true;
        }else{
            this.gridViewtoggle = false;
        }
    },
    getprojects(){

        this.preloader = true;
         axios.get('/project')
           .then(resp => {
                 console.log("global_dashboard", resp);
                // this.preloader = false;
                 this.dats = resp.data.success;
              
                 let currentProject = this.dats[0];
                 console.log("currentProject",currentProject);
                 this.organization_id = currentProject.organization_id;

                 this.projectdta = resp.data.success;

                 resp.data.success.forEach( i => {
               
                   if(i.project_active == 1){
                    this.runnintcount++;
                   }else{
                    this.completed++;
                   }
                  })

                    
                  

                 this.projects = resp.data.success;
                 this.coi = this.dats.length;

                 this.getCatgory();
                 this.markerMaker(this.dats);
                
            //     var n = 0;
            //        var sa = '';
            //      this.dats.forEach( i => {
               
            //     if(i.active){
            //       sa = '<a  href="/dashboard?p='+i.id+'" target="_blank"> Go To Dashboard </a>';
            //     }

            //            this.markers[n] = { 'position': {
            //   'lat': parseFloat(i.lat),
            //   'lng': parseFloat(i.long),
            // },
            // 'infoText' : '<p style="text-align:center"><strong>'+i.project_name+'</strong><br><strong>'+i.city+'</strong><br>'+sa+'</p>'
            //            }

            //     //if(i.camm.length > 0){
            //               this.getImageUrl(n);
            //            // }else{
            //              //this.projectdta[n].image_url = loading;
            //            // }

            //             n++;
            //      });

            //       console.log("data url",this.projectdta);
            //       console.log('markers',this.markers);
              
           })
          .catch(err => {
            console.log("global_dashboard ",err)
           }) 
    },
    getLattersImage(camdata, n){

         //  this.preloader = false;
               console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata+'/latest_images', method: 'GET' })
          .then(resp => {
           // this.preloader = false;
             console.log("Lattest Image >>", resp);
             this.getImageUrl(resp.data.success[0].id, n);
           })
          .catch(err => {
            console.log("Error --> ", err);
           // console.log("Error --> ",this.preloader);
           // this.preloader = false;
          })
    },
    getImageUrl(n){
          

              if(this.coi == n+1){
             
               console.log("===>", this.coi, n+1);  

                  var v = this;
                
                   v.preloader = false;
                   v.loopcout = true;

                    v.projectdta.sort(function(a, b){
                      return b.project_active - a.project_active;
                    }); 
              }
  
          
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {

        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        });

      

    },
    toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.infoText;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;

            }
          },
         setproejecttype(){
               
               if(this.projectsType==' '){
                   this.both = true;
                   this.actt ==  ' '
               }else {
                this.both = false;         
                this.actt = this.projectsType;
         
               }
         },
        selectCamere(camera,project,index){
                 console.log(camera, project);
                 window.open("/dashboard?c="+camera.id+"&p="+project.id+"&i="+index, '_blank');
                // window.location.href();
                
         },
         opentimelapsevideo(cdata){

           this.videoo = true;
           
           console.log("passing camm1 data",cdata);

           var key = cdata.key;
           console.log("passing key",key);
         axios({ url: '/get_sign_url_by_key?key='+key+'&exp=60 minutes&project_id='+this.projectid, method: 'get' })
           .then(resp => {
                  console.log("Sign  url resonse", resp);
                  if(resp.data.success.info){
                   
                    this.vv =  resp.data.success.url;

                  // this.messagetype ='success'
                  // this.message = 'Video not found'
                  // this.messagealert = true 
                    this.$refs.custome_timelapsvideo.src = resp.data.success.url;
                    console.log(this.vv);
                  }else{
                  this.messagetype = 'error'
                  this.message = 'Video not found'
                  this.messagealert = true 
                   this.$refs.custome_timelapsvideo.src = resp.data.success.url;
                   this.videoo = false;
                  }
          })
          .catch(err => {
            console.log("Sign Url images",err)
            
           }) 
         }, 
      
        toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    toggleDropdownp() {
      this.isOpenp = !this.isOpenp;
    },
    selectprojectfilteritem(item){
        this.selecteditem = item;
        this.isOpenp = false;

        // this.completecheck
        // this.runnibgcheck

        var d;

        if(this.completecheck && this.runnibgcheck){

          this.projects = this.projectdta;
          this.markerMaker(this.projectdta);   
      
        }else if(this.completecheck){

          d = this.projectdta.filter( i =>{
           return i.project_active == 0 ;
         });

         this.projects = d;
         this.markerMaker(d);   

        }else if(this.runnibgcheck){

         d  =  this.projectdta.filter( i =>{
           return i.project_active == 1;
         });
         this.projects = d;
         this.markerMaker(d);   
          
        }else{
          d = [];
          this.projects = d;
         this.markerMaker(d);   
        }
        
       
    },
    selectItem(item) {
        this.selectall = false;
      // Update child items
      var che;
      if (item.subcate && item.subcate.length) {
        item.subcate.forEach(child => {
          child.checked =  item.cat.checked;
          if(child.checked){
            console.log("clicked item loop", child.fieldselectedProject);
            che =  this.selectedcheckItems.includes(child.fieldselectedProject);
          if(!che){
            this.selectedcheckItems.push(child.fieldselectedProject);
          }

          }else{

            var index = this.selectedcheckItems.indexOf(child.fieldselectedProject);
            console.log("selected index",index,this.selectedcheckItems,child.fieldselectedProject);
           if (index > -1) { // only splice array when item is found
             this.selectedcheckItems.splice(index, 1); // 2nd parameter means remove one item only
            // console.log("finalfilterarray",this.finalfilterarray);
           }  
          //   child.fieldselectedProject.forEach(i =>{
          //   console.log("unchecked item ",i);
          //   var index = this.finalfilterarray.indexOf(i);
          //   console.log("selected index",index);
          //  if (index > -1) { // only splice array when item is found
          //    this.finalfilterarray.splice(index, 1); // 2nd parameter means remove one item only
          //    console.log("finalfilterarray",this.finalfilterarray);
          //   }
            
          //   })
          }
        });
      }else{
        if(item.checked){
        console.log("clicked item single", item.fieldselectedProject);
       // this.selectedcheckItems.push(item.fieldselectedProject);
         che =  this.selectedcheckItems.includes(item.fieldselectedProject);
          if(!che){
            this.selectedcheckItems.push(item.fieldselectedProject);
          }
       }else{

        var index = this.selectedcheckItems.indexOf(item.fieldselectedProject);
            console.log("selected index single",index,this.selectedcheckItems,item.fieldselectedProject);
           if (index > -1) { // only splice array when item is found
             this.selectedcheckItems.splice(index, 1); // 2nd parameter means remove one item only
           //  console.log("finalfilterarray",this.finalfilterarray);
           } 

        // item.fieldselectedProject.forEach(i =>{
        
        //     console.log("unchecked item single ",i);
        //     var index = this.finalfilterarray.indexOf(i);
        //     console.log("selected index",index);
        //    if (index > -1) { // only splice array when item is found
        //      this.finalfilterarray.splice(index, 1); // 2nd parameter means remove one item only
        //      console.log("finalfilterarray",this.finalfilterarray);
        //    }
       
        //     })
          }
      }

      //var  newArr = this.selectedcheckItems.flat();
      //this.finalfilterarray =  newArr;
      //console.log("sdjfhsjfjkf",this.selectedcheckItems);
      
      var arra  = this.selectedcheckItems.flat();
      this.finalfilterarray  =  this.removeDuplicates(arra);
      //console.log("sdjfhsjfjkf",this.finalfilterarray); 

     
      const filteredArray = this.projectdta.filter(obj1 =>
      this.finalfilterarray.some(obj2 => obj1.id === obj2)
); //this.finalfilterarray.filter(value => this.projectdta.includes(value));

      //  console.log("filteredArray",filteredArray); // Output: [3, 4, 5]   
        
        this.projects = filteredArray;
              
        this.markerMaker(filteredArray);         
       
       //this.projects = filtered;
      // if (filteredArray.length == 0) {
      //   this.projects = this.projectdta;
      //   //this.getprojects();
      // }
    },
    selectItemall(){

        console.log("e",this.selectall)

       // this.selectall = !this.selectall ;
        var check = false;
        if(this.selectall){
           check = true;
           this.projects = this.projectdta;
        }

        this.items.forEach(item =>{
             console.log("item",item);
             item.cat.checked = check;
         if (item.subcate && item.subcate.length) {
            item.subcate.forEach(child => {
              console.log("child",child);
            child.checked = check;
           // this.selectedcheckItems.push(child.fieldselectedProject);
           
          
            this.selectedcheckItems.push(child.fieldselectedProject);

            var xcd = this.removeDuplicates(this.selectedcheckItems);

            this.selectedcheckItems = xcd;
        

         });
         }
        })
       
        if(check){
          console.log("selectedcheckItems",this.selectedcheckItems);
          var arra = this.selectedcheckItems.flat();
          this.finalfilterarray  =  this.removeDuplicates(arra);

        }else{
          this.finalfilterarray = [];
        }
        const filteredArray = this.projectdta.filter(obj1 =>
      this.finalfilterarray.some(obj2 => obj1.id === obj2)
); //this.finalfilterarray.filter(value => this.projectdta.includes(value));

        console.log("filteredArray",filteredArray); // Output: [3, 4, 5]   
        
        this.projects = filteredArray;
              
        this.markerMaker(filteredArray); 
      },
      removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
},
    filterObjects() {
      var filter = this.searchtext.toLowerCase();
      var filtered = {};
      var markers = [];
     
      /// console.log(projectSearchdata);
      var keys = Object.keys(this.projectdta);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        var object = this.projectdta[key];

        var objectAsString = JSON.stringify(object).toLowerCase();
        //   console.log(objectAsString);
        if (
          key.toLowerCase().indexOf(filter) > -1 ||
          objectAsString.indexOf(filter) > -1
        ) {
          filtered[key] = object;
        }
      }
                  

       console.log("filtered",filtered);
       var result = Object.keys(filtered).map((key) => filtered[key]);
        console.log(result);
      if(result.length > 0){
        this.markerMaker(result);
      //   this.loopcout = false;
      // var n = 0;
      //              var sa = '';
      //              result.forEach(i => {
               
      //           if(i.active){
      //             sa = '<a  href="/dashboard?p='+i.id+'" target="_blank"> Go To Dashboard </a>';
      //           }

      //                  markers[n] = { 'position': {
      //         'lat': parseFloat(i.lat),
      //         'lng': parseFloat(i.long),
      //       },
      //       'infoText' : '<p style="text-align:center"><strong>'+i.project_name+'</strong><br><strong>'+i.city+'</strong><br>'+sa+'</p>'
      //                  }

      //           //if(i.camm.length > 0){
      //                     this.getImageUrl(n);
      //                  // }else{
      //                    //this.projectdta[n].image_url = loading;
      //                  // }

      //                   n++;
      //            });

      //            this.markers = markers;
        }
         console.log("markers",markers);
       this.loopcout = true;
       
       this.projects = filtered;
      if (filter == "") {
        this.projects = filtered;
        //this.getprojects();
      }

      console.log("filter",filter,filtered);
    },
    getCatgory(){
        var data = {
            orgid: this.organization_id
           }

           axios({ url: "project/get/category", method: "get",params : data })
          .then(resp => {
            console.log("Projects cate deta", resp); 
            var json = JSON.parse(resp.data.success.catdata);

      //       items: [
      //   {
      //     id: 1,
      //     name: 'Item 1',
      //     checked: false,
      //     children: [
      //       { id: 11, name: 'Subitem 1.1', checked: false },
      //       { id: 12, name: 'Subitem 1.2', checked: false }
      //     ]
      //   },
      //   {
      //     id: 2,
      //     name: 'Item 2',
      //     checked: false,
      //     children: [
      //       { id: 21, name: 'Subitem 2.1', checked: false },
      //       { id: 22, name: 'Subitem 2.2', checked: false }
      //     ]
      //   }
      // ],
            this.items = json;
            console.log("json",json);
          })
          .catch(err => {
            console.log(err);
          });
    },
    markerMaker(filteredArray){

      this.loopcout = false;
      var n = 0;
                   var sa = '';
                   var markers = [];
                   filteredArray.forEach(i => {
               
            
                  sa = '<a  style="color:#fff !important" class="btnnmap" href="/tpls/modules?p='+i.id+'"> Go To Dashboard </a>';
                

                       markers[n] = { 'position': {
              'lat': parseFloat(i.latitude),
              'lng': parseFloat(i.longitude),
            },
             'project_active' : i.project_active,
            'infoText' : '<p style="text-align:center" class=""><strong>'+i.project_name+'</strong><br><strong>'+i.city+'</strong><br><br>'+sa+'</p>'
                       }

                //if(i.camm.length > 0){
                          this.getImageUrl(n);
                       // }else{
                         //this.projectdta[n].image_url = loading;
                       // }

                        n++;
                 });

                 this.markers = markers;
        
         console.log("markers",markers);
       this.loopcout = true;
    }

    } // Mrthods close
} // Controller close

</script>
<style>
.secondheader {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    /* display: flex; */
    padding: 21px 45px;
    width: calc(100% - 55px);
}

.textsearch_box {
    height: 42px;
    background: #EFF4FA 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: calc(100% - 25px);
    padding: 0px 121px 0 13px;
    font-size: 11px;
    color: #7C9093;
}

.textsearch_box:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0014FF5D !important;
    border: 1px solid #333399;
    outline: 0;
}

.textsearch_box:focus-visible {
    outline: 0px;
}

.searchbar {
    width: calc(100% - 110px);
    position: relative;
}

.tbutton {
    background: #333399 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 0;
    color: #fff;
    font-size: 9px;
}

.seach-bittons.tbutton {
    padding: 6px 16px;
    position: absolute;
    right: 35px;
    top: 8px;
}

.iconn {
    font-size: 11px;
    position: relative;
    top: 7px;
    cursor: pointer;
    opacity: 0.5;
}

.iconn.active {
    opacity: 1;
}

.mapview {
    background: url(../../assets/tata/icons/Icon_feather-map-pin@2x.png);
    padding: 11px 5px 8px 21px;
    background-size: 23%;
    background-repeat: no-repeat;
    background-position: 3px 11px;

}

.gridview {
    background: url(../../assets/tata/icons/Icon_feather-grid@2x.png);
    padding: 13px 4px 11px 24px;
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: 8px 14px;
}

.sepratore {
    position: relative;
    background: #ccc;
    height: 15px;
    width: 2px;
    display: inline-block;
    top: 11px;
    border-radius: 5px;
}

.maincard {
    background: #fff;
    width: 100%;
    display: flex;
    padding: 5px 45px;
}

.card-box {
    display: block;
    padding: 15px 45px;
    box-sizing: border-box;
    background: #EFF4FA 0% 0% no-repeat padding-box;
    border-radius: 11px;
    width: 100%;
}

.projecttitle {
    font-size: 22px;
    padding: 0;
    margin: 0 !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 170px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #E5E5E5;
   border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
padding:5px;
text-align: left;
margin-left: 15px;
}

.dropdown-toggle {
  cursor: pointer;
  font-size: 12px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 11px;
  z-index: 10;
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #E5E5E5;
border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
width:170px;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-menu ul li {
  padding: 5px 10px;
}


.dropdown-togglep {
  cursor: pointer;
  font-size: 12px;
}

.dropdown-menup {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 11px;
  z-index: 1000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #E5E5E5;
border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
width:170px;
}

.dropdown-menup ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-menup ul li {
  padding: 5px 10px;
}

.dropdown-menup ul li label {
  display: block;
}
li.sepratoree {
    border-bottom: 1px solid #E5E5E5;
}
.openner {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #0058FF;
    position: relative;
    top: 12px;
    right: -14px;
}

.chech_container {
  display: block;
  position: relative;
  padding-left: 26px;
    margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chech_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkt {
  position: absolute;
  top: 2px;
  left: 1px;
  height: 13px;
    width: 14px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.chech_container:hover input ~ .checkmarkt {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chech_container input:checked ~ .checkmarkt {
  background-color: #0058FF;
}

/* Create the checkmarkt/indicator (hidden when not checked) */
.checkmarkt:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmarkt when checked */
.chech_container input:checked ~ .checkmarkt:after {
  display: block;
}

/* Style the checkmarkt/indicator */
.chech_container .checkmarkt:after {
    left: 4px;
    top: 0px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);

}
.selectlable{
    position: relative;
    left : 25px;
}

.filter {
    width: 75%;
    float: left;
    text-align: right;
}
.haadtile {
    width: 25%;
    float: left;
}
.card-inner-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
    /* overflow: auto; */
    position: relative;
    margin-top: 28px;
   
    float: left;
}
.card-inner-box .maps {

    border-radius: 5px;
    border:1px solid transparent;
}
.maps .vue-map-container{
    border-radius: 5px;
    border: 1px solid #ffffff00;
    overflow: hidden;
}
.tdash {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
}
.proejectitems .v-image {
    border-radius: 5px;
}
.pnamejtri{
    font-size: 15px;
    color:#fff;
    position: absolute;
    bottom: 5px;
    left: 22px;
    text-shadow: 0px 0px 2px BLACK;
}
.proejectitems {
    position: relative;
}
.proejectitems .indicatetore {
    z-index: 9;
    right: 18px;
    top: 20px;
}
.projectacct{
    width : 105px;
    cursor: pointer;
}
label.chech_container.filtered {
    width: 215px;
    display: inline-block;
    margin-left: 17px;
    /* padding-left: 0 !important; */
}
.filtered .checkmarkt {
    position: absolute;
    top: 3px;
    left: 11px;
    height: 15px;
    width: 15px;
    background-color: #eee;
}
.filtered  .checkmarkt:after {
    left: 4px;
    top: 1px;
}
label.chech_container.filtered.greenf {
    color: #56b03a;
    font-size: 13px;
}
label.chech_container.filtered.redf {
    color: #b03a3a;
    font-size: 13px;
}
.chech_container.filtered.greenf input:checked ~ .checkmarkt {
    background-color: #56b03b;
}
.chech_container.filtered.redf input:checked ~ .checkmarkt {
    background-color: #b03a3a;
}
.filtered.greenf .checkmarkt {
    background-color: #56b03b6a;
}
.filtered.redf .checkmarkt {
    background-color: #b03a3a71;
}
a.btnnmap {
    background: #339;
    color: #fff !important;
    padding: 3px 3px;
    margin-top: 19px;
    border-radius: 3px;
    margin-left: 7px;
}
.gridview{
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: 25;
  animation-delay: 1s;
}
@keyframes blink {
  80% {
    border-bottom: 3px solid #000;
  }
  90% {
    
    opacity: 1;
    border-bottom: 3px solid #000;
   
  }
  100% {
    border-bottom: 3px solid #000;
  }
}
img.pin {
    width: 14px;
    position: relative;
    top: 2px;
    left: 2px;
}
.cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #333399;
    border-radius: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: 0.1s;
}
.cursora {
    position: fixed;
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    background-color: #333399;
    border-radius: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: 0.08s;
}
.paymentinfobox {

    position: fixed !important;
    top : 0;
    left : 0;
     width: 100%;
     height: 120vh;

}
.paymentinfobox .infoc {
    position: fixed !important;
    top: 20%;
    left: 25%;
    width: 50%;
    height: 400px;
    text-align: center;
    background: #339;
    color: #fff;
    font-size: 18px;
   
    padding: 10%;
    border-radius: 5px;
    box-shadow: 0 0 11px 0px #000000db;
}
</style>  