<template>
    <div>
      <Preloader v-if="preloader" />
      <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
      <v-card>
        <v-card flat>
          <v-snackbar v-model="snackbar" center top timeout="10000" type="success">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ lang.Ok }}
        </v-btn>
      </template>
    </v-snackbar>
  
          <v-form ref="form" @submit.prevent="addcomaprisonschedulereport"  v-model="valid" lazy-validation>
            <v-card class="pa-5">
              <p class="reportinfo">
                Schedule Drone Comparison Report
              </p>
              <v-radio-group v-model="reporttype" column>
                <v-radio
                  :label="lang.EveryMonthprogressreport"
                  color="amber accent-3"
                  value="last30days"
                ></v-radio>
                <v-radio
                  :label="lang.BiMonthlyprogressreport"
                  color="amber accent-3"
                  value="last60days"
                ></v-radio>
                <v-radio
                  :label="lang.Quarterlyprogressreport"
                  color="amber accent-3"
                  value="last90days"
                ></v-radio>
  
                <v-radio
                  :label="lang.Halfyearlyprogressreport"
                  color="amber accent-3"
                  value="last180days"
                  title="Monthly progress report"
                ></v-radio>
              </v-radio-group>
              <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="email"
                  :label="lang.Email"
                  required
                  :rules="emailrules"
                ></v-text-field>
              </v-col>
               </v-row>
              <v-btn class="mr-4" type="submit" :disabled="isButtonDisabled" color="mylightbutton">
                {{lang.submit }}
              </v-btn>
              <v-btn @click="resetScheduledReports" :disabled="isResetButtonDisabled" color="mydarkbutton"> {{lang.Reset}} </v-btn>
            </v-card>
          </v-form>
        </v-card>
      </v-card>
    </div>
  </template>
  <script>
  
  import axios from "axios";
  import Preloader from "../../components/PreLoader";
  import { locals } from "../../utils/locals";
  import rotate from '../../assets/rotedmobilepsd.jpg';
  export default {
    components: {
      Preloader,
    },
    name: "drone_video_comparison_schedule_report",
    data() {
      return {
        rotate: rotate,
        currentProject: {},
        current_cam: {},
        preloader: false,
        alert: false,
        alertmessage: "",
        alertType: "",
        tab: 1,
        snackbar: false,
        text: "Something went wrong. Please reload and try again.",
        reporttype: "",
        email: "",
        emailrules: [
           v => !!v || this.lang.required,
          v => /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[^\s,]+\.[A-Za-z]{2,4}){0,100}$/.test(v) || this.lang.EmailValid
         ],
        valid: true,
          Local :locals,  
          lang : {},
          hasEntryInDB:""
      };
    },
    mounted() {
       var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
  
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.current_cam = this.$store.state.currentCamera;
      this.userdata = JSON.parse(localStorage.getItem('userdata'))
       if(this.userdata.free_access == 0){
            this.useraccess = true
          }else{
            this.$router.push('/')
      }
      // if (this.userdata.open_access == 1) {
      //   this.$router.push('/dashboard')
      // }
      this.fetchScheduledReportSetting();
        },
    computed: {
    params() {
      return {
        user_id: this.userdata.id,
        report_type: this.reporttype,
        email: this.email,
      };
    },
    isResetButtonDisabled() {
    return this.hasEntryInDB.length === 0 ;
  },
  isButtonDisabled() {
    return this.reporttype.length === 0 ;
  }
  },
    watch: {
      "$store.state.currentCamera": function () {
         let currentProject = JSON.parse(localStorage.getItem("currentProject"));
         this.currentProject = currentProject;
        this.current_cam = this.$store.state.currentCamera;
      },
    },
    methods: {
      async submit() {
        this.addcomaprisonschedulereport(); // action to login
      },
      addcomaprisonschedulereport() {
        axios({
          url: "/dron/" + this.currentProject.id + "/save_drone_comparison_schedule_report",
          data: this.params,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.success) {
              this.snackbar = true;
              this.text = "Report has been Scheduled successfully.";
              this.isResetButtonDisabled = true;
              this.fetchScheduledReportSetting();
            } else if(resp.data.error) {
              this.snackbar = true;
              this.text = "Report is already scheduled for selected report type";
            }else {
              this.snackbar = true;
              this.text = "Something went wrong, please try again.";
            }
          })
          .catch((err) => {
            console.log(err);
            this.snackbar = true;
            this.text = "Something went wrong. Please reload page and try again.";
          });
      },
      resetScheduledReports() {
        axios({
          url:
            "/dron/"+ this.currentProject.id + "/reset_drone_comparison_schedule_report_settings",
            data: { user_id: this.userdata.id},
          method: "POST",
        }).then((resp) => {
          if (resp.data.success) {
            this.snackbar = true;
            this.text = "Report has been reset successfully.";
            this.fetchScheduledReportSetting();
          } else {
            this.snackbar = true;
            this.text = "Something went wrong, please try again.";
          }
        }).catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = "Something went wrong. Please reload page and try again.";
        });
      },
      fetchScheduledReportSetting() {
        axios({
          url:
            "/dron/"+ this.currentProject.id + "/fetch_drone_comparison_schedule_report_settings",
            data: { user_id: this.userdata.id},
          method: "POST",
        }).then((resp) => {
          if (resp.data.success) {
            this.reporttype = resp.data.data[0].report_type;
            this.hasEntryInDB = resp.data.data[0].report_type;
            this.email = resp.data.data[0].email;
          }
        }).catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = "Something went wrong. Please reload page and try again.";
        });
      },
    },
  };
  </script>
  <style>
  .reportinfo {
    font-size: larger;
    font-weight: 500;
    color: #ffc400;
  }
  .info_rec {
    position: relative;
    left: 2%;
  }
  .mdi-progress-question {
    font-size: 28px !important;
  }
  </style>