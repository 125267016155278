<template>
  <div>
    <Preloader v-if="preloader" />
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="formcard materialbb">
          <template>
            <v-data-table :headers="headers" :items="allLeadsList" :items-per-page="100" :footer-props="{
      'items-per-page-options': [100, 200, 250, 500, 1000]
    }" item-key="name" class="elevation-1 pa-6" height="50%" @click:row="handleClick">
              <template v-slot:top>
                <v-toolbar-title>CRM</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-row class="newLeadButton">
                  <v-col class="alignFilter">
                    <v-checkbox v-model="filtersVisibilty" label="Filters" @click="hidecont"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-btn color="#f8ba04" @click="openLeadForm">Add New Lead</v-btn>
                  </v-col>
                  <v-col v-if="chartsVisible">
                    <v-btn color="#f8ba04" @click="openCharts">Charts</v-btn>
                  </v-col>
                </v-row>
                <v-container fluid v-if="showFilters">
                  <v-row>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-select label="Lead Owner" v-model="leadOwnerFilterValue" :items="items" return-object
                          item-text="lead_owner" outlined clearable dense>
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-select label="Lead Sub Owner" v-model="leadSubOwnerFilterValue" :items="itemsSubOwners"
                          return-object item-text="lead_sub_owner" outlined clearable dense>
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-select label="Priority" v-model="priorityFilterValue" :items="priorityItems" return-object
                          item-text="priority" outlined clearable dense>
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-select label="Status" v-model="statusFilterValue" :items="statusItems" return-object
                          item-text="status" outlined clearable dense>
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-select label="FollowUp Date" v-model="dateFilterValue" :items="weekItems" return-object
                          item-text="timeline" outlined clearable dense>
                        </v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="timelapseCameraFilterValue" type="text" outlined clearable dense
                          label="TimeLapse Camera"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="interioreFilterValue" type="text" outlined clearable dense
                          label="360 Interior"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="materialFilterValue" type="text" outlined clearable dense
                          label="Material Tracking"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="linearFilterValue" type="text" outlined clearable dense
                          label="Linear Monitoring"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="companyFilterValue" type="text" outlined clearable dense
                          label="Company Name"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-2">
                        <v-text-field v-model="cityFilterValue" type="text" outlined clearable dense
                          label="City"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.lead_sub_owner_email="{ item }">
                <v-tooltip bottom :color="getColor(item.priorityBoolToText)" outlined>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="trim">{{
      item.lead_sub_owner_email
    }}</span>
                  </template>
                  <span>{{ item.lead_sub_owner_email }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.requirement_message="{ item }">
                <v-btn :color="getColor(item.priorityBoolToText)" outlined
                  v-on:click="showRequirementDetails(item.requirement_message)" small>
                  Show
                </v-btn>
              </template>
              <template v-slot:item.priorityBoolToText="{ item }">
                <v-chip :color="getColor(item.priorityBoolToText)" dark>
                  {{ item.priorityBoolToText }}
                </v-chip>
              </template>
              <template v-slot:item.timelapse_cameras="{ item }">
                {{ item.timelapse_cameras ? "Yes" : "No" }}
              </template>
              <template v-slot:item.interiors_360="{ item }">
                {{ item.interiors_360 ? "Yes" : "No" }}
              </template>
              <template v-slot:item.material_tracking="{ item }">
                {{ item.material_tracking ? "Yes" : "No" }}
              </template>
              <template v-slot:item.drone_or_linear_monitoring="{ item }">
                {{ item.drone_or_linear_monitoring ? "Yes" : "No" }}
              </template>
              <template v-slot:item.latest_discussion="{ item }">
                <v-btn :color="getColor(item.priorityBoolToText)" outlined
                  v-on:click="showLatestDiscussionDetails(item.id)" small>
                  Show
                </v-btn>
              </template>
              <template v-slot:item.special_requirement_or_interest="{ item }">
                <v-btn :color="getColor(item.priorityBoolToText)" outlined v-on:click="
      showSpecialRequirementDetails(
        item.special_requirement_or_interest
      )
      " small>
                  Show
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" center top timeout="10000" type="success">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ lang.Ok }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog v-model="addNewLeadForm" persistent width="1024">
        <v-card>
          <v-form ref="form" v-model="valid">
            <v-card-title>
              <span class="text-h5">Create New Lead</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <small>*indicates required field</small>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-select label="Lead Owner*" :rules="[v => !!v || 'Lead Owner is required']"
                      v-model="selectedLeadOwner" :items="items" item-text="lead_owner" required>
                    </v-select>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-select label="Lead Sub Owner" v-model="lead_sub_owner" clearable :items="itemsSubOwners"
                      item-text="lead_sub_owner" multiple>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.lead_sub_owner }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ lead_sub_owner.length - 1 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field v-model="profit_sharing" type="number" min="0" label="Profit Sharing %"
                      :rules="[numberRule]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="full_name" :rules="fullNameRules" label="Full Name*" required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="company_name" :rules="compnayRules" label="Company name*"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="website" label="Website"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="email" :rules="emailRules" label="Email*" type="email"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="mobile_number" label="Mobile Number"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="city" label="City"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="country" :rules="countryRules" label="Country*"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="requirement_message" :rules="latestDiscussionRules" label="Requirement*"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="project" label="Project Name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-radio-group v-model="timelapseRadios" row>
                      <template v-slot:label>
                        <div>Timelapse Cameras</div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-radio-group v-model="droneRadios" row>
                      <template v-slot:label>
                        <div>Drone or Linear Monitoring</div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-radio-group v-model="interiorsRadios" row>
                      <template v-slot:label>
                        <div>360 Interiors</div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-radio-group v-model="materialRadios" row>
                      <template v-slot:label>
                        <div>Material Tracking</div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="latest_discussion" :rules="latestDiscussionRules" label="Latest Discussion*"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-menu ref="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="discussion_date" :rules="discussionDateRules" label="Discussion Date*"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="discussion_date" no-title scrollable
                        :max="new Date().toISOString().substr(0, 10)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu1.save(discussion_date)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="special_requirement_or_interest"
                      label="Special requirement or interest"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="4" sm="4">

                                        <v-menu ref="menu2" :close-on-content-click="false" transition="scale-transition"
                                            offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="date_created" label="Date Created*"
                                                    :rules="dateCreatedRules" prepend-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date_created" no-title scrollable
                                                :max="new Date().toISOString().substr(0, 10)">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu2 = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="primary" @click="$refs.menu2.save(date_created)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col> -->
                  <v-col cols="4" sm="4">
                    <v-select label="Status*" :rules="[v => !!v || 'Status is required']" v-model="status"
                      :items="statusItems" item-text="status" required>
                    </v-select>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select label="Priority" v-model="priority" clearable :items="priorityItems" item-text="priority">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-menu ref="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="followup_date" label="Followup Date" prepend-icon="mdi-calendar" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="followup_date" no-title scrollable
                        :min="new Date().toISOString().substr(0, 10)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu2.save(followup_date)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-file-input label="File" v-model="image" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="addNewLeadForm = false">
                Close
              </v-btn>
              <v-btn color="blue-darken-1" :disabled="!valid" variant="text" @click="addNewLead()">
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="editLeadForm" persistent width="1024">
        <v-card>
          <v-form ref="form" v-model="validDetails">
            <v-card-title>
              <span class="text-h5">Lead Details</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-select label="Lead Owner*" :rules="[v => !!v || 'Lead Owner is required']"
                      v-model="lead_details_lead_owner" :items="items" :item-text="'lead_owner'" :item-value="'value'"
                      required>
                    </v-select>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-select label="Lead Sub Owner" v-model="lead_details_lead_sub_owner" clearable
                      :items="itemsSubOwners" :item-text="'lead_sub_owner'" :item-value="'value'" multiple>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.lead_sub_owner }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ lead_details_lead_sub_owner.length - 1 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field v-model="lead_details_profit_sharing" type="number" min="0" label="Profit Sharing %"
                      :rules="[numberRule]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_full_name" :rules="fullNameRules" label="Full Name*"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_company_name" :rules="compnayRules" label="Company name*"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_website" label="Website"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_email" :rules="emailRules" label="Email*" type="email"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_mobile_number" label="Mobile Number"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_city" label="City"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_country" :rules="countryRules" label="Country*"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_requirement_message" :rules="latestDiscussionRules"
                      label="Requirement*" required></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_project" label="Project Name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-radio-group v-model="lead_details_timelapseRadios" row>
                      <template v-slot:label>
                        <div>Timelapse Cameras</div>
                      </template>
                      <v-radio :value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio :value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-radio-group v-model="lead_details_droneRadios" row>
                      <template v-slot:label>
                        <div>Drone or Linear Monitoring</div>
                      </template>
                      <v-radio :value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio :value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-radio-group v-model="lead_details_interiorsRadios" row>
                      <template v-slot:label>
                        <div>360 Interiors</div>
                      </template>
                      <v-radio :value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio :value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-radio-group v-model="lead_details_materialRadios" row>
                      <template v-slot:label>
                        <div>Material Tracking</div>
                      </template>
                      <v-radio :value="1">
                        <template v-slot:label>
                          <div>Yes</div>
                        </template>
                      </v-radio>
                      <v-radio :value="0">
                        <template v-slot:label>
                          <div>No</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_latest_discussion" :rules="latestDiscussionRules"
                      label="Latest Discussion*"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <!-- <v-menu ref="menu1" :close-on-content-click="false" transition="scale-transition"
                                            offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="lead_details_discussion_date" :rules="discussionDateRules"
                                                    label="Discussion Date*" prepend-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="lead_details_discussion_date" no-title scrollable
                                                :max="new Date().toISOString().substr(0, 10)">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu1 = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="primary" @click="$refs.menu1.save(lead_details_discussion_date)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu> -->
                    <v-text-field v-model="lead_details_discussion_date" label="Discussion Date" filled
                      readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_special_requirement_or_interest"
                      label="Special requirement or interest"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-text-field v-model="lead_details_date_created" label="Date Created" filled
                      readonly></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select label="Status*" v-model="lead_details_status" clearable :items="statusItems"
                      :item-text="'status'" :item-value="'value'" :rules="[v => !!v || 'Status is required']">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <v-menu ref="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="lead_details_followup_date" label="Followup Date"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="lead_details_followup_date" no-title scrollable
                        :min="new Date().toISOString().substr(0, 10)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu2.save(lead_details_followup_date)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-select label="Priority" v-model="lead_details_priority" clearable :items="priorityItems"
                      :item-text="'priority'" :item-value="'value'">
                    </v-select>
                  </v-col>
                  <v-col cols="4" sm="4">
                    <v-file-input label="File" v-model="image" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="getAttachments()">
                Attachments
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="leadDiscussions()">
                Previous Discussions
              </v-btn>
              <v-btn color="blue-darken-1" :disabled="!validDetails" variant="text" @click="updateLead()">
                Update
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="editLeadForm = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="leadDiscussionsCard" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Previous Discussions</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" v-for="u in LeadDiscussions" :key="u.latest_discussion">
                  <v-card>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-list-item-subtitle class="lead_date">{{
      u.discussion_date
    }}</v-list-item-subtitle>

                        <div class="text-overline mb-4 lead_heading">
                          {{ u.latest_discussion }}
                        </div>
                      </v-col>
                    </v-row>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="leadDiscussionsCard = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="attachmentsCard" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">Attachments List</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" v-for="u in LeadAttachments" :key="u.id">
                  <v-card>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-list-item-subtitle class="lead_attachment"><a :href="u.signedURL" download>{{
      u.file_name
    }}</a></v-list-item-subtitle>
                      </v-col>
                    </v-row>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="attachmentsCard = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="requirementMsg" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Requirement
          </v-card-title>
          <v-card-text>
            {{ this.requirementMsgDetails }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="requirementMsg = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="latestDiscussionDetails" max-width="600px">
        <v-card>
          <v-card-title class="text-h5">
            Latest Discussions
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" v-for="u in latestDiscussionDetailsText" :key="u.latest_discussion">
                  <v-card>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-list-item-subtitle class="lead_date">{{
      u.discussion_date
    }}</v-list-item-subtitle>

                        <div class="text-overline mb-4 lead_heading">
                          {{ u.latest_discussion }}
                        </div>
                      </v-col>
                    </v-row>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="latestDiscussionDetails = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="specialRequirementDetails" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Special Requirement
          </v-card-title>
          <v-card-text>
            {{ this.specialRequirementDetailsText }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="specialRequirementDetails = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="crmCharts">
        <v-card>
          <v-card-title class="text-h5">
            CRM Charts
          </v-card-title>
          <v-card-text>
            <!-- <div id="chart"> -->
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="bar" ref="leadStatus" height="350" v-if="showcharts"
                    :options="leadsStatusChartOptions" :series="leadsStatusSeries"></apexchart>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-layout align-center justify-center>
                        <v-radio-group v-model="priorityValLeadStatus" row>
                          <v-radio label="Low" color="red" value="2"></v-radio>
                          <v-radio label="Medium" color="red" value="1"></v-radio>
                          <v-radio label="High" color="red" value="0"></v-radio>
                        </v-radio-group>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="bar" ref="leadStatusByOwner" height="350" v-if="showcharts"
                    :options="LeadsByOwnerChartOptions" :series="LeadsByOwnerSeries"></apexchart>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-layout align-center justify-center>
                        <v-radio-group v-model="priorityValLeadStatusByOwner" row>
                          <v-radio label="Low" color="red" value="2"></v-radio>
                          <v-radio label="Medium" color="red" value="1"></v-radio>
                          <v-radio label="High" color="red" value="0"></v-radio>
                        </v-radio-group>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="bar" ref="leadsCreationTimeline" height="350" v-if="showcharts"
                    :options="leadsCreationTimelineChartOptions" :series="leadsCreationTimelineSeries"></apexchart>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-layout align-center justify-center>
                        <v-radio-group v-model="priorityValLeadCreationTimeline" row>
                          <v-radio label="Low" color="red" value="2"></v-radio>
                          <v-radio label="Medium" color="red" value="1"></v-radio>
                          <v-radio label="High" color="red" value="0"></v-radio>
                        </v-radio-group>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="donut" height="437" v-if="showcharts" :options="leadsPriorityChartOptions"
                    :series="leadsPrioritySeries"></apexchart>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="treemap" ref="leadsCountryWise" height="350" v-if="showcharts"
                    :options="leadsCountryWiseChartOptions" :series="leadsCountryWiseSeries"></apexchart>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-layout align-center justify-center>
                        <v-radio-group v-model="priorityValLeadCountryWise" row>
                          <v-radio label="Low" color="red" value="2"></v-radio>
                          <v-radio label="Medium" color="red" value="1"></v-radio>
                          <v-radio label="High" color="red" value="0"></v-radio>
                        </v-radio-group>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <apexchart type="bar" ref="leadsAgeAnalysis" height="350" v-if="showcharts"
                    :options="leadsAgeAnalysisChartOptions" :series="leadsAgeAnalysisSeries"></apexchart>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-layout align-center justify-center>
                        <v-radio-group v-model="priorityValLeadAgeAnalysis" row>
                          <v-radio label="Low" color="red" value="2"></v-radio>
                          <v-radio label="Medium" color="red" value="1"></v-radio>
                          <v-radio label="High" color="red" value="0"></v-radio>
                        </v-radio-group>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!-- </div> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="crmCharts = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import { locals } from "../../../utils/locals";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
  },
  name: "EditTask",
  data() {
    return {
      requerdRules: [v => !!v || this.lang.required],
      numberOnlyRules: [
        v => !!v || this.lang.required,
        v => /^[0-9]*[1-9][0-9]*$/.test(v) || this.lang.integetrequre
      ],
      valid: true,
      validDetails: true,
      fullNameRules: [v => !!v || "Full Name is required"],
      compnayRules: [v => !!v || "Company Name is required"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      countryRules: [v => !!v || "Country Name is required"],
      requirementRules: [v => !!v || "Requirement message is required"],
      latestDiscussionRules: [v => !!v || "Latest Discussion is required"],
      discussionDateRules: [v => !!v || "Discussion Date is required"],
      dateCreatedRules: [v => !!v || "Creation Date is required"],
      statusRules: [v => !!v || "Status is required"],
      numberRule: val => {
        if (val < 0) return "Please enter a positive number";
        return true;
      },
      preloader: false,
      projectid: 0,
      search: "",
      user_material_access: false,
      addNewLeadForm: false,
      editLeadForm: false,
      imagesshow: false,
      timelapseRadios: false,
      droneRadios: false,
      interiorsRadios: false,
      materialRadios: false,
      locals: [],
      lang: {},
      allLeadsList: [],
      items: [
        { value: "296", lead_owner: "manish.p@opticvyu.com" },
        { value: "770", lead_owner: "shubham.p@opticvyu.com" },
        { value: "1841", lead_owner: "harshal.p@opticvyu.com" },
        { value: "3200", lead_owner: "abhishek.c@opticvyu.com" },
        { value: "2497", lead_owner: "nitesh.patel@opticvyu.com" },
        { value: "1752", lead_owner: "anshul.jain@opticvyu.com" },
        { value: "3934", lead_owner: "ganesh.tiwari@opticvyu.com" },
        { value: "1811", lead_owner: "teerath.r@opticvyu.com" },
        { value: "4221", lead_owner: "rakhi.j.r@opticvyu.com" }
      ],
      itemsSubOwners: [
        { value: "296", lead_sub_owner: "manish.p@opticvyu.com" },
        { value: "770", lead_sub_owner: "shubham.p@opticvyu.com" },
        { value: "1841", lead_sub_owner: "harshal.p@opticvyu.com" },
        { value: "3200", lead_sub_owner: "abhishek.c@opticvyu.com" },
        { value: "2497", lead_sub_owner: "nitesh.patel@opticvyu.com" },
        { value: "1752", lead_sub_owner: "anshul.jain@opticvyu.com" },
        { value: "3934", lead_sub_owner: "ganesh.tiwari@opticvyu.com" },
        { value: "1811", lead_sub_owner: "teerath.r@opticvyu.com" },
        { value: "4221", lead_sub_owner: "rakhi.j.r@opticvyu.com" }
      ],
      statusItems: [
        { value: "in_discussion", status: "In Discussion" },
        { value: "for_presentation", status: "For Presentation" },
        { value: "converted", status: "Converted" },
        { value: "not_converted", status: "Not Converted" }
      ],
      priorityItems: [
        { value: "0", priority: "High" },
        { value: "1", priority: "Medium" },
        { value: "2", priority: "Low" }
      ],
      weekItems: [
        { value: 7, timeline: "1 Week" },
        { value: 14, timeline: "2 Weeks" },
        { value: 21, timeline: "3 weeks" },
        { value: 28, timeline: "4 weeks" }
      ],
      selectedLeadOwner: null,
      lead_sub_owner: null,
      profit_sharing: "",
      full_name: "",
      company_name: "",
      website: "",
      email: "",
      mobile_number: "",
      city: "",
      country: "",
      requirement_message: "",
      project: "",
      special_requirement_or_interest: "",
      latest_discussion: "",
      // date_created: "",
      status: "",
      discussion_date: "",
      priority: null,
      followup_date: "",
      leadOwnerFilterValue: null,
      leadSubOwnerFilterValue: null,
      companyFilterValue: "",
      cityFilterValue: "",
      countryFilterValue: "",
      timelapseCameraFilterValue: "",
      interioreFilterValue: "",
      materialFilterValue: "",
      linearFilterValue: "",
      requirementFilterValue: "",
      statusFilterValue: null,
      priorityFilterValue: null,
      dateFilterValue: null,
      showFilters: false,
      hasCRMAccess: false,
      lead_details_lead_owner: null,
      lead_details_lead_sub_owner: [],
      lead_details_profit_sharing: "",
      lead_details_full_name: "",
      lead_details_company_name: "",
      lead_details_website: "",
      lead_details_email: "",
      lead_details_mobile_number: "",
      lead_details_city: "",
      lead_details_country: "",
      lead_details_requirement_message: "",
      lead_details_project: "",
      lead_details_special_requirement_or_interest: "",
      lead_details_latest_discussion: "",
      lead_details_date_created: "",
      lead_details_status: null,
      lead_details_discussion_date: "",
      lead_details_timelapseRadios: false,
      lead_details_droneRadios: false,
      lead_details_interiorsRadios: false,
      lead_details_materialRadios: false,
      lead_details_priority: null,
      lead_details_followup_date: "",
      lead_id: "",
      LeadDiscussions: [],
      LeadAttachments: [],
      leadDiscussionsCard: false,
      attachmentsCard: false,
      Preloader: false,
      text: "",
      snackbar: false,
      filtersVisibilty: false,
      crm_discussion_id: "",
      image: null,
      base64: null,
      fileName: "",
      requirementMsg: false,
      requirementMsgDetails: "",
      latestDiscussionDetails: false,
      latestDiscussionDetailsText: [],
      specialRequirementDetails: false,
      specialRequirementDetailsText: "",
      crmCharts: false,
      leadsStatusSeries: [
        {
          name: "Leads Status",
          data: [],
        },
      ],
      leadsStatusChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
            barHeight: '50%',
            isFunnel: true,
          },
        },
        colors: ['#F44F5E',
          '#E55A89',
          '#D863B1',
          '#CA6CD8',
          '#B57BED',
          '#8D95EB',
          '#62ACEA',
          '#4BC3E6',
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
          },
          dropShadow: {
            enabled: true,
          },
        },
        title: {
          text: 'Leads Status',
          align: 'middle',
        },
        xaxis: {
          categories: [
            'In Discussion',
            'For Presentation',
            'Converted',
            'Not Converted',
          ],
        },
        legend: {
          show: false,
        },
      },
      LeadsByOwnerSeries: [],
      LeadsByOwnerChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Leads By Owner Status Wise',
          align: 'middle',
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: 40
        }
      },
      leadsCreationTimelineSeries: [{
        name: 'Leads',
        data: []
      }],
      leadsCreationTimelineChartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }

        },
        title: {
          text: 'Added Leads Timeline',
          // floating: true,
          // offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
      leadsPrioritySeries: [],
      leadsPriorityChartOptions: {
        chart: {
          width: 200,
          type: 'donut',
        },
        labels: ['High', 'Medium', 'Low'],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          }
        },
        title: {
          text: 'Leads Priority Wise',
          align: 'middle',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      leadsCountryWiseSeries: [
        {
          data: []
        }
      ],
      leadsCountryWiseChartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap'
        },
        title: {
          text: 'Leads Country Wise',
          align: 'middle',
        }
      },
      leadsAgeAnalysisSeries: [],
      leadsAgeAnalysisChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: '60%',
          }
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#0068b8", "#ffb01a", "#ff4560"],
        xaxis: {
          title: {
            // text: 'Timeline',
            align: 'top',
          },
          categories: ['Under 15 days', '1 month', '2 months', '3 months', '6 months', 'More'],
        },
        yaxis: {
          title: {
            // text: 'Number Of Leads'
          }
        },
        title: {
          text: 'Leads Age Analysis',
          // floating: true,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: 40
        }
      },
      showcharts: false,
      priorityValLeadStatus: "",
      priorityValLeadStatusByOwner: "",
      priorityValLeadCreationTimeline: "",
      priorityValLeadCountryWise: "",
      priorityValLeadAgeAnalysis: "",
      chartsVisible: 0,

    };
  },
  mounted() {
    var userdata = JSON.parse(localStorage.getItem("userdata"));
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    if (userdata.id == 2497 || userdata.id == 1752 || userdata.id == 3934) {
      this.chartsVisible = 1
    }
    this.getAllLeads();

    if (userdata.user_code == 2 && userdata.opticvyu_user == 1) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "Priority",
          value: "priorityBoolToText",
          filter: this.priorityFilter,
          align: "center"
        },
        {
          text: "Lead Owner",
          value: "lead_owner_email",
          filter: this.nameFilter
        },
        { text: "Full Name", value: "full_name" },
        {
          text: "Company Name",
          value: "company_name",
          filter: this.companyNameFilter
        },
        {
          text: "Status",
          value: "statusConvertedValue",
          filter: this.statusFilter
        },
        { text: "Email", value: "email" },
        { text: "Mobile number", value: "mobile_number" },
        { text: "City", value: "city", filter: this.cityFilter },
        { text: "Project Name", value: "project_name" },
        {
          text: "FollowUp Date",
          value: "followup_date",
          filter: this.dateFilter
        },
        { text: "Latest Discussion Date", value: "discussion_date" },
        { text: "Latest Discussion", value: "latest_discussion" },
        { text: "Requirement", value: "requirement_message" },
        {
          text: "Special requirement or interest",
          value: "special_requirement_or_interest",
          filter: this.requirementFilter
        },
        {
          text: "Timelapse Cameras",
          value: "timelapse_cameras",
          filter: this.timelapseCameraFilter
        },
        {
          text: "360 Interiors",
          value: "interiors_360",
          filter: this.interioreFilter
        },
        {
          text: "Drone or Linear Monitoring",
          value: "drone_or_linear_monitoring",
          filter: this.linearFilter
        },
        {
          text: "Material Tracking",
          value: "material_tracking",
          filter: this.materialFilter
        },
        {
          text: "Lead Sub-Owner",
          value: "lead_sub_owner_email",
          filter: this.subNameFilter
        },
        { text: "Profit Sharing", value: "profit_sharing" }
        // { text: 'Website', value: 'website' },
        // { text: 'Country', value: 'country', filter: this.countryFilter },
        // { text: 'Date Created', value: 'date_created' },
      ];
    },
    params() {
      var priorityValue;
      if (this.priority == undefined) {
        priorityValue = 2;
      } else {
        priorityValue = this.priority;
      }
      return {
        lead_owner_id: this.selectedLeadOwner,
        lead_sub_owner: this.lead_sub_owner,
        profit_sharing: this.profit_sharing,
        full_name: this.full_name,
        company_name: this.company_name,
        website: this.website,
        email: this.email,
        mobile_number: this.mobile_number,
        country: this.country,
        city: this.city,
        requirement_message: this.requirement_message,
        project_name: this.project,
        timelapse_cameras: this.timelapseRadios,
        interiors_360: this.droneRadios,
        material_tracking: this.interiorsRadios,
        drone_or_linear_monitoring: this.materialRadios,
        special_requirement_or_interest: this.special_requirement_or_interest,
        // date_created: this.date_created,
        latest_discussion: this.latest_discussion,
        discussion_date: this.discussion_date,
        status: this.status,
        priority: priorityValue,
        followup_date: this.followup_date,
        file: this.base64,
        uploadedFileName: this.fileName
      };
    },
    filteredLeadsList() {
      return this.allLeadsList.filter(item => item.priority === parseInt(this.priorityValLeadStatus));
    },
    filteredLeadsListStatusByOwner() {
      return this.allLeadsList.filter(item => item.priority === parseInt(this.priorityValLeadStatusByOwner));
    },
    filteredLeadsListByCreationTimeline() {
      return this.allLeadsList.filter(item => item.priority === parseInt(this.priorityValLeadCreationTimeline));
    },
    filteredLeadsListCountryWise() {
      return this.allLeadsList.filter(item => item.priority === parseInt(this.priorityValLeadCountryWise));
    },
    filteredLeadsListAgeAnalysis() {
      return this.allLeadsList.filter(item => item.priority === parseInt(this.priorityValLeadAgeAnalysis));
    }
  },
  watch: {
    image: function (newVal) {
      if (newVal) {
        this.createBase64Image(newVal);
      } else {
        this.base64 = null;
      }
    },
    priorityValLeadStatus() {
      this.leadsStatusGraph();
    },
    priorityValLeadStatusByOwner() {
      this.leadsStatusByOwnerGraph();
    },
    priorityValLeadCreationTimeline() {
      this.leadsGenerationByMonth();
    },
    priorityValLeadCountryWise() {
      this.leadsCountryWiseGraph();
    },
    priorityValLeadAgeAnalysis() {
      this.leadsAgeAnalysisGraph();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    openLeadForm() {
      this.addNewLeadForm = true;
    },
    getAllLeads: function () {
      var currentUserId = JSON.parse(localStorage.getItem("userdata"));
      axios({ url: "/getList/crmLeads/" + currentUserId.id, method: "GET" })
        .then(resp => {
          var statusText;
          var priorityText;
          resp.data.success.forEach(item => {
            statusText = this.statusItems.filter(function (itemStatus) {
              return itemStatus.value == item.status;
            });
            item.statusConvertedValue = statusText[0].status;
            priorityText = this.priorityItems.filter(function (itemPriority) {
              return itemPriority.value == item.priority;
            });
            item.priorityBoolToText = priorityText[0].priority;
          });
          this.allLeadsList = resp.data.success;
        })
        .catch(err => {
          console.log(err);
        });
    },
    addNewLead() {
      var crmDetails = this.params;
      axios({ url: "/add/crmLeads", data: crmDetails, method: "POST" })
        .then(resp => {
          if (resp.data.success) {
            this.snackbar = true;
            this.text = "New Lead Added Successfully.";
            this.resetForm();
            this.getAllLeads();
          } else {
            this.snackbar = true;
            this.text = "Error While Adding New Lead.";
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    nameFilter(value) {
      if (!this.leadOwnerFilterValue) {
        return true;
      }
      if (value != null) {
        var stringVal = JSON.parse(JSON.stringify(this.leadOwnerFilterValue));
        // return value.toLowerCase().includes(this.leadOwnerFilterValue.toLowerCase());
        return value.includes(stringVal.lead_owner);
      }
    },
    subNameFilter(value) {
      if (!this.leadSubOwnerFilterValue) {
        return true;
      }
      if (value != null) {
        var stringVal = JSON.parse(
          JSON.stringify(this.leadSubOwnerFilterValue)
        );
        return value.includes(stringVal.lead_sub_owner);
      }
    },
    companyNameFilter(value) {
      if (!this.companyFilterValue) {
        return true;
      }
      if (value != null) {
        return value
          .toLowerCase()
          .includes(this.companyFilterValue.toLowerCase());
      }
    },
    cityFilter(value) {
      if (!this.cityFilterValue) {
        return true;
      }
      if (value != null) {
        return value.toLowerCase().includes(this.cityFilterValue.toLowerCase());
      }
    },
    countryFilter(value) {
      if (!this.countryFilterValue) {
        return true;
      }
      if (value != null) {
        return value
          .toLowerCase()
          .includes(this.countryFilterValue.toLowerCase());
      }
    },
    timelapseCameraFilter(value) {
      if (!this.timelapseCameraFilterValue) {
        return true;
      }
      if (value != null) {
        if (value == 0) {
          var convertedValueBool = "no";
        } else {
          convertedValueBool = "yes";
        }
        return convertedValueBool
          .toLowerCase()
          .includes(this.timelapseCameraFilterValue.toLowerCase());
      }
    },
    interioreFilter(value) {
      if (!this.interioreFilterValue) {
        return true;
      }
      if (value != null) {
        if (value == 0) {
          var convertedValueBool = "no";
        } else {
          convertedValueBool = "yes";
        }
        return convertedValueBool
          .toLowerCase()
          .includes(this.interioreFilterValue.toLowerCase());
      }
    },
    materialFilter(value) {
      if (!this.materialFilterValue) {
        return true;
      }
      if (value != null) {
        if (value == 0) {
          var convertedValueBool = "no";
        } else {
          convertedValueBool = "yes";
        }
        return convertedValueBool
          .toLowerCase()
          .includes(this.materialFilterValue.toLowerCase());
      }
    },
    linearFilter(value) {
      if (!this.linearFilterValue) {
        return true;
      }
      if (value != null) {
        if (value == 0) {
          var convertedValueBool = "no";
        } else {
          convertedValueBool = "yes";
        }
        return convertedValueBool
          .toLowerCase()
          .includes(this.linearFilterValue.toLowerCase());
      }
    },
    requirementFilter(value) {
      if (!this.requirementFilterValue) {
        return true;
      }
      if (value != null) {
        return value
          .toLowerCase()
          .includes(this.requirementFilterValue.toLowerCase());
      }
    },
    priorityFilter(value) {
      if (!this.priorityFilterValue) {
        return true;
      }
      if (value != null) {
        var stringVal = JSON.parse(JSON.stringify(this.priorityFilterValue));
        return value.includes(stringVal.priority);
      }
    },
    statusFilter(value) {
      if (!this.statusFilterValue) {
        return true;
      }
      if (value != null) {
        var stringVal = JSON.parse(JSON.stringify(this.statusFilterValue));
        return value === stringVal.status;
        // return value.includes(stringVal.status);
      }
    },
    dateFilter(value) {
      if (!this.dateFilterValue) {
        return true;
      }
      if (value != null) {
        let currentDate = new Date().toJSON().slice(0, 10);
        const nextWeek = new Date();
        var stringVal = JSON.parse(JSON.stringify(this.dateFilterValue));
        nextWeek.setDate(new Date().getDate() + stringVal.value);
        var getMonth = nextWeek.toLocaleString("default", { month: "2-digit" });
        var getDay = nextWeek.toLocaleString("default", { day: "2-digit" });
        const toDate = nextWeek.getFullYear() + "-" + getMonth + "-" + getDay;
        return value <= toDate && value >= currentDate;
      }
    },
    hidecont() {
      this.showFilters = !this.showFilters;
    },
    handleClick(value) {
      if (value.lead_sub_owner != null) {
        this.lead_details_lead_sub_owner = value.lead_sub_owner.split(",");
      }
      (this.lead_id = value.crm_lead_id),
        (this.crm_discussion_id = value.crm_discussion_id),
        (this.lead_details_lead_owner = value.lead_owner_id.toString()),
        (this.lead_details_profit_sharing = value.profit_sharing),
        (this.lead_details_full_name = value.full_name),
        (this.lead_details_company_name = value.company_name),
        (this.lead_details_website = value.website),
        (this.lead_details_email = value.email),
        (this.lead_details_mobile_number = value.mobile_number),
        (this.lead_details_city = value.city),
        (this.lead_details_country = value.country),
        (this.lead_details_requirement_message = value.requirement_message),
        (this.lead_details_project = value.project_name),
        (this.lead_details_special_requirement_or_interest =
          value.special_requirement_or_interest),
        (this.lead_details_latest_discussion = value.latest_discussion),
        (this.lead_details_date_created = value.date_created),
        (this.lead_details_status = value.status),
        (this.lead_details_discussion_date = value.discussion_date),
        (this.lead_details_timelapseRadios = value.timelapse_cameras),
        (this.lead_details_droneRadios = value.drone_or_linear_monitoring),
        (this.lead_details_interiorsRadios = value.interiors_360),
        (this.lead_details_materialRadios = value.material_tracking),
        (this.lead_details_followup_date = value.followup_date),
        (this.lead_details_priority = value.priority.toString()),
        localStorage.setItem(
          "latestDiscussonStoredString",
          JSON.stringify(value.latest_discussion)
        );
      this.editLeadForm = true;
    },
    updateLead() {
      var oldLatestDiscussionString = JSON.parse(
        localStorage.getItem("latestDiscussonStoredString")
      );

      if (oldLatestDiscussionString == this.lead_details_latest_discussion) {
        axios({
          url: "/update/crmLeads/" + this.lead_id,
          data: {
            lead_owner_id: this.lead_details_lead_owner,
            lead_sub_owner: this.lead_details_lead_sub_owner,
            profit_sharing: this.lead_details_profit_sharing,
            full_name: this.lead_details_full_name,
            company_name: this.lead_details_company_name,
            website: this.lead_details_website,
            email: this.lead_details_email,
            mobile_number: this.lead_details_mobile_number,
            country: this.lead_details_country,
            city: this.lead_details_city,
            requirement_message: this.lead_details_requirement_message,
            project_name: this.lead_details_project,
            timelapse_cameras: this.lead_details_timelapseRadios,
            interiors_360: this.lead_details_droneRadios,
            material_tracking: this.lead_details_interiorsRadios,
            drone_or_linear_monitoring: this.lead_details_materialRadios,
            special_requirement_or_interest: this
              .slead_details_pecial_requirement_or_interest,
            discussion_date: this.lead_details_discussion_date,
            status: this.lead_details_status,
            priority: this.lead_details_priority,
            followup_date: this.lead_details_followup_date,
            file: this.base64,
            uploadedFileName: this.fileName
          },
          method: "POST"
        })
          .then(resp => {
            if (resp.data.success) {
              this.snackbar = true;
              this.text = "Lead Details Updated Successfully.";
              this.getAllLeads();
            } else {
              this.snackbar = true;
              this.text = "Error While Updating New Details.";
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        axios({
          url: "/update/crmLeads/" + this.lead_id,
          data: {
            latest_discussion: this.lead_details_latest_discussion,
            lead_owner_id: this.lead_details_lead_owner,
            lead_sub_owner: this.lead_details_lead_sub_owner,
            profit_sharing: this.lead_details_profit_sharing,
            full_name: this.lead_details_full_name,
            company_name: this.lead_details_company_name,
            website: this.lead_details_website,
            email: this.lead_details_email,
            mobile_number: this.lead_details_mobile_number,
            country: this.lead_details_country,
            city: this.lead_details_city,
            requirement_message: this.lead_details_requirement_message,
            project_name: this.lead_details_project,
            timelapse_cameras: this.lead_details_timelapseRadios,
            interiors_360: this.lead_details_droneRadios,
            material_tracking: this.lead_details_interiorsRadios,
            drone_or_linear_monitoring: this.lead_details_materialRadios,
            special_requirement_or_interest: this
              .slead_details_pecial_requirement_or_interest,
            discussion_date: this.lead_details_discussion_date,
            status: this.lead_details_status,
            priority: this.lead_details_priority,
            followup_date: this.lead_details_followup_date,
            file: this.base64,
            uploadedFileName: this.fileName
          },
          method: "POST"
        })
          .then(resp => {
            if (resp.data.success) {
              this.snackbar = true;
              this.text = "New Discussion Added Successfully.";
              this.getAllLeads();
            } else {
              this.snackbar = true;
              this.text = "Error While Adding New Lead.";
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    leadDiscussions() {
      axios({
        url: "/getList/crmLeadDiscussions/" + this.lead_id,
        method: "GET"
      })
        .then(resp => {
          this.LeadDiscussions = resp.data.success;
          this.leadDiscussionsCard = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getColor(priorityBoolToText) {
      if (priorityBoolToText == "High") return "red";
      else if (priorityBoolToText == "Medium") return "orange";
      else if (priorityBoolToText == "Low") return "blue";
      else return "white";
    },
    resetForm() {
      this.$refs.form.reset();
    },
    createBase64Image: function (FileObject) {
      var fileData = event.target.files[0];
      this.fileName = fileData.name;
      const reader = new FileReader();
      reader.onload = event => {
        this.base64 = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    getAttachments() {
      axios({
        url: "/getList/crmLeadAttachments/" + this.lead_id,
        method: "GET"
      })
        .then(resp => {
          this.LeadAttachments = resp.data.success;
          this.attachmentsCard = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    showRequirementDetails(requirement) {
      event.stopPropagation();
      this.requirementMsgDetails = requirement;
      this.requirementMsg = true;
    },
    showLatestDiscussionDetails(id) {
      event.stopPropagation();
      axios({ url: "/getList/crmLeadDiscussions/" + id, method: "GET" })
        .then(resp => {
          this.latestDiscussionDetailsText = resp.data.success;
          this.latestDiscussionDetails = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    showSpecialRequirementDetails(specialRequirement) {
      event.stopPropagation();
      this.specialRequirementDetailsText = specialRequirement;
      this.specialRequirementDetails = true;
    },
    openCharts() {
      if (this.chartsVisible) {
        
      //leadsStatusGraph
      var statusType = ['in_discussion', 'for_presentation', 'converted', 'not_converted'];

      var statusCountsVar = this.allLeadsList.reduce((counts, item) => {
        let status = item.status;
        counts[status] = (counts[status] || 0) + 1;
        return counts;
      }, {});

      this.leadsStatusSeries[0].data = statusType.map(status => statusCountsVar[status] || 0);

      //leadsStatusByOwnerGraph
      var leadOwners = [...new Set(this.allLeadsList.map(item => item.lead_owner_email))];

      this.LeadsByOwnerChartOptions.xaxis.categories = leadOwners;

      const statusSequence = ['in_discussion', 'for_presentation', 'converted', 'not_converted'];
      let statusCounts = Object.fromEntries(statusSequence.map(s => [s, {}]));
      this.allLeadsList.forEach(item => {
        let owner = item.lead_owner_email;
        let status = item.status;


        if (status in statusCounts) {
          statusCounts[status][owner] = (statusCounts[status][owner] || 0) + 1;
        }
      });

      // Convert statusCounts to an array of objects
      let statusCountsArray = statusSequence.map(status => ({
        name: status,
        data: Object.values(statusCounts[status])
      }));

      this.LeadsByOwnerSeries = statusCountsArray

      // leadsGenerationByMonth
      let twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

      var datesWithLeadsCreatedCount = this.allLeadsList.reduce((counts, lead) => {
        let dateCreated = new Date(lead.date_created);

        // Ignore leads that were created more than two years ago
        if (dateCreated < twoYearsAgo) {
          return counts;
        }

        // Group by month and year
        let monthYear = dateCreated.toISOString().slice(0, 7);

        counts[monthYear] = (counts[monthYear] || 0) + 1;

        return counts;
      }, {});

      let sortedArrayBydatesInAscendingOrder = Object.entries(datesWithLeadsCreatedCount).sort((a, b) => new Date(a[0]) - new Date(b[0])).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      this.leadsCreationTimelineSeries[0].data = Object.values(sortedArrayBydatesInAscendingOrder);

      this.leadsCreationTimelineChartOptions.xaxis.categories = Object.keys(sortedArrayBydatesInAscendingOrder);


      this.leadsByPriorityGraph();

      var countryWiseLeads = this.allLeadsList.reduce((counts, lead) => {
        //Normalize the country name to a consistent format
        let country = lead.country.toUpperCase();

        if (!counts[country]) {
          counts[country] = { country: country, count: 0 };
        }

        counts[country].count += 1;

        return counts;
      }, {});
      // Convert the countryWiseLeads object to an array
      var countryWiseLeadsArray = Object.values(countryWiseLeads);

      var extractedData = countryWiseLeadsArray.map(item => {
        return {
          x: item.country,
          y: item.count
        };
      });

      this.leadsCountryWiseSeries[0].data = extractedData;




      let now = new Date();

      let durations = [
        { name: 'Less than 15 days', value: 15 },
        { name: '1 month', value: 30 },
        { name: '2 months', value: 60 },
        { name: '3 months', value: 90 },
        { name: '6 months', value: 180 },
        { name: 'More', value: Infinity }
      ];

      let statuses = ['converted', 'for_presentation', 'in_discussion'];

      let leadsByDurationAndStatus = durations.map(duration => {
        let leads = this.allLeadsList.filter(lead => {
          let createDate = new Date(lead.date_created);

          let timestamp = lead.updated_at;
          let date = new Date(timestamp);

          let day = date.getDate();
          let month = date.getMonth() + 1; // JavaScript months are 0-11
          let year = date.getFullYear();

          let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;


          let updateDate = new Date(formattedDate);
          let daysSinceCreation = (now - createDate) / (1000 * 60 * 60 * 24);
          let daysSinceUpdate = (now - updateDate) / (1000 * 60 * 60 * 24);

          return daysSinceCreation <= duration.value && daysSinceUpdate <= duration.value;
        });

        let leadsByStatus = statuses.reduce((counts, status) => {
          counts[status] = leads.filter(lead => lead.status === status).length;
          return counts;
        }, {});

        return {
          duration: duration.name,
          leadsByStatus: leadsByStatus
        };
      });

      let duration = ['Less than 15 days', '1 month', '2 months', '3 months', '6 months', 'More'];
      let statusList = ['converted', 'for_presentation', 'in_discussion'];

      let leadsByStatusArray = statusList.map(name => {
        let data = duration.map(duration => {
          let item = leadsByDurationAndStatus.find(item => item.duration === duration);
          return item ? item.leadsByStatus[name] : 0;
        });

        return { name, data };
      });


      // this.leadsAgeAnalysisChartOptions.xaxis.categories = duration;
      console.log(leadsByStatusArray);
      this.leadsAgeAnalysisSeries = leadsByStatusArray;
      console.log(this.leadsAgeAnalysisSeries);


      this.showcharts = true;
      this.crmCharts = true;
      }else{
        this.showcharts = false;
      this.crmCharts = false;
      }
    },
    leadsStatusGraph() {

      var statusList = ['in_discussion', 'for_presentation', 'converted', 'not_converted'];

      var statusCountsVar = this.filteredLeadsList.reduce((counts, item) => {
        let status = item.status;
        counts[status] = (counts[status] || 0) + 1;
        return counts;
      }, {});


      this.showcharts = true;
      this.$refs.leadStatus.updateSeries([{
        data: statusList.map(status => statusCountsVar[status] || 0)
      }], true);


    },
    leadsStatusByOwnerGraph() {
      var leadOwners = [...new Set(this.filteredLeadsListStatusByOwner.map(item => item.lead_owner_email))];
      // this.LeadsByOwnerChartOptions.xaxis.categories = leadOwners.filter(owner => owner !== null);
      this.$refs.leadStatusByOwner.updateOptions([{
        xaxis: {
          categories: leadOwners,
        },
      }], true);

      const statusSequence = ['in_discussion', 'for_presentation', 'converted', 'not_converted'];
      let statusCounts = Object.fromEntries(statusSequence.map(s => [s, {}]));
      this.filteredLeadsListStatusByOwner.forEach(item => {
        let owner = item.lead_owner_email;
        let status = item.status;

        // Normalize the status to a consistent format
        status = status.toLowerCase().replace(/\s+/g, '-');

        if (status in statusCounts) {
          statusCounts[status][owner] = (statusCounts[status][owner] || 0) + 1;
        }
      });

      // Convert statusCounts to an array of objects
      let statusCountsArray = statusSequence.map(status => ({
        name: status,
        data: Object.values(statusCounts[status])
      }));

      this.$refs.leadStatusByOwner.updateSeries(statusCountsArray, true);
    },
    leadsGenerationByMonth() {
      let twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

      var datesWithLeadsCreatedCount = this.filteredLeadsListByCreationTimeline.reduce((counts, lead) => {
        let dateCreated = new Date(lead.date_created);

        // Ignore leads that were created more than two years ago
        if (dateCreated < twoYearsAgo) {
          return counts;
        }

        // Group by month and year
        let monthYear = dateCreated.toISOString().slice(0, 7);

        counts[monthYear] = (counts[monthYear] || 0) + 1;

        return counts;
      }, {});

      let sortedArrayBydatesInAscendingOrder = Object.entries(datesWithLeadsCreatedCount).sort((a, b) => new Date(a[0]) - new Date(b[0])).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      this.$refs.leadsCreationTimeline.updateSeries([{
        data: Object.values(sortedArrayBydatesInAscendingOrder)
      }], true);
      this.$refs.leadsCreationTimeline.updateOptions([{
        xaxis: {
          categories: Object.keys(sortedArrayBydatesInAscendingOrder),
        },
      }], true);
    },
    leadsByPriorityGraph() {
      let priorityCounts = this.allLeadsList.reduce((counts, lead) => {
        let priority = lead.priority;
        counts[priority] = (counts[priority] || 0) + 1;
        return counts;
      }, {});

      let highCount = priorityCounts[0] || 0;
      let lowCount = priorityCounts[2] || 0;
      let mediumCount = priorityCounts[1] || 0;
      this.leadsPrioritySeries = [highCount, mediumCount, lowCount];
    },
    leadsCountryWiseGraph() {
      var countryWiseLeads = this.filteredLeadsListCountryWise.reduce((counts, lead) => {
        let country = lead.country.toUpperCase();

        if (!counts[country]) {
          counts[country] = { country: country, count: 0 };
        }
        counts[country].count += 1;
        return counts;
      }, {});

      // Convert the countryWiseLeads object to an array
      var countryWiseLeadsArray = Object.values(countryWiseLeads);

      var extractedData = countryWiseLeadsArray.map(item => {
        return {
          x: item.country,
          y: item.count
        };
      });

      this.$refs.leadsCountryWise.updateSeries([{
        data: extractedData
      }], true);

    },
    leadsAgeAnalysisGraph() {
      let now = new Date();

      let durations = [
        { name: 'Less than 15 days', value: 15 },
        { name: '1 month', value: 30 },
        { name: '2 months', value: 60 },
        { name: '3 months', value: 90 },
        { name: '6 months', value: 180 },
        { name: 'More', value: Infinity }
      ];

      let statuses = ['converted', 'for_presentation', 'in_discussion'];

      let leadsByDurationAndStatus = durations.map(duration => {
        let leads = this.filteredLeadsListAgeAnalysis.filter(lead => {
          let createDate = new Date(lead.date_created);

          let timestamp = lead.updated_at;
          let date = new Date(timestamp);

          let day = date.getDate();
          let month = date.getMonth() + 1; // JavaScript months are 0-11
          let year = date.getFullYear();

          let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;


          let updateDate = new Date(formattedDate);
          let daysSinceCreation = (now - createDate) / (1000 * 60 * 60 * 24);
          let daysSinceUpdate = (now - updateDate) / (1000 * 60 * 60 * 24);

          return daysSinceCreation <= duration.value && daysSinceUpdate <= duration.value;
        });

        let leadsByStatus = statuses.reduce((counts, status) => {
          counts[status] = leads.filter(lead => lead.status === status).length;
          return counts;
        }, {});

        return {
          duration: duration.name,
          leadsByStatus: leadsByStatus
        };
      });

      let duration = ['Less than 15 days', '1 month', '2 months', '3 months', '6 months', 'More'];
      let statusList = ['converted', 'for_presentation', 'in_discussion'];

      let leadsByStatusArray = statusList.map(name => {
        let data = duration.map(duration => {
          let item = leadsByDurationAndStatus.find(item => item.duration === duration);
          return item ? item.leadsByStatus[name] : 0;
        });

        return { name, data };
      });

      this.$refs.leadsAgeAnalysis.updateSeries(leadsByStatusArray, true);
    }
  }
};
</script>
<style>
.newLeadButton {
  position: absolute;
  right: 19px;
  top: 18px;
}

.alignFilter {
  margin-top: -11px;
}

.lead_date {
  left: 10px;
  position: absolute;
}

.lead_heading {
  margin-top: 18px;
  margin-left: 10px;
}

.lead_attachment {
  text-align: center;
  font-size: large;
}

.trim {
  display: inline-block;
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
