<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">

            <v-btn color="#ffc400" class="ma-2 Block--text uploadDrawing" @click="uploadDrawignaForm = true" v-if="this.zoneid > 0">
                {{lang.UploadDrawing}}
                <v-icon right dark>
                    mdi-arrow-up-bold
                </v-icon>
            </v-btn>
            <v-btn color="#ffc400" class="ma-2 Block--text uploadDrawing" @click="addZoneform = true">
                {{lang.AddZone}}
                <v-icon right dark>
                    mdi-plus
                </v-icon>
            </v-btn>

        </v-col>
        <v-col v-for="n in drawings" :key="n.drawingid" class="d-flex child-flex drawingitem" cols="12"
           xs="12"
           sm="6"
           md="3"
           lg="3"
           xl="3">
           <div class="drawingname ffull">
                    {{n.name}}

                    <v-btn class="mx-2 updatedrawign" fab x-small color="#ffc400" @click="updateDrawing(n)">
                        <v-icon dark>
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>

                    <v-btn class="mx-2 removedrawing" v-if="userdata.email == 'anshul.jain@opticvyu.com'" fab x-small color="#ffc400" @click="removeDrawing(n.drawingid)">
                        <v-icon dark>
                            mdi-delete
                        </v-icon>
                    </v-btn>

                      <v-btn class="mx-2 removedrawing" v-if="userdata.email !== 'anshul.jain@opticvyu.com'" fab x-small color="#ffc400" @click="removeDrawingbyAdminRequest(n)">
                        <v-icon dark>
                            mdi-delete
                        </v-icon>
                    </v-btn>

                    

                </div>
            <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
                
                <div class="drawingname dfooter">
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-axis-z-rotate-clockwise
                        </v-icon>
                    </v-btn>
                    {{n.numberofrotet}}
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-camera
                        </v-icon>
                    </v-btn>
                    {{n.numberofimages}}
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-play
                        </v-icon>
                    </v-btn>
                    {{n.numberofvideos}}

                </div>

            </v-img>
        </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" persistent max-width="600" color="amber accent-3" v-model="uploadDrawignaForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.UploadDrawing}} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="submitDrawingform">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="drawingname" :rules="requerdRules" :label="lang.DrawingName" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="floor" :label="lang.Floor" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="area" :label="lang.Area" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-lable>{{lang.SelectImage}} </v-lable>
                                <input type="file" ref="file" required :rules="requerdRules" accept="image/png, image/jpeg" @change="selectFile" />
                            </v-col>
                            <img id="small-image" width="150px">

                            <small>{{imgsize}} MB</small>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="uploadDrawignaForm = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container> 
                </v-form>
            </v-card>
        </template>
    </v-dialog>


    <v-dialog transition="dialog-bottom-transition" persistent max-width="600" color="amber accent-3" v-model="updateform">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Update Drawing. </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="updateDrawingform">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="update.drawingname" :rules="requerdRules" :label="lang.DrawingName" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="update.floor" :label="lang.Floor" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="update.area" :label="lang.Area" required></v-text-field>
                            </v-col>
                            
                             <v-col cols="12" sm="12">
                                <v-text-field v-model="update.point_size" type="number" min="1" max="9" label="Point Size" required></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="updateform = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>


    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="addZoneform">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.NewZone}} </v-toolbar>
                <v-form v-model="validzone" lazy-validation @submit.prevent="submitZoneform">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="newzonename" :rules="requerdRules" :label="lang.zoneName" required></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!validzone" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="addZoneform = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

        <div class="imageeditor active drawings" v-if="showannotation">
        <v-btn icon dark @click="showannotationclose" class="oveicon clsoe">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn dark text @click="done()" class="oveicon save">
            Done
        </v-btn>
        <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options" :key="tui_re_render" v-if="showannotation"></tui-image-editor>
    </div>

    <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import image1 from '../../assets/e.png'
import { locals } from "../../utils/locals";
import {
    ImageEditor
} from "@toast-ui/vue-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
export default {
    components: {
        Preloader,
        "tui-image-editor": ImageEditor,
    },
    name: "interiorViewEdit",
    data() {
        return {
            preloader: false,
            tui_re_render: 0,
            projectid: 0,
            requerdRules: [
                v => !!v || 'This field is required',
            ],
            zoneid: 0,
            image1: image1,
            loafin: false,
            drawings: {},
            uploadDrawignaForm: false,
            drawing: {},
            proj_dir: '',
            drawingname: '',
            floor: '',
            area: '',
            update : {
                drawingname: '',
                floor: '',
                area: '',
                point_size: 1,
                did : ''

            },
            thumbnail: '',
            send: false,
            valid: true,
            alertmessage: '',
            alert: false,
            addZoneform: false,
            validzone: true,
            newzonename: '',
            Local :locals,  
            lang : {},
            showannotation : false,
             useDefaultUI: true,
             options: {
                cssMaxWidth: 1000,
                cssMaxHeight: 600,
                includeUI: {
                    loadImage: {
                        path: image1,
                        name: "SampleImage",
                    },
                    initMenu: "crop",
                    menuBarPosition: "left",
                    menu : ['crop', 'flip', 'rotate']
                },
                ErrorMsg: 'Sorry Image is not load',
            },
            updateform : false,
            imgsize : 0
        };
    },
    
    mounted() {
          var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng]; 

      //  var openDrawing  =  localStorage.getItem("openDrawing");

       // this.goToDrawingdirect(openDrawing);

      // console.log("openDrawing",this.$route.from);

        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
            this.useraccess = true
        }
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        //console.log("Current data get",this.$store.state.currentCamera)
        if (this.userdata.interior_editor_access == 0 && currentProject.interior_status != 'Running') {
              this.$router.push('/dashboard/user-interior-view')
        }
       
       this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
        this.projectid = currentProject.id;
        this.prefix = currentProject.pro_dir;
        this.zoneid = this.$store.state.currentZone;
        this.initViewer();
        this.getDrawings();
    },
    watch: {
        '$store.state.currentZone': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.prefix = currentProject.pro_dir;
            this.getDrawings();
        }
    },
    computed: {

    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false;

            // this.getcoordinates();
        },
        getDrawings() {
            axios({
                    url: '/interior/project/' + this.projectid + '/get-drawings',
                    method: 'get',
                    params: {
                        zone: this.zoneid
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.drawings = resp.data.success;
                    console.log("dfasfasdf", resp.data)
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },

        selectFile() {

            //this.drawing = this.$refs.file.files[0];
            
            var width = 450;

            var _self = this;
            var reader = new FileReader();
            reader.onload = function (event) {
                var img = new Image();
                img.onload = function () {
                  
                    var ocf = document.createElement('canvas'),
                        octxf = ocf.getContext('2d');
                        ocf.width = img.width;
                        ocf.height = img.height;
                        octxf.drawImage(img, 0, 0);
                        octxf.drawImage(img, 0, 0, ocf.width, ocf.height);
                         _self.options.includeUI.loadImage.path = ocf.toDataURL();

                    if (img.width > width) {
                        var oc = document.createElement('canvas'),
                            octx = oc.getContext('2d');
                        oc.width = img.width;
                        oc.height = img.height;
                        octx.drawImage(img, 0, 0);
                        while (oc.width * 0.5 > width) {
                            oc.width *= 0.5;
                            oc.height *= 0.5;
                            octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        }
                        oc.width = width;
                        oc.height = oc.width * img.height / img.width;
                        octx.drawImage(img, 0, 0, oc.width, oc.height);
                        document.getElementById('small-image').src = oc.toDataURL();
                        _self.thumbnail = oc.toDataURL(); 
                       
                       
                    } else {
                        document.getElementById('small-image').src = img.src;

                        _self.thumbnail = img.src;
                    }

                    
                     _self.showannotation = true;
                };
                img.src = event.target.result;

                
            };
          reader.readAsDataURL(this.$refs.file.files[0]);
          //  this.options.includeUI.loadImage.path =  a;
        },
        submitDrawingform() {
            this.send = true;
            let formData = new FormData();

            formData.append("file", this.drawing);
            formData.append("projectId", this.projectid);
            formData.append("proj_dir", this.prefix);
            formData.append("drawingname", this.drawingname);
            formData.append("floor", this.floor);
            formData.append("area", this.area);
            formData.append("zoneid", this.zoneid);
            formData.append("thumbnail", this.thumbnail);

            console.log("formdata", this.thumbnail);

            axios.post('/interior/project/' + this.projectid + '/add-drawings', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(resp => {
                    console.log("Drawing resonse", resp);

                    this.send = false;
                    this.uploadDrawignaForm = false;
                    this.alert = true;
                    this.alertmessage = this.lang.drawingupload;
                    this.getDrawings();

                })
                .catch(err => {
                    this.send = false;
                    this.uploadDrawignaForm = false;
                    console.log("Drawing resonse Error", err)
                })
        },
        updateDrawingform(){
               this.preloader = true;

            axios({
                        url: 'interior/update-drawing',
                        method: 'post',
                        data: this.update
                    })
                    .then(resp => {
                        console.log("headings", resp);
                        this.alert = true;
                        this.alertmessage = "Drawing updated successfully";
                        this.updateform = false;
                           this.preloader = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                         this.updateform = false;
                    })

        },
        removeDrawingbyAdminRequest(n){  // send request to delete Interior drawing.
           var conf = confirm('Do you want to send drawing delete request to OpticVyu admin ?');
                console.log("n",n);
            if (conf) {
                 let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                 let zonename = localStorage.getItem('currentZoneName');
                axios({
                        url: 'interior/remove-drawings-request',
                        method: 'post',
                        data: {
                            drawingname: n.name,
                            projectname : currentProject.project_name,
                            zone_name: zonename,
                            userfirst : this.userdata.firstname,
                            userlast : this.userdata.lastname,
                            email:  this.userdata.email,
                        }
                    })
                    .then(resp => {
                        console.log("headings", resp);
                        this.alert = true;
                        this.alertmessage = "Your Drawing Delete request was sent to the admin successfully...The drawing will be deleted soon";
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }
        },
        removeDrawing(id) {
            var conf = confirm(this.lang.drawingconfirm);

            if (conf) {
                axios({
                        url: 'interior/remove-drawings',
                        method: 'post',
                        data: {
                            did: id
                        }
                    })
                    .then(resp => {
                        console.log("headings", resp);
                        this.alert = true;
                        this.alertmessage = this.lang.drawingremove;

                            this.$router.push({
                             name: 'interior_editdrawings'
                            });
                      //  this.getDrawings();
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }else{
                          this.$router.push({
                             name: 'interior_editdrawings'
                            });
            }
        },
        goToDrawing(n) {
            this.$router.push({
                name: 'interior_viewedit',
                params: {
                    id: n.drawingid
                }
            });
        },
         goToDrawingdirect(n) {
            this.$router.push({
                name: 'interior_viewedit',
                params: {
                    id: n
                }
            });
        },
        submitZoneform() {
            axios({
                    url: '/interior/project/' + this.projectid + '/add-zone',
                    method: 'get',
                    params: {
                        zonename: this.newzonename
                    }
                })
                .then(() => {
                    this.alert = true;
                    this.alertmessage = this.lang.addezone;
                    this.addZoneform = false;
                    this.$router.go();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
    //     done(){
            
    //         const myImageb = this.$refs.tuiImageEditor.invoke("toDataURL");
            
    //         this.drawing = myImageb;
    //        // console.log("Edit data", myImageb);
             
    //   //  var _scale = 0.2;
    //     var img = document.createElement('img');
    //     img.setAttribute("src", myImageb);
    //   var _self =  this;
    //  img.onload = () => {
    //      var canvas = document.createElement('canvas');
         
    //     //   if(img.height > 1500){
    //     //        _scale = 0.3;
    //     //   }else if(img.height > 1200){
    //     //      _scale = 0.5;
    //     //   }else if(img.height > 800){
    //     //       _scale = 0.6;
    //     //   }

    //     //    console.log(" _scale", _scale);
    //     //    console.log("height", img.height);

    //     //  canvas.width = img.width * _scale;
    //     // canvas.height = img.height * _scale;

    //     //  var ctx = canvas.getContext("2d");
    //     //  //var cw = canvas.width;
    //     // // var ch = canvas.height;
    //     //  var maxW = img.width * _scale;
    //     //  var maxH = img.height * _scale;

    //     // var iw = img.width;
    //     //  var ih = img.height;
    //     //  var scl = Math.min((maxW / iw), (maxH / ih));
    //     //  var iwScaled = iw * scl;
    //     //  var ihScaled = ih * scl;
    //     //   console.log(" _scale", _scale);
    //     //    console.log("resize height", ihScaled);
    //     //  canvas.width = iwScaled;
    //     //  canvas.height = ihScaled;
    //     //  ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    //     //  const newBase64 = canvas.toDataURL("image/jpeg", scl);


    //         //    const width = Math.min(800, img.width);
    //         //     const scaleFactor = width / img.width;
    //         //     canvas.width = width;
    //         //     canvas.height = img.height * scaleFactor;
 
    //         //     const ctx = canvas.getContext('2d');
    //         //     // img.width and img.height will contain the original dimensions
    //         //     ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);


    //              const height = Math.min(600, img.height);
    //             const scaleFactor = height / img.height;
    //             canvas.height = height;
    //             canvas.width = img.width * scaleFactor;

    //             const ctx = canvas.getContext('2d');
    //             // img.width and img.height will contain the original dimensions
    //             ctx.drawImage(img, 0, 0, img.width * scaleFactor, height);
          
    //         document.getElementById('small-image').src = newBase64;
    //         const newBase64 = canvas.toDataURL("image/jpeg");
    //         this.drawing = newBase64;
    //         //_self.thumbmail();
    //        // this.thumbnail = newBase64;
            
    //         };
    //     },
        done(){
              const myImageb = this.$refs.tuiImageEditor.invoke("toDataURL");
            
           // this.drawing = myImageb;
          //  console.log("Edit data", myImageb);

           this.comspressDrawing(myImageb);
             
        var _scale = 0.8;
        var img = document.createElement('img');
        img.setAttribute("src", myImageb);

     img.onload = () => {
         var canvas = document.createElement('canvas');
            _scale = 0.3;
         
         canvas.width = img.width * _scale;
        canvas.height = img.height * _scale;

         var ctx = canvas.getContext("2d");
         //var cw = canvas.width;
        // var ch = canvas.height;
         var maxW = img.width * _scale;
         var maxH = img.height * _scale;

        var iw = img.width;
         var ih = img.height;
         var scl = Math.min((maxW / iw), (maxH / ih));
         var iwScaled = iw * scl;
         var ihScaled = ih * scl;
        
         canvas.width = iwScaled;
         canvas.height = ihScaled;
         ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
         const newBase64 = canvas.toDataURL("image/jpeg", scl);
          
            document.getElementById('small-image').src = newBase64;
            // this.drawing = newBase64;
             this.thumbnail = newBase64;
             this.showannotation = false;
            };
        },
         comspressDrawing(myImageb){
          
        var img = document.createElement('img');
        img.setAttribute("src", myImageb);

     img.onload = () => {
         var canvas = document.createElement('canvas');
          
         
         canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

         var ctx = canvas.getContext("2d");
         ctx.drawImage(img, 0, 0);
         const newBase64 = canvas.toDataURL("image/jpeg");

         var stringLength = newBase64.length - 'data:image/png;base64,'.length;

var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
var sizeInKb=sizeInBytes/1000;
          
          var mb = sizeInKb / 1000;

          this.imgsize  = Math.round(mb);
           console.log("img", img);
            //document.getElementById('tt').src = newBase64;
            this.drawing = newBase64;
            }
         }, 


        showannotationclose() {
            this.tui_re_render++;
            this.showannotation = false;
            self.$refs.tuiImageEditor.invoke("clearObjects");
        },
        updateDrawing(data){
           this.updateform = true;
            console.log(data);
           this.update = {
               drawingname: data.name,
                floor: data.floor,
                area: data.area,
                point_size: data.point_size,
                did : data.drawingid
           }
        }
        
    }

};
</script>

<style>
.uploadDrawing {
    float: right;
}
.drawingitem {
     position: relative;
}
.drawingitem .v-image {
    border: 3px solid #292929 !important;
    border-radius: 3px;
}

.drawingname {
    background: #000000d9;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 6px 0;
    text-shadow: 0px 0px 2px black;
    position: absolute;
    width: calc( 100% - 24px);
    /* left: 0; */
    z-index: 1;
}
.drawingname.dfooter {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.removedrawing {
    position: absolute;
    right: 0;
    top: 0px;
}

.imageeditor.active.drawings{
   z-index: 99999;
   position:fixed;
}
.updatedrawign {
    position: absolute;
    right: 40px;
    top: 0px;
}
.ffull {
    width: calc(100% - 26px)!important;
}
</style>