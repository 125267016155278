  
<template>
    <div id="map"></div>
  </template>
  
  <script>
  import axios from "axios";
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import 'leaflet.gridlayer.googlemutant';
 // import { LMap, LTileLayer, LMarker, LPolyline } from 'vue2-leaflet';
  import 'leaflet-draw/dist/leaflet.draw.css';
  import 'leaflet-draw';
  
  export default {
    name: 'MapComponent',
//     components: {
//     LMap,
//     LTileLayer,
//     LMarker,
//     LPolyline
//   },
  data() {
    return {
      map: null,
      polyline: null,
      drawnItems: new L.FeatureGroup(),
      currentProject : '',
      jsonData: null,
      
    };
  },
    mounted() {

         

          this.initMap();

    },
    watch: {
    "$store.state.currentvisitId": function () {
       this.initMap();
    }
  },
    methods: {
         fetchXmlData() {

           

            var pid = this.currentProject.id;
            this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));
            
            var visit_date = this.visitdata.visit_date;

            console.log("this.visitdata",this.visitdata.visit_date);


           var xmlurl = 'https://opticvyu-temp.s3.amazonaws.com/ortho/'+pid+'/'+visit_date+'/tilemapresource.xml';

        axios({
        url: 'https://opticvyu.com/api/getDronxmlFiledatatojson',
        method: "GET",
        params: {
            xmlurl : xmlurl
        }
      })
        .then(response => {
           console.log("this.xmlToJson(xm",response);
           this.jsondata = response.data;
            this.mapenit();
        })
        .catch(err => {
          console.log("Error in current visit data xml data", err);
        });
    },
    xmlToJson(xml) {
      // Create the return object
      let obj = {};
      
      if (xml.nodeType === 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (let j = 0; j < xml.attributes.length; j++) {
            const attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType === 3) { // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
          const item = xml.childNodes.item(i);
          const nodeName = item.nodeName;
          if (typeof obj[nodeName] === "undefined") {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push === "undefined") {
              const old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      }
      return obj;
    },
      loadGoogleMapsScript() {
        return new Promise((resolve, reject) => {
          if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            resolve();
          } else {
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC4Kv9QiLIdKfBOsj7xPD1XYwQCgbzrlzY';
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
          }
        });
      },
      initMap() {

        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
        this.loadGoogleMapsScript().then(() => {

            this.fetchXmlData();
          // Google Maps base layers
         
        }).catch(error => {
          console.error('Error loading Google Maps script:', error);
        });
      },
       mapenit(){

        const googleRoadmap = L.gridLayer.googleMutant({
            type: 'roadmap'
          });
  
          const googleSatellite = L.gridLayer.googleMutant({
            type: 'satellite'
          });
  
          const googleHybrid = L.gridLayer.googleMutant({
            type: 'hybrid'
          });
  
          const googleTerrain = L.gridLayer.googleMutant({
            type: 'terrain'
          });
  
          // Overlay layers (TMS)
          //https://opticvyu-temp.s3.amazonaws.com/ortho/1/1/1.png

          var pid = this.currentProject.id;
          this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

          var visit_date = this.visitdata.visit_date;

           console.log("this.visitdata- ",visit_date);

          var lyr = L.tileLayer('https://opticvyu-temp.s3.amazonaws.com/ortho/'+pid+'/'+visit_date+'/{z}/{x}/{y}.png', { tms: true, opacity: 1, attribution: "", minZoom: 0, maxZoom: 18 });
  
          // Map

           console.log("lyr",lyr);
           
         var maxx = this.jsondata.BoundingBox["@attributes"].maxx;

         var maxy = this.jsondata.BoundingBox["@attributes"].maxy;

         var minx = this.jsondata.BoundingBox["@attributes"].minx;

         var miny = this.jsondata.BoundingBox["@attributes"].miny;


         var cx = this.jsondata.Origin["@attributes"].x;
         var cy = this.jsondata.Origin["@attributes"].y;

            if (this.map) {
            // Remove the existing map instance
                this.map.remove();
            }

          this.map = L.map('map', {
            center: [cy, cx],
            zoom: 18, 
            minZoom: 0,
            maxZoom: 18,
            layers: [googleRoadmap]  // Set Google Maps roadmap as the default layer
          });

          let map = this.map;

          
  
          const basemaps = {
            "Google Roadmap": googleRoadmap,
            "Google Satellite": googleSatellite,
            "Google Hybrid": googleHybrid,
            "Google Terrain": googleTerrain
          };
  
          const overlaymaps = { "Orthophoto": lyr };
  
          // Title
          const title = L.control();
          title.onAdd = function(map) {
            console.log(map);
            this._div = L.DomUtil.create('div', 'ctl title');
            this.update();
            return this._div;
          };
          title.update = function(props) {
            console.log(props);
            this._div.innerHTML = "orthophoto_webodm.tif";
          };
          title.addTo(map);
  
          // Note
          const src = '';
          const note = L.control({ position: 'bottomleft' });
          note.onAdd = function(map) {
            console.log(map);
            this._div = L.DomUtil.create('div', 'ctl src');
            this.update();
            return this._div;
          };
          note.update = function(props) {
            console.log(props);
            this._div.innerHTML = src;
          };
          note.addTo(map);
  
          // Add base layers
          L.control.layers(basemaps, overlaymaps, { collapsed: false }).addTo(map);

          map.addLayer(this.drawnItems);

    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems
      },
      draw: {
        polyline: true,
        polygon: true,
        rectangle: true,
        circle: false,
        marker: true
      }
    });
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      this.drawnItems.addLayer(layer);

      if (layer instanceof L.Polyline) {
        const latlngs = layer.getLatLngs();
        const distance = this.calculateDistance(latlngs);
        alert(`Length: ${distance} meters`);
      }
    })

      // Fit the map to the polyline
      //map.fitBounds(polyline.getBounds());
  
          // Fit to overlay bounds (SW and NE points with (lat, lon))
          map.fitBounds([[maxy, maxx], [miny, minx]]);

       },
      calculateDistance(latlngs) {
      let totalDistance = 0;
      for (let i = 0; i < latlngs.length - 1; i++) {
        totalDistance += latlngs[i].distanceTo(latlngs[i + 1]);
      }
      return totalDistance.toFixed(2);
    }
    }
  };
  </script>
  
  <style>

  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height : 100vh;
    z-index: 1;
  } /* full size */

  </style>
