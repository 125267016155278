<template>
  <div>
    <Preloader v-if="preloader" />
    <v-row>
      <v-col cols="12" md="9">
        <v-card class="pa-5 openAccessUsers">
          <v-card-title> {{ lang.createOpenAccessHeading }} </v-card-title>
          <v-alert dense text :type="alertType" v-if="alert">
            <v-row align="center">
              <v-col class="grow">
                {{ alertmessage }}
              </v-col>
            </v-row>
          </v-alert>
          <v-snackbar v-model="snackbar" center top timeout="3000" type="success">
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar v-model="snackbarError" center top timeout="10000" type="success">
            {{ snackbarErrorText }}
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbarError = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
            <v-container>
              <v-row>
                <v-col cols="12" sm="5">
                  <v-select v-model="e7" :items="projects" :label="lang.selectProjectOpenAccess" item-text="project_name"
                    item-value="id" @change="checkAccessType()" multiple chips persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-checkbox v-model="interiorCheck" label="Interior" color="amber" :value="toggleInterior"
                    :disabled="!interiorEnabled" v-validate.continues="'atLeastOne'" hide-details></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox v-model="constructionCamCheck" label="Construction Camera" color="amber"
                    :value="toggleconstructionCam" :disabled="!constructionCamEnabled" v-validate.continues="'atLeastOne'"
                    hide-details></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox v-model="droneconstructionCheck" label="Drone Construction" color="amber"
                    :value="toggleconstructionDron" :disabled="!droneconstructionEnabled" v-validate.continues="'atLeastOne'"
                    hide-details></v-checkbox>
                </v-col>
                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                  {{ lang.createOpenAccessLinkButton }}
                </v-btn>
                <v-btn @click="clear" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
          <v-data-table :headers="headers" :items="openAccessUsersList" sort-by="firstname" class="elevation-1"
            :search="search">
            <template v-slot:item.link="{ item, index }">
              <a :href="link_url" class="text-dark truncate" :id="`textToCopy${index}`" target="_blank"
                rel="noopener noreferrer" ref="textToCopy">{{ baseURL }}{{ item.open_access_token }}</a>
              <v-btn v-on:click.native="copyTestingCode(index)" color="amber">Copy</v-btn>
            </template>
            <template v-slot:item.status="{ item }" :color="getColor(item.active)" dark>
              <v-btn :color="getColor(item.active)" v-on:click.native="
                changeOpenAccessUserStatus(item.active, item.id)
                " dark>{{ item.active == 1 ? "Active" : "Inactive" }}</v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary">
                {{ lang.Reset }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="setting_sidebar" cols="3" md="3" out-line>
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar
  },
  name: "Adduser",
  data() {
    return {
      valid: true,
      firstname: "openaccess",
      lastname: "",
      email: "",
      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      e7: [],

      usertype: "1",
      projects: {},
      useraccess: false,
      userinfo: false,
      dialog: false,
      ustatus: "",
      param_up: {},
      snackbar: false,
      snackbarError: false,
      text: "Something went wrong. Please reload and try again.",
      snackbarErrorText: "Please check atleast one check box.",
      Local: locals,
      lang: {},
      open_access: "1",
      headers: [
        { text: "Link", value: "link", align: "start" },
        { text: "Projects name", value: "project_names" },
        { text: "Status", value: "status" }
      ],
      openAccessUsersList: [],
      items: {},
      baseURL: "",
      interiorEnabled: false,
      constructionCamEnabled: false,
      droneconstructionEnabled: false,
      interiorCheck: false,
      constructionCamCheck: false,
      droneconstructionCheck : false,
      authAlternateString:"",
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.initViewer();
    this.getPorjects();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
      console.log("Redirect to dashboard");
    }
    this.baseURL = "https://open.opticvyu.com/openAccessDashboard/";
    this.getOpenAccessUsers();
  },
  watch: {
    "$store.state.currentCamera": function () {
      // console.log("Current data get",this.$store.state.currentCamera)
    }
  },
  computed: {
    toggleInterior() {
      return !this.interiorCheck;
    },
    toggleconstructionCam() {
      return !this.constructionCamCheck;
    },
    toggleconstructionDron() {
      return !this.droneconstructionCheck;
    },
    params() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        project: this.currentProject.id,
        project_ids: this.e7.toString(),
        user_code: this.usertype,
        cctvtimelapse_user: 0,
        currentProject: {},
        added_by: this.userdata.email,
        open_access: this.open_access,
        interior_access: this.interiorCheck && this.interiorEnabled ? 1 : 0,
        cont_camera_aceess: this.constructionCamCheck && this.constructionCamEnabled ? 1 : 0,
        linear_monitoring_access: this.droneconstructionCheck && this.droneconstructionEnabled ? 1 : 0,
        short_string:this.authAlternateString
      };
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      this.preloader = false;
    },
    generateAlphanumericString(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async submit() {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      const valid = await this.$refs.form.validate();
     // this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.lastname = this.currentProject.project_name;
      var date = new Date();
      var milliseconds = date.getTime();
      this.email = "openaccess" + milliseconds + "@opticvyu.com";
      this.usertype = "1";
      this.authAlternateString = this.generateAlphanumericString(10);
      if (valid) {
        // this.params.cont_camera_aceess = 0;
        // this.params.material_access = 0;
        // this.params.interior_access = 0;

        // if (this.userdata.cont_camera_aceess == 1) {
        //     this.params.cont_camera_aceess = this.userdata.cont_camera_aceess;
        // } else if (this.userdata.interior_access == 1) {
        //     this.params.interior_access = this.userdata.interior_access
        // } else if (this.userdata.material_access == 1) {
        //     this.params.material_access = this.userdata.material_access
        // }
        console.log("use data", this.params);
        if (this.params.interior_access || this.params.cont_camera_aceess || this.params.linear_monitoring_access) {
          this.adduser(this.params); // action to login
        } else {
          this.snackbarError = true;
        }
      }
    },
    adduser(userdata) {
      this.preloader = true;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
       //console.log('');
      axios({ url: "/users", data: userdata, method: "POST" })
        .then(resp => {
          if (resp.data.success) {
            this.alert = true;
            this.preloader = true;
            if (
              this.userdata.cont_camera_aceess == 1 &&
              this.userdata.material_access == 1 &&
              this.userdata.interior_access == 1 && 
              this.userdata.linear_monitoring_access == 1
            ) {
              this.alertmessage = this.lang.usermsgone; 
              //"Access given for construction camera. To manage access for Interior Monitoring & Material tracking, go to manage users";
            }
            if (
              this.userdata.cont_camera_aceess == 0 &&
              this.userdata.material_access == 1 &&
              this.userdata.interior_access == 1 && 
               this.userdata.linear_monitoring_access == 1
            ) {
              this.alertmessage = this.lang.usermsgtwo; 
              // "Access given for Interior monitoring module. To manage access for Material tracking, go to manage users";
            }
            if (
              this.userdata.cont_camera_aceess == 0 &&
              this.userdata.material_access == 1 &&
              this.userdata.interior_access == 0 &&
              this.userdata.linear_monitoring_access == 0
            ) {
              this.alertmessage = this.lang.usermsgthree; 
              //"User added successfully. Login instructions have been emailed to the new user.";
            }
            if (
              this.userdata.cont_camera_aceess == 1 &&
              this.userdata.material_access == 0 &&
              this.userdata.interior_access == 0
              && this.userdata.linear_monitoring_access == 0
            ) {
              this.alertmessage = this.lang.usermsgthree; 
              //"User added successfully. Login instructions have been emailed to the new user.";
            }
            if (
              this.userdata.cont_camera_aceess == 0 &&
              this.userdata.material_access == 0 &&
              this.userdata.interior_access == 1 && 
              this.userdata.linear_monitoring_access == 0
            ) {
              this.alertmessage = this.lang.usermsgthree; 
              //"User added successfully. Login instructions have been emailed to the new user.";
            }
            var addedUserEmail = this.email;
            var shortString = userdata.short_string
            this.loginOpenAccessUser(addedUserEmail,shortString), (this.preloader = false);
            this.clear();
          } else {
            console.log("error msg", resp.data);
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
            this.preloader = false;
            if (resp.data.errorr == 1) {
              this.userinfo = true;
              console.log("userinfo", this.userinfo);
            } else {
              this.userinfo = false;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clear() {
      this.lastname = "";
      this.email = "";
      this.e7 = "";
      this.usertype = "";
      this.userdata.email = "";
      this.interiorCheck = false;
      this.constructionCamCheck = false;
      this.droneconstructionCheck = false
      this.interiorEnabled = false;
      this.constructionCamEnabled = false;
      this.authAlternateString = "";
    },
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then(resp => {
          console.log("Projects", resp.data.success);
          this.projects = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });
          localStorage.setItem("projectList", JSON.stringify(this.projects));
        })
        .catch(err => {
          console.log(err);
        });
    },
    loginOpenAccessUser(addedUserEmail,shortString) {
      axios({
        url: "/login",
        params: { email: addedUserEmail, password: "password" },
        method: "POST"
      }).then(resp => {
        const obj = JSON.parse(resp.data);
        const token = obj.access_token;
        if (token != undefined) {
          this.updateOpenAccessToken(token, addedUserEmail,shortString);
        }
      });
    },
    updateOpenAccessToken(token, addedUserEmail,shortString) {
      axios({
        url: "user/updateOpenAccessToken",
        params: { email: addedUserEmail, open_access_token: token, short_string:shortString },
        method: "POST"
      }).then(resp => {
        this.getOpenAccessUsers();
        this.alert = false;
        this.alertmessage = this.lang.newUserOpenAccess;
        this.alertType = "success";
        const obj = JSON.parse(resp.data);
        console.log("resp.dataUpdate", obj);
      });
    },
    getOpenAccessUsers() {
      var userId = this.userdata.id;
      this.preloader = true;
      axios({
        url: "/user/" + userId + "/open_access_users",
        params: { project_id: this.currentProject.id },
        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          var variableBeta = [];
          var variableAlpha = [];
          resp.data.success.forEach(element => {
            element.forEach(item => {
              variableBeta.push(item);
              element.forEach(item => {
                variableAlpha.push(item);
              });
            });
          });
          const key = "id";
          const arrayUniqueByKey = [
            ...new Map(variableAlpha.map(item => [item[key], item])).values()
          ];
          // let myArray = [];

          arrayUniqueByKey.forEach(function(obj) {
              if (obj.short_string) {
                  obj.open_access_token = obj.short_string;
              }
          });
          this.openAccessUsersList = arrayUniqueByKey;
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
        });
    },
    getColor(active) {
      if (active == 0) return "#ec665cfc";
      else if (active == 1) return "#8bc34ac2";
      else return "#ff9800";
    },
    changeOpenAccessUserStatus(activeStatus, userId) {
      this.preloader = true;
      var activeState;
      if (activeStatus == 1) {
        activeState = 0;
      }
      if (activeStatus == 0) {
        activeState = 1;
      }
      axios({
        url: "user/" + userId + "/updateOpenAccessUserStatus",
        params: { active: activeState },
        method: "POST"
      })
        .then(resp => {
          if (resp.data.success) {
            this.getOpenAccessUsers();
            this.preloader = false;
          } else {
            this.alertmessage = this.lang.updateUserErrorOpenAccess;
            this.alertType = "error";
            this.preloader = false;
          }
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
        });
    },
    copyTestingCode(index) {
      var id = "textToCopy" + index;
      let textToCopy = document.getElementById(id).innerHTML;
      navigator.clipboard.writeText(textToCopy);
    },
    checkAccessType() {
      var projectId = this.e7.toString();
      var projectIdAarray = JSON.parse("[" + projectId + "]");
      var projectsList = JSON.parse(localStorage.getItem("projectList"));
      var checkInteriorVal = projectIdAarray.map(
        id => projectsList.find(el => el.id == id).interior
      );
      var checkConstructionCamVal = projectIdAarray.map(
        id => projectsList.find(el => el.id == id).const_camera
      );

      var checkdroneConstructionVal = projectIdAarray.map(
        id => projectsList.find(el => el.id == id).linear_monitoring
      );

      var interiorValAarray = JSON.parse("[" + checkInteriorVal + "]");
      var ConstructionCaminteriorValAarray = JSON.parse(
        "[" + checkConstructionCamVal + "]"
      );
      var droneValAarray = JSON.parse("[" + checkdroneConstructionVal + "]");

      this.interiorEnabled = Math.max(...interiorValAarray) == 1;
      this.constructionCamEnabled =
        Math.max(...ConstructionCaminteriorValAarray) == 1;
      this.droneconstructionEnabled = Math.max(...droneValAarray) == 1;
    }
  }
};
</script>

<style>
.openAccessUsers .v-data-footer,
.vhfix .v-data-footer {
  position: relative !important;
  bottom: 0;
  width: 100% !important;
  background: #fff;
  left: 0;
}

.truncate {
  display: inline-block;
  width: 231px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn:not(.v-btn--round).v-size--default {
  /* padding: 10px; */
  max-width: fit-content;
}
</style>
