<template>
    <!-- Your HTML/Template code goes here -->
    <div>
        <Preloader v-if="preloader" />

        <div class="actions">

             <div class="sepratorebox">

                <v-btn small dark class=" mr-2 mb-2" color="#A020F0"  @click="LoadPoints">
                    Load Strategic Points
                </v-btn>
                
                 <br>
                 <br>
            
                <v-btn small light class=" mr-2 mb-2" color="#fffff"  @click="incurrectImageFound">
                    Incorrect
                </v-btn>
                <p color="red" small v-if="incorectfound" >If the current image is incorrect, please find the correct image and press the 'Correct' button.</p>

                <v-btn small dark class=" mr-2 mb-2" color="#f8bb03" v-if="incorectfound" @click="ImageFound">
                    Correct
                </v-btn>
              
               
             </div>
              <!--  <br>
                <v-btn small class=" mr-2" :class="methodpath == 1 ? 'activebtcx' : ''"
                    :color="methodpath == 1 ? '#000' : '#fff'" @click="method1">
                    Method 1
                </v-btn>
                <v-btn small class=" mr-2" :class="methodpath == 2 ? 'activebtcx' : ''"
                    :color="methodpath == 2 ? '#000' : '#fff'" @click="method2">
                    Method 2
                </v-btn>
                <v-btn small class=" mr-2" :class="methodpath == 3 ? 'activebtcx' : ''"
                    :color="methodpath == 3 ? '#000' : '#fff'" @click="method3">
                    Method 3
                </v-btn>
                <br>
                <span v-if="methodpath == 3">

                    <small> Start Scale Factor </small> <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                mdi-information-slab-circle
                            </v-icon>

                        </template>
                        <span>1. This method is useful when the start & end points are in a horizontal or vertical line.
                            <br>
                            2. Use two scale factors to correctly fit the path between the start & end points. The 1st & 2nd
                            scale factor will be applied for the 1st & second half of the curve respectively.</span>
                    </v-tooltip>
                    <br>
                    <v-btn class="mx-2 scaledrawing-plus" fab x-small color="#fff" @click="goplus">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>

                    <spam class="scalevalue">
                        <input type="text" v-model="startScaleFactorx" style="width:50px; text-align:center" />
                    </spam>
                    <v-btn class="mx-2 mr-2 scaledrawing-minus" fab x-small color="#fff" @click="gominus">
                        <v-icon dark>
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <br>
                    <small> End Scale Factor </small>
                    <br>
                    <v-btn class="mx-2 mr-2 scaledrawing-plus" fab x-small color="#fff" @click="goplusend">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    <spam class="scalevalue"> <input type="text" v-model="endScaleFactory"
                            style="width:50px; text-align:center" /> </spam>
                    <v-btn class="mx-2 mr-2 scaledrawing-minus" fab x-small color="#fff" @click="gominusend">
                        <v-icon dark>
                            mdi-minus
                        </v-icon>

                    </v-btn>
                </span>
            </div>
                    <div class="sepratorebox">
                        <v-row>
                            <v-col cols="8" sm="8">
                                <v-text-field v-model="digree" label="Rotation Angle" type="number"
                                    style="text-align:center;width:150px"></v-text-field>

                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-btn small class="submitbutton" color="#fff" @click="rotateCoordinates">
                                    Rotate
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="sepratorebox">
                        <v-row>
                            <v-col cols="6" sm="6" class="pb-0">
                                <v-text-field v-model="shiftxx" label="X Value"  type="number"
                                    style="text-align:center"></v-text-field>
                               </v-col>
                              <v-col cols="6" sm="6" class="pb-0">
                                <v-text-field v-model="shiftyy" label="Y Value" type="number"
                                    style="text-align:center"></v-text-field>
                                </v-col>
                             <v-col cols="12" sm="12" class="pt-0 pb-0">
                                <v-switch v-model="shiftall" hide-details inset label="Shift All"></v-switch>
                            </v-col>
                            <v-col cols="6" sm="6" v-if="!shiftall" class=" pb-0">
                                <v-text-field v-model="cstart" label="From Coordinate" v-if="!shiftall" type="number"
                                    style="text-align:center"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" v-if="!shiftall" class=" pb-0">
                                <v-text-field v-model="cend" label="To Coordinate" v-if="!shiftall" type="number"
                                    style="text-align:center"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" class="pt-0">
                                <v-btn small class="submitbutton mr-2" color="#fff" @click="pathShift">
                                    Shift
                                </v-btn>
                              
                            </v-col>
                        </v-row>
                    </div>
                    <div class="sepratorebox">
                        <v-row>
                            <v-col cols="4" sm="4" class="pt-0 pb-0">
                                <v-text-field v-model="scaley" label="Scale X" type="number"
                                    style="text-align:center"></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" class="pt-0 pb-0">
                                <v-text-field v-model="scalex" label="Scale Y" type="number" style="text-align:center">
                                </v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" class="pt-0 pb-0">
                                <v-btn small class="submitbutton" color="#fff" @click="scaleCoordinates">
                                    Scale
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div> -->
                    <div class="sepratorebox">
                        <v-btn class="submitbutton" color="#fff" @click="onclicktest">
                            Submit
                        </v-btn>
                    </div>
                </div>
           
            <div class="drawingvbboz">
                <div class="wrapperdd">
            <img :src="drawing.drawingpath" alt="Workplace" id="ble2" class="map2" usemap="#workmap2">
            <map name="workmap2" id="mymap">
                <area shape="circle"
                    data-maphilight="{'strokeColor':'000000','strokeWidth':2,'fillColor': '000000','fillOpacity':0.6}"
                    key="startxx" :coords="`${walkthroughdata.startx},${walkthroughdata.starty},8`" alt="start" title="Start Point">
            
                <area shape="circle"
                    data-maphilight="{'strokeColor':'000000','strokeWidth':2,'fillColor': '000000','fillOpacity':0.6}"
                    key="startyy" :coords="`${xh},${yh},10`" alt="start">     
                <area shape="circle" v-for="(d, index) in cordinates"
                    data-maphilight="{'strokeColor':'ff0000','strokeWidth':2,'fillColor': 'ff0000','fillOpacity':0.6}"
                    @click="openTooltip(index,d)" class="pointstyle" :key="d[0]" :coords="`${d[0]},${d[2]},3`" alt="Corridor" :title="index"> 
                <area shape="circle" v-for="d in loadcoordinates"
                    data-maphilight="{'strokeColor':'ff0000','strokeWidth':2,'fillColor': 'ff0000','fillOpacity':0.6}"
                     class="pointstyle" :key="d[x]" :coords="`${d['x']},${d['y']},5`" alt="Corridor">
                </map>
                  
             </div>
            
            <div class="draggable-boximage" :style="{ top: `${top}px`, left: `${left}px` }" v-if="showimages">
                <v-icon dark color="#000" @click="showimages = false" class="exitbuttonscomapre" style="z-index:9">
                    mdi-close
                </v-icon>
                <div @mousedown="startDragging">
                    <v-icon dark color="#000"  class="exitbuttonscomapre" style="z-index:9">
                        mdi-drag-variant
                </v-icon>
                </div>
                <v-pannellum class="imagehitex" :src="framImage"></v-pannellum>
        </div>
         
            <v-snackbar v-model="alert" top right timeout="3000">
                {{ alertmessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="alert = false">
                        {{ lang.Close }}
                    </v-btn>
                </template>
            </v-snackbar>
            </div>
        </div>
</template>
<script>
//   import cameraPositions from "../../assets/cameraPositions.txt";
//   import startend from "../../assets/startend.txt";
import Preloader from '../../components/PreLoader';
import { locals } from "../../utils/locals";
import image1 from '../../assets/e.png';
import imagvre1 from '../../assets/360_vr.mp4';

import axios from "axios";
import $ from 'jquery';
import VuePannellum from 'vue-pannellum';
// import {
//     Pano
// } from 'vuejs-vr';
export default {
    components: {
        Preloader,
        'VPannellum': VuePannellum,
       // Pano,
    },
    data() {
        return {
            preloader: false,
            pathCoordinates: [],
            startEndCoordinates: [],
            Local: locals,
            drawing: {
                name: '',
                drawingpath: image1
            },
            image1: image1,
            imagvre1:imagvre1,
            framImage: '',
            cordinates: [],
            cordinatesOriginal: [],
            scale: 5,
            positionst: '391,792,136,810',
            coordination: '',
            showimages: false,
            alert: false,
            alertmessage: '',
            walkthroughdata: '',
            digree: 0,
            methodpath: 0,
            startScaleFactorx: 33,
            endScaleFactory: 55,
            shiftxx: 0,
            shiftyy: 0,
            shiftall: true,
            cstart: 0,
            cend: 0,
            scalex: 1,
            scaley: 1,
            drawingahwo : true,
            xh : 0,
            yh : 0,
            loadcoordinates : [],
            toggleload : false,
            pvideo: '',
            vidoeloded: false,
            isDragging: false,
            startX: 0,
            startY: 0,
            left: 60,
            top:60,
            incorectfound : false,
            currentitemindex : 0


        }
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        if (this.userdata.open_access == 1) {
            this.$router.push("/dashboard");
        }
        var selectleng = this.$cookies.get("currentLanguage");
        this.lang = locals[selectleng];
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

        this.projectid = this.currentProject.id;
        this.getDrawings();
        this.getcoordinatesOrg()
        //  this.convert();
        // if(this.currentProject.autodesk_urn){
        //            console.log("mounted test");
        // }
    },
    created() {
        console.log("df");
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

        this.projectid = this.currentProject.id;
        this.currentZoneName = localStorage.getItem("currentZoneName");
        this.getDrawings();
        this.getcoordinatesOrg()
    },
    watch: {

        "$store.state.currentZone": function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            this.currentZoneName = localStorage.getItem("currentZoneName");
            console.log("current zone", this.zoneid);
            this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
            this.autodesk_viewer = this.currentProject.autodesk_viewer;
            this.projectid = this.currentProject.id;
            if (this.currentProject.autodesk_urn) {
                console.log("mounted test");
            }
        }
    },
    methods: {

        getDrawings() {
            //   var id = this.$route.params.id;
            axios({
                url: '/interior/get-drawingbyid',
                method: 'get',
                params: {
                    did: this.$route.params.did
                }
            })
                .then(resp => {
                    // console.log("Drawing DTA", resp);
                    this.drawing = resp.data.success[0];
                    this.setpath();
                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.required;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        
        setpath() {

         var  positions = localStorage.getItem("positiondata");

         this.cordinates = this.readPathCoordinates(positions);

         axios({
                url: 'interior/get/walkthrough-by-id',
                method: 'get',
                params: {
                    id: this.$route.params.wid
                }
            })
                .then(resp => {

                    console.log("walkthrough", resp.data.path);
                    this.walkthroughdata = resp.data;

                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false
                })


         var _self = this;
         setTimeout(() => {
            _self.coorddinates();
            $('.map2').maphilight({ 
                strokeWidth: 2,
                stroke: true,
                fillOpacity: 0.9,
                alwaysOn: true
            });

        }, 100);

        },
        getVideoSignUrl(){

           var vp = this.walkthroughdata.video.split('.001/');
               console.log(vp);
            axios({
                url: '/get_sign_url_by_key',
                method: 'get',
                params: {
                    key: 'PRJCT372/walkthrough/video3.mp4',//vp[1],
                    exp: '60 mins',
                    project_id: this.projectid
                }
            })
                .then(resp => {
                    console.log("esp.data.success.url", resp.data.success.url);
                    // this.$refs.custome_timelapsvideo.src = resp.data.success.url;
                   // this.$refs.custome_timelapsvideopano.source = resp.data.success.url;
                   this.pvideo = resp.data.success.url; 
                                //https://d1zsmm4yi50872.cloudfront.net/PRJCT372/walkthrough/video2.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASKGTOTLMI2MZC7RW%2F20240117%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240117T064257Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=c6c00a6af6a62e40a5bd832b94f0c3bc20fb76cbba1614d6ec1874d9a79021bf 
                   this.vidoeloded = true;
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                })
          
        },
        getSignurlposition(key) {

            axios({
                url: '/get_sign_url_by_key',
                method: 'get',
                params: {
                    key: key,
                    exp: '20 mins',
                    project_id: this.projectid
                }
            })
                .then(resp => {
                    console.log("esp.data.success.url", resp.data.success.url);
                    this.ppath(resp.data.success.url);
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                })

        },
        // ppath(pathkey) {
        //     delete axios.defaults.headers.common["Authorization"]
        //     // axios({
        //     //         url: 'https://d1zsmm4yi50872.cloudfront.net/PRJCT372/walkthrough/cameraPositions.txt?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASKGTOTLMI2MZC7RW%2F20231215%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20231215T124253Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=c82afb872367cfbaf4e3ef3954cd4a6a2c855d134cccbfb9116ed112ec8774f3',
        //     //         method: 'get',
        //     //         responseType: 'text'
        //     //     })
        //     axios.get(pathkey,
        //         { responseType: 'text' })
        //         .then((res) => {
        //             // console.log("seponce",res);
        //             //  this.fileContent = response.data;
        //             //  const fileContentStartIndex =  res.data.indexOf('text/plain') + 'text/plain'.length + 4;
        //             // console.log("fileContentStartIndex",fileContentStartIndex);
        //             // var fileContent = res.data.substring(fileContentStartIndex);
        //             //console.log("fileContent",fileContent);
        //             //var coordinates = this.readPathCoordinates(res.data);


        //             //var startEnd = this.readStartEnd();

        //             // var xzCoordinates;
        //             // if (this.methodpath == 1) {

        //             //     xzCoordinates = this.fitCoordinates(startEnd, coordinates);
        //             // }
        //             // if (this.methodpath == 2) {

        //             //     xzCoordinates = this.scaleAndReflectPositions(startEnd, coordinates);
        //             // }
        //             // if (this.methodpath == 3) {
        //             //     xzCoordinates = this.nonUniformScaleAndRotatePositions(coordinates);
        //             // }

        //             this.cordinates = xzCoordinates;
        //             console.log("this.cordinates", xzCoordinates);
        //             // var fittedCoordinates = this.updateZCoordinates(xzCoordinates);

        //             // this.cordinates = fittedCoordinates;

        //             // console.log("xzCoordinates",fittedCoordinates);
        //             //  var yCoordinates = coordinates.map(coord => coord[1]);
        //             // this.writeFittedCoordinates(fittedCoordinates) 
        //             const token = localStorage.getItem("token")

        //             axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

        //             var _self = this;
        //             this.drawingahwo = false;
        //             setTimeout(() => {

        //                 _self.coorddinates();
        //                 _self.drawingahwo = true;
        //                 $('.map2').maphilight({

        //                     strokeWidth: 2,
        //                     stroke: true,
        //                     fillOpacity: 0.9,
        //                     alwaysOn: true
        //                 });
                        
        //             }, 100);
        //         })
        //         .catch(err => {
        //             const token = localStorage.getItem("token")
        //             axios.defaults.headers.common["Authorization"] = 'Bearer ' + token

        //             console.log(err);
        //             this.alert = true;
        //             this.alertmessage = this.lang.SomethingWentWrong;
        //             this.alertType = "error"
        //             this.preloader = false


        //         })
        // },
        readPathCoordinates(fileContent) {
            //    console.log("fileContent",fileContentdx);
            // const fileContent = fs.readFileSync(filepath, 'utf-8');

            // var cc = fileContentdx;
            // var fileContent = cc[1];

            //console.log("lines",fileContent);

            const linesall = fileContent.trim().split('\n\r\n'); // Skip header line


            const lines = linesall[0].trim().split('\n\n'); // Skip header line
            // var lines = linesall.splice(-1);
            // console.log("lines",lines);
            const coordinates = lines.map(line => line.trim().split(',').map(Number));

            console.log("coordinates==>",coordinates);

            return coordinates;
        },

        readStartEnd() {
            // const fileContent = ["391","792","136","810"];
            // ["391","792","136","810"]
            //     [y1, x1, y2, x2]
            console.log(this.positionst);

            // this.positionst = this.walkthroughdata.starty,   
            //[this.walkthroughdata.starty,this.walkthroughdata.startx,this.walkthroughdata.endy,this.walkthroughdata.endx];

            const startEnd = this.positionst.split(',');//["391","792","136","810"];[y1,x1,y2,x2]
            //[391,792],[136,810] [391,792],[136,810]
            const pairs = [];
            for (let i = 0; i < startEnd.length; i += 2) {
                pairs.push([parseInt(startEnd[i]), parseInt(startEnd[i + 1])]);
            }
            //console.log("pairs",pairs);
            return pairs;
        },
        fitCoordinates(startEnd, xzCoordinatesin) {


            console.log(startEnd, xzCoordinatesin);

            var cameraPositions = xzCoordinatesin;

            //120,166,85,817

            //  var startX =  102;//parseInt(this.walkthroughdata.startx); 
            //  var startZ = 813;//parseInt(this.walkthroughdata.starty);
            //  var endX =  299;//parseInt(this.walkthroughdata.endx);
            //  var endZ =  116;//parseInt(this.walkthroughdata.endy);
            // // Extract the first and last positions from cameraPositions

            var startX = parseInt(this.walkthroughdata.starty);
            var startZ = parseInt(this.walkthroughdata.startx);
            var endX = parseInt(this.walkthroughdata.endy);
            var endZ = parseInt(this.walkthroughdata.endx);

            const firstX = cameraPositions[0][0];
            const firstZ = cameraPositions[0][2];
            const lastX = cameraPositions[cameraPositions.length - 1][0];
            const lastZ = cameraPositions[cameraPositions.length - 1][2];

            // Calculate scale factors
            const scaleX = (endX - startX) / (lastX - firstX);
            const scaleZ = (endZ - startZ) / (lastZ - firstZ);

            // Scale and translate positions, keeping y as 1
            const scaledPositions = [];
            for (let i = 0; i < cameraPositions.length; i++) {
                const [x, , z] = cameraPositions[i];
                const newX = (x - firstX) * scaleX + startX;
                const newZ = (z - firstZ) * scaleZ + startZ;

                console.log("x = ", newX, " y = ", newZ);
                scaledPositions.push([newZ, 1, newX]);
            }

            return scaledPositions;
        },

        rotateCoordinates() {

            var angleDegrees = this.digree;
            var coordinates = this.cordinates;
            const angleRadians = (angleDegrees * Math.PI) / 180;
            const rotatedCoordinates = [];

            for (let i = 0; i < coordinates.length; i++) {
                const [x, y, z] = coordinates[i];
                // Rotation matrix for y-axis
                const newX = x * Math.cos(angleRadians) + z * Math.sin(angleRadians);
                const newZ = -x * Math.sin(angleRadians) + z * Math.cos(angleRadians);
                rotatedCoordinates.push([newX, y, newZ]);
            }
            this.cordinates = rotatedCoordinates;

            console.log(rotatedCoordinates);

            var _self = this;
            this.drawingahwo = false;
            setTimeout(() => {

                // _self.cordinates = [[91,792],[136,810]];

                _self.coorddinates();
                _self.drawingahwo = false;
                $('.map2').maphilight({

                    strokeWidth: 2,
                    stroke: true,
                    fillOpacity: 0.9,
                    alwaysOn: true
                });
               
            }, 100);

            console.log("rotatedCoordinates", rotatedCoordinates)
        },

        rotateCoordinatesrefresh(){
            var _self = this;
            this.drawingahwo = false;
            setTimeout(() => {

                // _self.cordinates = [[91,792],[136,810]];

                _self.coorddinates();
                _self.drawingahwo = false;
                $('.map2').maphilight({

                    strokeWidth: 2,
                    stroke: true,
                    fillOpacity: 0.9,
                    alwaysOn: true
                });
               
            }, 100);
        },

        reflectPoint(x, z, x1, z1, x2, z2) {
            // Reflection of a point (x, z) about the line passing through (x1, z1) and (x2, z2)
            let m, c, d, xReflected, zReflected;

            if (x2 - x1 !== 0) {
                m = (z2 - z1) / (x2 - x1);
                c = z1 - m * x1;
                d = (x + (z - c) * m) / (1 + m ** 2);
                xReflected = 2 * d - x;
                zReflected = 2 * d * m - z + 2 * c;
            } else {
                // Vertical line case
                xReflected = 2 * x1 - x;
                zReflected = z;
            }

            return [xReflected, zReflected];
        },
        scaleAndReflectPositions(startEnd, cameraPositions) {
            // Extract the first and last positions from cameraPositions
            var startX = parseInt(this.walkthroughdata.starty);
            var startZ = parseInt(this.walkthroughdata.startx);
            var endX = parseInt(this.walkthroughdata.endy);
            var endZ = parseInt(this.walkthroughdata.endx);

            const firstX = cameraPositions[0][0];
            const firstZ = cameraPositions[0][2];
            const lastX = cameraPositions[cameraPositions.length - 1][0];
            const lastZ = cameraPositions[cameraPositions.length - 1][2];

            // Calculate scale factors
            const scaleX = (endX - startX) / (lastX - firstX);
            const scaleZ = (endZ - startZ) / (lastZ - firstZ);

            // Scale, translate, and reflect positions, keeping y as 1
            const scaledReflectedPositions = [];
            for (let i = 0; i < cameraPositions.length; i++) {
                const [x, , z] = cameraPositions[i];

                // Scale and translate
                const newX = (x - firstX) * scaleX + startX;
                const newZ = (z - firstZ) * scaleZ + startZ;

                // Reflect
                const [reflectedX, reflectedZ] = this.reflectPoint(newX, newZ, startX, startZ, endX, endZ);

                scaledReflectedPositions.push([reflectedZ, 1, reflectedX]);
            }

            return scaledReflectedPositions;
        },

        updateZCoordinates(fittedCoordinates) {
            const z1 = fittedCoordinates[0][2];

            for (let i = 0; i < fittedCoordinates.length; i++) {
                fittedCoordinates[i][2] = 2 * z1 - fittedCoordinates[i][2];
            }

            return fittedCoordinates;
        },
        // writeFittedCoordinates(fittedCoordinates) {
        //     this.coordination = fittedCoordinates.map((coord, index) => `${coord[0]},${coord[1]},${coord[2]}`);

        //     //console.log("content",content);

        //     // fs.writeFileSync(filepath, content);
        // },

        coorddinates() {
            var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
                canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;

            has_canvas = !!document.createElement('canvas').getContext;

            // VML: more complex
            has_VML = (function () {
                var a = document.createElement('div');
                a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
                var b = a.firstChild;
                b.style.behavior = "url(#default#VML)";
                return b ? typeof b.adj == "object" : true;
            })();

            if (!(has_canvas || has_VML)) {
                $.fn.maphilight = function () {
                    return this;
                };
                return;
            }

            if (has_canvas) {
                hex_to_decimal = function (hex) {
                    return Math.max(0, Math.min(parseInt(hex, 16), 255));
                };
                css3color = function (color, opacity) {
                    return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
                };
                create_canvas_for = function (img) {
                    var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
                    c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
                    return c;
                };
                var draw_shape = function (context, shape, coords, x_shift, y_shift) {
                    x_shift = x_shift || 0;
                    y_shift = y_shift || 0;

                    context.beginPath();
                    if (shape == 'rect') {
                        // x, y, width, height
                        context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
                    } else if (shape == 'poly') {
                        context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
                        for (var i = 2; i < coords.length; i += 2) {
                            context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
                        }
                    } else if (shape == 'circ') {
                        // x, y, radius, startAngle, endAngle, anticlockwise
                        context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
                    }
                    context.closePath();
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var i, context = canvas.getContext('2d');

                    console.log(name, i);

                    // Because I don't want to worry about setting things back to a base state

                    // Shadow has to happen first, since it's on the bottom, and it does some clip /
                    // fill operations which would interfere with what comes next.
                    if (options.shadow) {
                        context.save();
                        if (options.shadowPosition == "inside") {
                            // Cause the following stroke to only apply to the inside of the path
                            draw_shape(context, shape, coords);
                            context.clip();
                        }

                        // Redraw the shape shifted off the canvas massively so we can cast a shadow
                        // onto the canvas without having to worry about the stroke or fill (which
                        // cannot have 0 opacity or width, since they're what cast the shadow).
                        var x_shift = canvas.width * 100;
                        var y_shift = canvas.height * 100;
                        draw_shape(context, shape, coords, x_shift, y_shift);

                        context.shadowOffsetX = options.shadowX - x_shift;
                        context.shadowOffsetY = options.shadowY - y_shift;
                        context.shadowBlur = options.shadowRadius;
                        context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);

                        // Now, work out where to cast the shadow from! It looks better if it's cast
                        // from a fill when it's an outside shadow or a stroke when it's an interior
                        // shadow. Allow the user to override this if they need to.
                        var shadowFrom = options.shadowFrom;
                        if (!shadowFrom) {
                            if (options.shadowPosition == 'outside') {
                                shadowFrom = 'fill';
                            } else {
                                shadowFrom = 'stroke';
                            }
                        }
                        if (shadowFrom == 'stroke') {
                            context.strokeStyle = "rgba(0,0,0,1)";
                            context.stroke();
                        } else if (shadowFrom == 'fill') {
                            context.fillStyle = "rgba(0,0,0,1)";
                            context.fill();
                        }
                        context.restore();

                        // and now we clean up
                        if (options.shadowPosition == "outside") {
                            context.save();
                            // Clear out the center
                            draw_shape(context, shape, coords);
                            context.globalCompositeOperation = "destination-out";
                            context.fillStyle = "rgba(0,0,0,1);";
                            context.fill();
                            context.restore();
                        }
                    }

                    context.save();

                    draw_shape(context, shape, coords);

                    // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
                    // which mostly looks weird when the shadow has a high opacity
                    if (options.fill) {
                        context.fillStyle = css3color(options.fillColor, options.fillOpacity);
                        context.fill();
                    }
                    // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
                    // shadow or the shadow-background if it's present.
                    if (options.stroke) {
                        context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
                        context.lineWidth = options.strokeWidth;
                        context.stroke();
                    }

                    context.restore();

                    if (options.fade) {
                        $(canvas).css('opacity', 0).animate({
                            opacity: 1
                        }, 100);
                    }
                };
                clear_canvas = function (canvas) {
                    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                };
            } else { // ie executes this code
                create_canvas_for = function (img) {
                    return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var fill, stroke, opacity, e;
                    for (var i in coords) {
                        coords[i] = parseInt(coords[i], 10);
                    }
                    fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
                    stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
                    opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
                    if (shape == 'rect') {
                        e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
                    } else if (shape == 'poly') {
                        e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
                    } else if (shape == 'circ') {
                        e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
                    }
                    e.get(0).innerHTML = fill + opacity;
                    $(canvas).append(e);
                };
                clear_canvas = function (canvas) {
                    // jquery1.8 + ie7
                    var $html = $("<div>" + canvas.innerHTML + "</div>");
                    $html.children('[name=highlighted]').remove();
                    $(canvas).html($html.html());
                };
            }

            shape_from_area = function (area) {
                var i, coords,
                    shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
                if (shape === 'defa') {
                    // 'default' doesn't really apply to what we're doing; it's the background state
                    return;
                }
                coords = (area.getAttribute('coords') || '').split(',');
                for (i = 0; i < coords.length; i++) {
                    coords[i] = parseFloat(coords[i]);
                }
                return [shape, coords];
            };

            options_from_area = function (area, options) {
                var $area = $(area);
                return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
            };

            is_image_loaded = function (img) {
                if (!img.complete) {
                    return false;
                } // IE
                if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
                    return false;
                } // Others
                return true;
            };

            canvas_style = {
                position: 'absolute',
                left: 0,
                top: 0,
                padding: 0,
                border: 0
            };

            var ie_hax_done = false;
            $.fn.maphilight = function (opts) {
                opts = $.extend({}, $.fn.maphilight.defaults, opts);

                if (!has_canvas && !ie_hax_done) {
                    $(window).ready(function () {
                        document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
                        var style = document.createStyleSheet();
                        var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
                        $.each(shapes,
                            function () {
                                style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
                            }
                        );
                    });
                    ie_hax_done = true;
                }

                return this.each(function () {
                    var img, wrap, options, map, canvas, canvas_always, usemap;
                    img = $(this);

                    //  console.log(highlighted_shape,imgSrc);

                    if (!is_image_loaded(this)) {
                        // If the image isn't fully loaded, this won't work right.  Try again later.
                        return window.setTimeout(function () {
                            img.maphilight(opts);
                        }, 200);
                    }

                    options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));

                    // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
                    // So use raw getAttribute instead.
                    usemap = img.get(0).getAttribute('usemap');

                    if (!usemap) {
                        return;
                    }

                    map = $('map[name="' + usemap.substr(1) + '"]');

                    if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
                        return;
                    }

                    if (img.hasClass('maphilighted')) {
                        // We're redrawing an old map, probably to pick up changes to the options.
                        // Just clear out all the old stuff.
                        var wrapper = img.parent();
                        img.insertBefore(wrapper);
                        wrapper.remove();
                        $(map).unbind('.maphilight');
                    }

                    //Formating the image source. IE > 9 has issue with new line characters
                    var imgSrc = this.src.replace(/[\n\r]/g, '');
                    console.log(imgSrc);
                    wrap = $('<div></div>').css({
                        display: 'block',
                        background: 'url("' + this.src + '")',
                        "background-size": 'contain',
                        "background-repeat": 'no-repeat',
                        position: 'relative',
                        padding: 0,
                        width: this.width,
                        height: this.height
                    });
                    if (options.wrapClass) {
                        if (options.wrapClass === true) {
                            wrap.addClass($(this).attr('class'));
                        } else {
                            wrap.addClass(options.wrapClass);
                        }
                    }
                    // Firefox has a bug that prevents tabbing into the image map if
                    // we set opacity of the image to 0, but very nearly 0 works!
                    img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
                    if (has_VML) {
                        img.css('filter', 'Alpha(opacity=0)');
                    }
                    wrap.append(img);

                    canvas = create_canvas_for(this);
                    $(canvas).css(canvas_style);
                    canvas.height = this.height;
                    canvas.width = this.width;

                    $(map).bind('alwaysOn.maphilight', function () {
                        // Check for areas with alwaysOn set. These are added to a *second* canvas,
                        // which will get around flickering during fading.
                        if (canvas_always) {
                            clear_canvas(canvas_always);
                        }
                        if (!has_canvas) {
                            $(canvas).empty();
                        }
                        $(map).find('area[coords]').each(function () {
                            var shape, area_options;
                            area_options = options_from_area(this, options);
                            if (area_options.alwaysOn) {
                                if (!canvas_always && has_canvas) {
                                    canvas_always = create_canvas_for(img[0]);
                                    $(canvas_always).css(canvas_style);
                                    canvas_always.width = img[0].width;
                                    canvas_always.height = img[0].height;
                                    img.before(canvas_always);
                                }
                                area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                                shape = shape_from_area(this);
                                if (!shape) {
                                    return;
                                }
                                if (has_canvas) {
                                    add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                                } else {
                                    add_shape_to(canvas, shape[0], shape[1], area_options, "");
                                }
                            }
                        });
                    }).trigger('alwaysOn.maphilight')
                        .bind('mouseover.maphilight focusin.maphilight', function (e) {
                            console.log(e);
                            var shape, area_options, area = e.target;
                            area_options = options_from_area(area, options);
                            if (!area_options.neverOn && !area_options.alwaysOn) {
                                shape = shape_from_area(area);
                                if (!shape) {
                                    return;
                                }
                                add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                                if (area_options.groupBy) {
                                    if (typeof area_options.groupBy == 'string') {
                                        area_options.groupBy = [area_options.groupBy];
                                    }
                                    var el = $(this);
                                    $.each(area_options.groupBy, function (index, groupitem) {
                                        var areas;
                                        // two ways groupBy might work; attribute and selector
                                        if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                                            areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                                        } else {
                                            areas = map.find(groupitem);
                                        }
                                        var first = this;
                                        areas.each(function () {
                                            if (this != first) {
                                                var subarea_options = options_from_area(this, options);
                                                if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                                                    var shape = shape_from_area(this);
                                                    add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                                                }
                                            }
                                        });
                                    });
                                }
                                // workaround for IE7, IE8 not rendering the final rectangle in a group
                                if (!has_canvas) {
                                    $(canvas).append('<v:rect></v:rect>');
                                }
                            }
                        }).bind('mouseout.maphilight focusout.maphilight', function (e) {
                            console.log(e);
                            clear_canvas(canvas);
                        });

                    img.before(canvas); // if we put this after, the mouseover events wouldn't fire.

                    img.addClass('maphilighted');
                });
            };
            $.fn.maphilight.defaults = {
                fill: true,
                fillColor: 'fdc004',
                fillOpacity: 0.2,
                stroke: true,
                strokeColor: '000000',
                strokeOpacity: 1,
                strokeWidth: 1,
                fade: true,
                alwaysOn: true,
                neverOn: false,
                groupBy: false,
                wrapClass: true,
                // plenty of shadow:
                shadow: true,
                shadowX: 0,
                shadowY: 0,
                shadowRadius: 6,
                shadowColor: '000000',
                shadowOpacity: 0.8,
                shadowPosition: 'outside',
                shadowFrom: false
            }
        },

        euclideanDistance(x1, y1, x2, y2) {

            const deltaX = Math.pow((parseFloat(x2) - parseFloat(x1)), 2);
            const deltaY = Math.pow((parseFloat(y2) - parseFloat(y1)), 2);

            const distanceSquared = deltaX + deltaY;
            const distance = Math.sqrt(distanceSquared);

            // console.log(distance);
            return distance;
        },

        onclicktest(){

            var aa = confirm("Make sure you've correctly fitted the curve. Do you want to proceed?");
            if(aa){

            var finalarray = [];

            var lowwesitems = [];

            var acceptevalues = [];

            this.cordinatesOriginal.forEach((a,indexa) => {

                  //  console.log("a.coordinateid ",a.coordinateid);
                  //  console.log("a.x, a.y", b[0], b[2]);

                   
                   finalarray.push({
                      indexb: []
                   });

                    this.cordinates.forEach((b) => {
                       // console.log(a.coordinateid,'--->',a.x, a.y, b[0], b[2]);

                          var dis = this.euclideanDistance(a.x, a.y, b[0], b[2]);

                          finalarray[indexa].indexb.push({
                            cid: a.coordinateid,
                            frame: b[3],
                            distance: dis
                        })    

                       // console.log("dis --> ",a.coordinateid,a.x, a.y, b[0], b[2], dis,b[3]);

                        
                    })

                        var lowestd  = finalarray[indexa].indexb.reduce((min, current) =>
                           current.distance < min.distance ? current : min
                        );

                       // console.log("lowestd",lowestd);


                        lowwesitems.push(lowestd);

                   // console.log("-------",(this.cordinatesOriginal.length - 1) == indexa);
                    
                   if((this.cordinatesOriginal.length - 1) == indexa){
                       
                    let myImg = document.querySelector("#ble2");
                let w = myImg.naturalWidth;
                let h = myImg.naturalHeight;

                 //console.log(w, h);

                 const squaredSum = Math.pow(h, 2) + Math.pow(w, 2); // Calculate h^2 + w^2
                 const dsize = Math.sqrt(squaredSum); // Calculate the square root of the sum
              // console.log(dsize);
                 var fivep =  (dsize * 5)/100;
                console.log(fivep);

                lowwesitems.forEach(lowestd => {

                    if(fivep >= lowestd.distance){
                     acceptevalues.push({
                         frame : lowestd.frame,
                         cid : lowestd.cid,
                         distance : lowestd.distance
                     })
                    }
                   })
                   console.log("acceptevalues",acceptevalues);
                    
                   var final_data = JSON.stringify(acceptevalues);

                    this.sendFinalProcessdata(final_data);
                   }
                 })

                
                }


        },

        // Example usage:
         onclicktest23(){

            
            //var acceptevalues = [];
            var finalarray = [];
           // console.log(this.cordinates);

           // this.cordinatesOriginal
            this.cordinatesOriginal.forEach( (a,indexa) => {

                         
                 

                 finalarray.push({
                      indexb: []
                   });

                 this.cordinates.forEach(b => {

                    console.log("a.coordinateid ",a.coordinateid);
                    console.log("a.x, a.y", a.x, a.y, b[2], b[0]);


                 var dis = this.euclideanDistance(a.x, a.y, b[2], b[0]);

               // console.log("dis --> ",a.x, a.y, b[2], b[0], dis,b[3]);

                    finalarray[indexa].indexb.push({
                            cid: a.coordinateid,
                            frame: b[3],
                            distance: dis
                        })        
                 });

                   //  var s = finalarray[indexa].indexb.reduce((minIndex, current, currentIndex, array) =>
                    //  current.distance < array[minIndex].distance ? currentIndex : minIndex, 0);


                //  var lowestd  = finalarray[indexa].indexb.reduce((min, current) =>
                //    current.distance < min.distance ? current : min
                // );



            //     console.log(lowestd);

            //     let myImg = document.querySelector("#ble2");
            //     let w = myImg.naturalWidth;
            //     let h = myImg.naturalHeight;

            //      //console.log(w, h);

            //      const squaredSum = Math.pow(h, 2) + Math.pow(w, 2); // Calculate h^2 + w^2
            //  const dsize = Math.sqrt(squaredSum); // Calculate the square root of the sum
            //   // console.log(dsize);
            //      var fivep =  (dsize * 5)/100;
            //    //console.log(fivep);


            //    if(fivep >= lowestd.distance){
            //     acceptevalues.push({
            //         frame : lowestd.frame,
            //         cid : lowestd.cid,
            //         distance : lowestd.distance
            //     })
            //    }


            });


            

            //var final_data = JSON.stringify(acceptevalues);
            console.log("finalarray",finalarray);

            finalarray.forEach(c => {

              var lllw = this.findLowestDistancePair(c['indexb']);

              console.log(lllw);
                 
            })

          // console.log("final_data",final_data);
            //this.sendFinalProcessdata(final_data);

           // this.euclideanDistance(1597, 682,791.32,369); // Replace these values with your coordinates
           },

           findLowestDistancePair(frames) {
  if (frames.length < 2) {
    return null; // Not enough frames to compare
  }

  frames.sort((a, b) => a.distance - b.distance); // Sort frames based on distance

  let minDistance = Infinity;
  let closestPair = [];

  for (let i = 1; i < frames.length; i++) {
    const currentDistance = frames[i].distance - frames[i - 1].distance;

    if (currentDistance < minDistance) {
      minDistance = currentDistance;
      closestPair = [frames[i - 1]];
    }
  }

  return closestPair;
},

        sendFinalProcessdata(final_data) {

            console.log("final_data",final_data);

            const content = this.cordinates.map(coord => `${coord[0]},${coord[1]},${coord[2]},${coord[3]}`).join('\n\n');
            console.log(content);
            //console.log();

            axios({
                url: 'interior/walkthrough-image-processsing',
                method: 'post',
                data: {
                    wid: this.$route.params.wid,
                    final_data: final_data,
                    fitted_data: 'Position x, Position y, Position z, frame  \n' + content,
                }
            })
                .then(resp => {
                    console.log("headings", resp);

                    //this.cordinatesOriginal = resp.data;

                    this.alert = true;
                    this.alertmessage = "walkthrough process completed successfully."



                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })


        },
        getcoordinatesOrg() {
            axios({
                url: '/interior/getcoordinates',
                method: 'get',
                params: {
                    did: this.$route.params.did
                }
            })
                .then(resp => {
                    console.log("headings---", resp);

                    this.cordinatesOriginal = resp.data;

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        openTooltip(n,cord) {

         //console.log("clickitem", n);

           this.xh = cord[0];
           this.yh = cord[2];
           

            //    alert("clicked Frame number = " + cord[3]);

               this.currentitemindex = cord[3];
          
                this.preloader = true;
                axios({
                    url: 'interior/get/walkthrough-by-id',
                    method: 'get',
                    params: {
                        id: this.$route.params.wid
                    }
                })
                    .then(resp => {

                        console.log("walkthrough", resp.data.path);
                        var key = resp.data.path + '/' + cord[3] + '.jpg';
                        this.getSignurl(key);
                        var _self = this;
                        // this.drawingahwo = false;
                            setTimeout(() => {
                                _self.coorddinates;
                            //  _self.drawingahwo = true;
                                $('.map2').maphilight({
                                    strokeWidth: 2,
                                    stroke: true,
                                    fillOpacity: 0.9,
                                    alwaysOn: true
                                });
                
                
            }, 100);

                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })
            
        },
        getSignurl(key) {

            axios({
                url: '/get_sign_url_by_key',
                method: 'get',
                params: {
                    key: key,
                    exp: '20 mins',
                    project_id: this.projectid
                }
            })
                .then(resp => {

                    console.log("walkthrough", resp);
                    this.framImage = resp.data.success.url;
                    this.showimages = true;
                    this.preloader = false;

                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                })

        },
        calculateAngle(startX, startZ, endX, endZ) {
            // Calculate the angle of the line with respect to the z-axis
            const dx = endX - startX;
            const dz = endZ - startZ;
            const angle = Math.atan2(dz, dx);
            return angle;
        },

        rotatePoint(x, z, angle) {
            // Rotate the point (x, z) by the given angle
            const newX = x * Math.cos(angle) - z * Math.sin(angle);
            const newZ = x * Math.sin(angle) + z * Math.cos(angle);
            return { newX, newZ };
        },

        linearInterpolation(start, end, t) {
            // Linearly interpolate between start and end values based on t (0 <= t <= 1)
            return start + t * (end - start);
        },

        nonUniformScaleAndRotatePositions(cameraPositions) {

            var startX = parseInt(this.walkthroughdata.starty);
            var startZ = parseInt(this.walkthroughdata.startx);
            var endX = parseInt(this.walkthroughdata.endy);
            var endZ = parseInt(this.walkthroughdata.endx);

            var rotationAngle = this.calculateAngle(startX, startZ, endX, endZ)

            const firstX = cameraPositions[0][0];
            const firstZ = cameraPositions[0][2];
            const lastX = cameraPositions[cameraPositions.length - 1][0];
            const lastZ = cameraPositions[cameraPositions.length - 1][2];

            // Scale factors at the start and end
            const startScaleFactor = parseInt(this.startScaleFactorx);
            const endScaleFactor = parseInt(this.endScaleFactory); // Example value

            console.log("startScaleFactor", startScaleFactor, "endScaleFactor", endScaleFactor);


            // Original direction angle
            const originalAngle = this.calculateAngle(firstX, firstZ, lastX, lastZ);

            // Apply non-uniform scaling, rotation, and translation
            const scaledRotatedPositions = [];
            for (let i = 0; i < cameraPositions.length; i++) {
                const [x, , z] = cameraPositions[i];
                const t = i / (cameraPositions.length - 1);
                const scaleFactor = this.linearInterpolation(startScaleFactor, endScaleFactor, t);

                const newX = (x - firstX) * scaleFactor + startX;
                const newZ = (z - firstZ) * scaleFactor + startZ;

                const { newX: rotatedX, newZ: rotatedZ } = this.rotatePoint(newX - startX, newZ - startZ, rotationAngle - originalAngle);
                scaledRotatedPositions.push([rotatedZ + startZ, 1, rotatedX + startX]);
            }

            return scaledRotatedPositions;
        },
        pathShift() {

            // Manually set the shift values and selection option
            var shiftX = parseInt(this.shiftxx);  // Shift in x direction - vertical direction - minus up, plus down.
            var shiftZ = parseInt(this.shiftyy);  // Shift in z direction - horizontal direction - minus left, plus right.
             // Set to true to select all points, false to use a range
             var startIndex = parseInt(this.cstart);  // Start index for shifting (used if selectAll is false)
             var endIndex = parseInt(this.cend);   // End index for shifting (used if selectAll is false)
           // var maintainAspectRatio = false;  // Set to true to maintain aspect ratio

            // Coordinates will be assumed to be available in the current JavaScript environment
            // (e.g., loaded from a file or obtained through another method)
            var coordinates = this.cordinates;
            // Calculate the original aspect ratio

             console.log("old coordinates",coordinates);
            
                for (let i = 0; i < coordinates.length; i++) {
                 if (this.shiftall || (i >= startIndex - 1 && i < endIndex)) {
                        coordinates[i][0] += shiftX;
                 }
                }

                // Shift in the Z direction
                for (let i = 0; i < coordinates.length; i++) {
                 if (this.shiftall || (i >= startIndex - 1 && i < endIndex)) {
                     coordinates[i][2] += shiftZ;
                 }
                }

                //this.cordinates = coordinates;
                const rotatedCoordinates = [];
                for (let i = 0; i < coordinates.length; i++) {
                const [x, y, z] = coordinates[i];
                rotatedCoordinates.push([x, y, z]);
            }
            console.log("shifted coordinates",rotatedCoordinates);
            this.cordinates = rotatedCoordinates;

           var _self = this;

            setTimeout(() => {
                _self.coorddinates;
              //  _self.drawingahwo = true;
                $('.map2').maphilight({
                    strokeWidth: 2,
                    stroke: true,
                    fillOpacity: 0.9,
                    alwaysOn: true
                });
                
            }, 500); 

        },

        shiftCoordinatesall(coordinates, shiftX, shiftZ) {
            // Perform shifting
            //console.log(selectAll);
           

                for (let i = 0; i < coordinates.length; i++) {

                    // console.log(coordinates[i][0]);
                    coordinates[i][0] += shiftX;
                    coordinates[i][2] += shiftZ;
                    //  console.log("after shift", coordinates[i][0]);
                }
            
            return coordinates;
        },
        shiftCoordinates(coordinates, shiftX, shiftZ, startIndex, endIndex) {
            // Perform shifting
            //console.log(selectAll);
               for (let i = startIndex - 1; i < endIndex; i++) {
                    coordinates[i][0] += shiftX;
                    coordinates[i][2] += shiftZ;
                }
            return coordinates;
        },
        scaleToMaintainAspectRatio(coordinates, originalAspectRatio) {
            // Calculate the new bounding box
            const min_X = Math.min(...coordinates.map(coord => coord[0]));
            const max_X = Math.max(...coordinates.map(coord => coord[0]));
            const min_Z = Math.min(...coordinates.map(coord => coord[2]));
            const max_Z = Math.max(...coordinates.map(coord => coord[2]));

            const newAspectRatio = (max_X - min_X) / (max_Z - min_Z);

            // Scale to maintain the original aspect ratio
            const scalingFactor = originalAspectRatio / newAspectRatio;
            for (let i = 0; i < coordinates.length; i++) {
                coordinates[i][0] *= scalingFactor;
            }

            return coordinates;
        },
        scaleCoordinates() {

            const scaleX = parseFloat(this.scalex);      // Scale factor for x direction - vertical direction
            const scaleZ = parseFloat(this.scaley);  // Scale factor for z direction - horizontal direction

            const scaledCoordinates = [];
            for (const [x, y, z] of this.cordinates) {
                const newX = x * scaleZ;
                const newZ = z * scaleX;
                scaledCoordinates.push([newX, y, newZ]);
            }
            this.cordinates = scaledCoordinates;
            console.log(scaledCoordinates);

            var _self = this;

            setTimeout(() => {
                _self.coorddinates();
                $('.map2').maphilight({
                    strokeWidth: 2,
                    stroke: true,
                    fillOpacity: 0.9,
                    alwaysOn: true
                });
            }, 100);

        },
        LoadPoints(){

            this.toggleload = !this.toggleload;
            if(this.toggleload){
                this.loadcoordinates = this.cordinatesOriginal;
            }else{

                this.loadcoordinates = [];
            }
          var _self = this;

            setTimeout(() => {
                _self.coorddinates();
                $('.map2').maphilight({
                    strokeWidth: 2,
                    stroke: true,
                    fillOpacity: 0.9,
                    alwaysOn: true
                });
            }, 100);
        },
        startDragging(event) {
      // Prevent default behavior to avoid unwanted text selection
      event.preventDefault();

      this.isDragging = true;
      this.startX = event.clientX - this.left;
      this.startY = event.clientY - this.top;

      // Add event listeners for mousemove and mouseup
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    drag(event) {
      if (this.isDragging) {
        this.left = event.clientX - this.startX;
        this.top = event.clientY - this.startY;
      }
    },
    stopDragging() {
      this.isDragging = false;

      // Remove event listeners when dragging stops
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDragging);
    },
     incurrectImageFound(){
       this.incorectfound = true;
       let citem = this.currentitemindex;
       this.inccuretindex = citem;


     },
     ImageFound(){
       this.incorectfound = false;
       console.log("currc", this.currentitemindex,this.inccuretindex );

        var diff =  this.currentitemindex - this.inccuretindex;
      
       var currectPosition = [];
       var si;
       this.cordinates.forEach((s) => {

           // console.log(s);
               if(s[3] >= this.inccuretindex ){

                 si = [s[0],s[1],s[2],s[3]+diff];
                 currectPosition.push(si);
               }else{
                si = [ s[0],s[1],s[2],s[3]];
                currectPosition.push(si);
               }
          
          });
          this.cordinates = currectPosition;
          console.log(this.cordinates);
     }
    }
};
</script>
<style>
.imagehitex {
    width: 400px;
}
.draggable-boximage {
  position: absolute;
  width: 400px;
  height: 300px;
  color: #ffffff;
  cursor: grab;
  user-select: none; /* Disable text selection while dragging */
}

.draggable-boximage:active {
  cursor: grabbing;
}

</style>