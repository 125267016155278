<template>
  <div>
    <Preloader v-if="preloader" />
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="0" @click="setrouter('/dashboard/reports/instant-report')">
          {{lang.InstantReport}}
        </v-tab>
        <v-tab key="1" @click="setrouter('/dashboard/reports/schedule-report')">
          {{lang.ScheduleReport}}
        </v-tab>
        <v-tab key="2" @click="setrouter('/dashboard/reports/report-demo')">
          {{lang.ReportDemo}}
        </v-tab>
      </v-tabs>
      <v-tabs-items>
        <v-tab-item value="tab-1"> </v-tab-item>
        <div
          v-ripple="{ center: true }"
          class="text-center elevation-1 pa-0 headline"
        >
          <v-dialog v-model="dialog" scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                outlined
                color="amber accent-3"
                v-bind="attrs"
                v-on="on"
              >
                {{ d1 }}
                <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-date-picker
                :color="activecolor"
                @change="getSelectedDate($event)"
                v-model="picker"
                :max="new Date().toISOString().substr(0, 10)"
                :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
              ></v-date-picker>

              <v-divider></v-divider>
              <div class="timeList">
                <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
                <v-list mandatory rounded max-height="341px">
                  <v-list-item-group>
                    <v-dialog v-model="timeslotloader" persistent width="300">
                      <v-card :color="activecolor" dark>
                        <v-card-text>
                          {{ lang.Pleasestandby }}
                          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-list-item v-for="(item, i) in timeslots" :key="i" @change="selectTimeSlot(item.image_id)" class="itemBottomMargin">
                      <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                        <v-chip>{{ item.updated_time }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <!-- <v-chip-group active-class="amber" multiple :color="activecolor">
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{lang.Pleasestandby }}
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-chip
                  v-for="(item, i) in timeslots"
                  :key="i"
                  @change="selectTimeSlot(item.image_id, item.updated_time)"
                  >{{ item.updated_time }}</v-chip
                >
              </v-chip-group> -->

              <v-card-actions>
                <v-btn color="green darken-1" text @click="dialog = false">
                  {{lang.Close}}
                </v-btn>
                <v-btn color="green darken-1" text @click="dialog = false">
                  {{ lang.Done }}
                </v-btn>
                <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
                  {{ archiveCalenderyearMonth }}
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                      {{lang.Close}}
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                      <v-icon dark>
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Capturing Insights</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- <v-btn color="success" dark @click="swithmode"> Switch Mode </v-btn> -->

          <v-dialog v-model="dialogtwo" scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                outlined
                color="amber accent-3"
                v-bind="attrs"
                v-on="on"
              >
                {{ d2 }}
                <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-date-picker
                :color="activecolor"
                @change="getSelectedDatetwo($event)"
                v-model="pickertwo"
                :max="new Date().toISOString().substr(0, 10)"
                :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
              ></v-date-picker>

              <v-divider></v-divider>
              <div class="timeList">
                <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
                <v-list mandatory rounded max-height="341px">
                  <v-list-item-group>
                    <v-dialog v-model="timeslotloader" persistent width="300">
                      <v-card :color="activecolor" dark>
                        <v-card-text>
                          {{ lang.Pleasestandby }}
                          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-list-item v-for="(item, i) in timeslotstwo" :key="i" @change="selectTimeSlottwo(item.image_id)" class="itemBottomMargin">
                      <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                        <v-chip>{{ item.updated_time }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <!-- <v-chip-group
                active-class="amber accent-3-darken-3--text text--accent-4"
                multiple
                :color="activecolor"
              >
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{lang.Pleasestandby }}
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-chip
                  v-for="(item, i) in timeslotstwo"
                  :key="i"
                  @change="selectTimeSlottwo(item.image_id, item.updated_time)"
                  >{{ item.updated_time }}</v-chip
                >
              </v-chip-group> -->
              <v-card-actions>
                <v-btn color="green darken-1" text @click="dialogtwo = false">
                  {{lang.Close}}
                </v-btn>
                <v-btn color="green darken-1" text @click="dialogtwo = false">
                  {{lang.Done }}
                </v-btn>
                <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
                  {{ archiveCalenderyearMonth }}
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                      {{lang.Close}}
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                      <v-icon dark>
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Capturing Insights</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-tabs-items>
    </v-card>
    <v-card>
      <div class="comarebox" v-if="!comapreview">
        <VueCompareImage
          :leftImage="compareimage1"
          :rightImage="compareimage2"
        />
      </div>
      <div class="splitview" v-if="comapreview">
        <div v-if="show_1img360" class="full360right"> 
        <v-pannellum :src="compare_1img360"></v-pannellum></div>
        <v-zoomer v-if="!show_1img360" class="zzoomerright">
          <img v-if="!show_1img360"
            :src="compareimage1"
            style="object-fit: contain; width: 100%; height: 100%"
          />
        </v-zoomer>
        <div v-if="show_2img360" class="full360left"> 
        <v-pannellum :src="compare_2img360"></v-pannellum></div>
        <v-zoomer v-if="!show_2img360" class="zzoomerleft">
          <img v-if="!show_2img360"
            :src="compareimage2"
            style="object-fit: contain; width: 100%; height: 100%"
          />
        </v-zoomer>
      </div>
      <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        top
        right
        timeout="4000"
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            {{lang.Close}}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <br />
    <v-row justify="center" class="actionbtn">
      <v-btn
        class="ma-2"
        depressed
        color="amber accent-3"
        elevation="3"
        align="center"
        outlined
        @click="getpdfreport()"
      >
        {{lang.GeneratePdfReport}}
      </v-btn>
      <v-btn
        class="ma-2"
        depressed
        color="amber accent-3"
        elevation="3"
        align="center"
        outlined
        @click="opensharebox"
      >
        {{lang.MailPdfReport}}
      </v-btn>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="sharebox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> {{lang.ShareByEmail}}</v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send}}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="sharebox = false" color="mydarkbutton">
                    {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Preloader from "../../components/PreLoader";
import image1 from "../../assets/opticvyu_comp_l.png";
import image2 from "../../assets/opticvyu_comp_r.png";
import VueCompareImage from "vue-compare-image";
import VueZoomer from "vue-zoomer";
import VuePannellum from 'vue-pannellum';
import { locals } from "../../utils/locals";
// import moment from 'moment'
Vue.use(VueZoomer);
export default {
  components: {
    Preloader,
    VueCompareImage,
    'VPannellum': VuePannellum,
  },
  name: "schedule_report",
  data() {
    return {
      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      sharebox: false,
      send: false,
      valid: true,
      email: "",
      message: "",
      subject: "",
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      tab: 0,
      currentProject: "",
      dialog: false,
      dialogtwo: false,
      picker: new Date().toISOString().substr(0, 10),
      pickertwo: new Date().toISOString().substr(0, 10),
      activecolor: "amber accent-3",
      timeslots: [],
      timeslotstwo: [],
      compareimage1: image1,
      compareimage2: image2,
      timeslotloader: false,
      comapreview: true,
      d1: 'Select Date',
      d2: 'Select Date',
      filename: "",
      orgname: "",
      projectname: "",
      reporttype: "Comparison Report",
      camid: "",
      cameraname: "",
      url: {},
      datetime1: "",
      datetime2: "",
      multiLine: false,
      snackbar: false,
      text: `Images not found on the selected date.`,
      currentdt: "",
      camnumber: "",
      compare_1img360:'',
      compare_2img360:'',
      show_1img360:false,
      show_2img360:false,
      Local :locals,  
      lang: {},
      dateActiver: false,
      arrayDates: [],
      pickerDate: null,
      datesWithImages: [],
      archiveCalenderyearMonth: false,
      snackbarDatesWithImages: false,
    };
  },
  mounted() {
    
    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.d1 =  this.lang.SelectDate
     this.d2 =  this.lang.SelectDate
    this.getPorjects();
    this.initViewer();
    this.getCamerasbyProject();
    this.current_cam = this.$store.state.currentCamera;
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.userdata = JSON.parse(localStorage.getItem('userdata'))

     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //console.log("Current data get", this.$store.state.currentCamera);
      this.camers = [];
      this.timeslots = [];
      this.timeslotstwo = [];
      this.current_cam = this.$store.state.currentCamera;
      this.compareimage1 = image1;
      this.compareimage2 = image2;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      var currentdate = new Date();
      var currentmonth = currentdate.getMonth() + 1;
      this.currentdt =
        currentdate.getDate() +
        "_" +
        currentmonth +
        "_" +
        currentdate.getFullYear();
      this.getCamerasbyProject();
    },
  },
  computed: {
    params() {
      return {};
    },
    param_add() {
      return {};
    },
  },
  methods: {

    allowedDates(val) {
      var values = [];
      this.datesWithImages.forEach((item) => {
        var daywithimage = item.image_date;
        values.push(daywithimage);
      });

      if (values.includes(val)) return true
      if (values.includes(val)) return ['red', '#00f']
      return false
    },
    alldates(val) {
      return val;
    },
    datesWithImage() {
      this.timeslotloader = true;
      this.dateActiver = !this.dateActiver;
      var camera_id = this.current_cam.camera_id;
      this.snackbarDatesWithImages = true;
      if (this.dateActiver) {
        this.archiveCalenderyearMonth = "Filtered dates where images are available.";
      } else {
        this.archiveCalenderyearMonth = "Showing all dates.";
      }

      axios({
        url: "/camera/" + camera_id + "/dates_with_images",
        method: "GET",
      })
        .then((resp) => {
          this.datesWithImages = resp.data.success;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });

          this.getCamerasbyProject(this.items[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }
      //console.log(url);
    },
    getSelectedDate(data) {
      this.current_cam = this.$store.state.currentCamera;
      this.timeslotloader = true;
      this.d1 = data;
      var d = data.split("-");
      //console.log(d);
      var nd = d[0] + d[1] + d[2];
      //  console.log(nd, this.current_cam.camera_id);
      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }
          var dd = resp.data.success;
          this.timeslots = dd;

          // console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    selectTimeSlot(imageID, uptime) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.datetime1 = this.d1 + " " + uptime;
      this.getImageUrlfirst(imageID);
      this.preloader = true;
    },
    getImageUrlfirst(id) {
      // console.log("parameter pdf", this.params);
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
          this.current_cam = this.$store.state.currentCamera;
              if(this.current_cam.cam_360 == 1){
                  this.show_1img360 = true;
                  this.compare_1img360 = resp.data.success.image_url;
                  console.log("360img", this.compare_1img360);
            }else{
                this.show_1img360 = false;
                this.compareimage1 = resp.data.success.image_url;
                console.log("normalimg", this.compareimage1);
            }
          //this.compareimage1 = resp.data.success.image_url;
          this.dialog = false;
          var v = this;

          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    getSelectedDatetwo(data) {
      this.current_cam = this.$store.state.currentCamera;
      this.d2 = data;
      this.timeslotloader = true;
      var d = data.split("-");
      // console.log(d);
      var nd = d[0] + d[1] + d[2];

      //console.log(nd, this.current_cam.camera_id);
      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }
          var dd = resp.data.success;
          this.timeslotstwo = dd;
          //console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectTimeSlottwo(imageID, uptime2) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.datetime2 = this.d2 + " " + uptime2;
      this.getImageUrlSecond(imageID);
      this.preloader = true;
    },
    getImageUrlSecond(id) {
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
           this.current_cam = this.$store.state.currentCamera;
              if(this.current_cam.cam_360 == 1){
                  this.show_2img360 = true;
                  this.compare_2img360 = resp.data.success.image_url;
            }else{
                this.show_2img360 = false;
                this.compareimage2 = resp.data.success.image_url;
            }
          //this.compareimage2 = resp.data.success.image_url;
          this.dialogtwo = false;
          console.log("parameter pdf", this.params);
          var v = this;
          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCamerasbyProject() {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      axios({ url: "/camera/" + this.currentProject.id, method: "GET" })
        .then((resp) => {
          //console.log('get cameras by project', resp.data.success);
          this.camers = resp.data.success.filter(function (item) {
            return item.camera_active == 1;
          });
          var len = 0;
          if (resp.data.success != null) {
            len = resp.data.success.length;
          }
          if (len > 0) {
            //console.log("count", resp.data.success.length);
            for (var i = 0; i < len; i++) {
              if (
                this.current_cam.camera_id == resp.data.success[i].camera_id
              ) {
                this.camnumber = i + 1;
              }
            }
          }
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpdfreport() {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.preloader = true;
    var res = this.compareimage1.split("?");
   
    var res2 = this.compareimage2.split("?");

    var cloud = '';
     var img1 = '';
     var img2 = '';
     if(this.currentProject.s3bucket == "default") {      
        // $bucket = 'aws.opticvyu.com.001';
        // $region = 'ap-southeast-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';
       

        img1 = res[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
    

      }else if(this.currentProject.s3bucket == "india") {         
        // $bucket = 'aws.opticvyu.com.india.001';
        // $region = 'ap-south-1';
        cloud = 'https://d3fpj3owe0806r.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001");

      }else if(this.currentProject.s3bucket == "usa") {         
        // $bucket = 'aws.opticvyu.com.usa.001';
        // $region = '';
        cloud = 'https://dsh9smw3t8bth.cloudfront.net';

        img1 = res[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001"); 
        img2 = res2[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001");

      }else if(this.currentProject.s3bucket == "europe ") {         
        // $bucket = 'aws.opticvyu.com.eu.001';
        // $region = 'eu-west-2';
        cloud = 'https://dkg8sn4ditt1q.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001"); 
        img2 = res2[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001");


      }else if(this.currentProject.s3bucket == "saudi") {         
        // $bucket = 'aws.opticvyu.com.ksa.001';
        // $region = 'us-east-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001"); 
         img2 = res2[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001");

      }
   
      var currentdate = new Date();
      var currentmonth = currentdate.getMonth() + 1;
      this.currentdt =
        currentdate.getDate() +
        "_" +
        currentmonth +
        "_" +
        currentdate.getFullYear();
    const formData = new URLSearchParams();
      formData.append(
        "filename",
        this.currentProject.project_name + "_" + this.currentdt
      );
      formData.append("orgname", this.currentProject.company);
      formData.append("projectname", this.currentProject.project_name);
      formData.append("reporttype", this.reporttype);
      formData.append("camid", this.current_cam.camera_id);
      formData.append("camera", this.camnumber);
      formData.append("1", [img1,img2]);
     

      var filename = this.currentProject.project_name + "_" + this.currentdt;
      axios({
        crossOrigin: true,
        url: "https://www.opticvyu.com/api/get/instant-report",
        params: formData,
        method: "get",
        // headers: {
        //   Authorization: "Basic b3B0aWN2eXU6MHB0aWN2eXU=",
        //   "Content-Type": "application/x-www-form-urlencoded",
        // },
       // responseType: "arraybuffer",
      })
        .then((response) => {
          this.preloader = false;
          console.log(response);
          // let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.target = "_blank";
          link.href = response.data.success.reports[0];
          link.download = filename + ".pdf";
          link.click();
        })
        .catch((err) => {
          console.log("Report Error", err);
           this.snackbar = true;
          this.text = `Getting technical issue. Please try again later`;
        });
    },
    opensharebox() {
      this.sharebox = true;
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharepdfreport(); // action to login
      }
    },
    sharepdfreport() {
     
     var res = this.compareimage1.split("?");
   // var img1 = img1 = res[0].replace("https://d1zsmm4yi50872.cloudfront.net","https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
   
    var res2 = this.compareimage2.split("?");
   
    //var img2 = res2[0].replace("https://d1zsmm4yi50872.cloudfront.net","https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
    
     
    var cloud = '';
     var img1 = '';
     var img2 = '';
     if(this.currentProject.s3bucket == "default") {      
        // $bucket = 'aws.opticvyu.com.001';
        // $region = 'ap-southeast-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';
       

        img1 = res[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
    

      }else if(this.currentProject.s3bucket == "india") {         
        // $bucket = 'aws.opticvyu.com.india.001';
        // $region = 'ap-south-1';
        cloud = 'https://d3fpj3owe0806r.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001");

      }else if(this.currentProject.s3bucket == "usa") {         
        // $bucket = 'aws.opticvyu.com.usa.001';
        // $region = '';
        cloud = 'https://dsh9smw3t8bth.cloudfront.net';

        img1 = res[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001"); 
        img2 = res2[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001");

      }else if(this.currentProject.s3bucket == "europe ") {         
        // $bucket = 'aws.opticvyu.com.eu.001';
        // $region = 'eu-west-2';
        cloud = 'https://dkg8sn4ditt1q.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001"); 
        img2 = res2[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001");


      }else if(this.currentProject.s3bucket == "saudi") {         
        // $bucket = 'aws.opticvyu.com.ksa.001';
        // $region = 'us-east-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001"); 
         img2 = res2[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001");

      }
    
    
      console.log(img2);
      const formData = new URLSearchParams();
      formData.append("to", this.email);
      formData.append("subject", this.subject);
      formData.append(
        "filename",
        this.currentProject.project_name + "_" + this.currentdt
      );
      formData.append("orgname", this.currentProject.company);
      formData.append("projectname", this.currentProject.project_name);
      formData.append("reporttype", this.reporttype);
      formData.append("camid", this.current_cam.camera_id);
      formData.append("camera", this.camnumber);
      formData.append("1", [img1,img2]);
      

      //var camid = this.currentcam.camera_id;
      this.send = true;
      axios({
        crossOrigin: true,
        url: "https://www.opticvyu.com/api/get/instant-report",
        params: formData,
        method: "get",
      })
        .then((resp) => {
          console.log("share resp", resp);
          this.sharebox = false;
          this.send = false;
         
            this.snackbar = true;
            this.text =  this.lang.emailsuccessMsg;
          
        })
        .catch((err) => {
          console.log(err);
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },
  },
};
</script>
<style>
.comarebox {
  display: block;
  width: 80%;
  margin: auto;
}
.zzoomerleft{
   position: absolute;
   left:50%;
   /* width:50%; */
   display:inline-block;

 }
 .zzoomerright{
   position: absolute;
   right:50%;
   /* width:50%; */
   display:inline-block;

 }
.graybg {
  background: #565656;
}
.full360right {
     display: block; 
    width: 50%;
    height: 70vh;
    background: #000000ab;
   position: absolute;
    top: 8%;
    right: 50%;
    /* z-index: 1; */
}
.full360left {
     display: block; 
    width: 50%;
    height: 70vh;
    background: #000000ab;
   position: absolute;
    top: 8%;
    left: 50%;
    /* z-index: 1; */
}
.actionbtn{
  top: 94%;
    position: absolute;
    left: 30%;
    right: 30%;
}
.timeList {
  position: absolute;
  width: 110px;
  top: 89px;
  right: 0px;
  height: 340px;
  display: block;
  overflow: hidden;
}

.dateWithImageButton {
  position: absolute !important;
  top: 237px;
  left: 48px;
}
@media only screen and (max-width: 600px) {
  .dateWithImageButton {
    position: unset !important;
    top: unset;
    left: unset;
  }
}
.itemBottomMargin {
  margin-bottom: -9px !important;
}
</style>