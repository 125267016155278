<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
    <v-row>
      <v-col class="col-sm-9">
        <span class="ttile">
          {{ lang.VisitDate }} : {{ visitdata.visit_date }}
        </span>
      </v-col>
      <v-col class="col-sm-3 text-right editedUploadVideoButton">
        <v-btn v-if="isOpticvyuUser" @click="uploadEditedVideo = true" depressed color="#f8ba04"
        >
        Upload Edited Videos
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" pb-0>
        <v-btn
          color="#292929"
          class="ma-2 white--text uploadDrawing"
          small
          @click="
            uploadzoneAction();
            fullscreenexit();
          "
          v-if="uploadzoneb"
        >
          {{ lang.UploadMedia }}
          <v-icon right dark>
            mdi-arrow-up-bold
          </v-icon>
        </v-btn>
        <!-- <v-btn color="#ffc400" class="ma-2 Block--text uploadDrawing" @click="addDateAction" v-if="dateviewb">
                Survey Dates
                <v-icon right dark>
                    mdi-calendar-today
                </v-icon>
            </v-btn> -->
        <!-- <v-btn color="#292929" class="ma-2 Block--text uploadDrawing" small @click="galleryviewAction" v-if="galleryviewb">
                Gallery
                <v-icon right dark>
                    mdi-image-multiple
                </v-icon>
            </v-btn> -->

        <v-btn
          class="ma-2 Block--text uploadDrawing"
          small
          @click="
            mediaSelect('Drone Video');
            fullscreenexit();
          "
          :color="`${selectedMedia == 'Drone Video' ? '#ffc400' : '#FFF'}`"
          :disabled="visitdata.drone_videos_count == 0"
          v-if="mm"
        >
          <v-icon dark left> mdi-video-marker </v-icon> {{ lang.DroneVideos }}
          <strong>{{ visitdata.drone_videos_count }}</strong>
        </v-btn>
        <v-btn
          class="ma-2 Block--text uploadDrawing"
          small
          @click="
            mediaSelect('Drone Images');
            fullscreenexit();
          "
          :color="`${selectedMedia == 'Drone Images' ? '#ffc400' : '#FFF'}`"
          :disabled="visitdata.drone_images_count == 0"
          v-if="mm"
        >
          <v-icon dark left> mdi-quadcopter </v-icon> {{ lang.DroneImages }}
          <strong>{{ visitdata.drone_images_count }}</strong>
        </v-btn>
        <v-btn
          class="ma-2 Block--text uploadDrawing"
          small
          @click="
            mediaSelect('Normal Images');
            fullscreenexit();
          "
          :color="`${selectedMedia == 'Normal Images' ? '#ffc400' : '#FFF'}`"
          :disabled="visitdata.normal_images_count == 0"
          v-if="mm"
        >
          <v-icon dark left> mdi-image-multiple </v-icon>
          {{ lang.NormalImages }}
          <strong>{{ visitdata.normal_images_count }}</strong>
        </v-btn>
        <v-btn
          class="ma-2 Block--text uploadDrawing"
          small
          @click="
            mediaSelect('360 Images');
            fullscreenexit();
          "
          :color="`${selectedMedia == '360 Images' ? '#ffc400' : '#FFF'}`"
          :disabled="visitdata.panaroma_count == 0"
          v-if="mm"
        >
          <v-icon dark left> mdi-axis-z-rotate-clockwise </v-icon>
          {{ lang.Images360 }} <strong>{{ visitdata.panaroma_count }} </strong>
        </v-btn>

        <v-select
          :items="mediatypeUpload"
          v-if="uploadzone"
          :label="lang.MediaType"
          class="media_type"
          v-model="selectedMediaupload"
        ></v-select>
        <v-btn
          color="#292929"
          class="ma-2 white--text"
          small
          @click="addDateAction"
          v-if="galleryview"
        >
          <v-icon left dark>
            mdi-arrow-left-bold-circle
          </v-icon>
          {{ lang.Back }}
        </v-btn>
        <v-btn
          color="#292929"
          class="ma-2 white--text"
          small
          @click="galleryviewAction"
          v-if="uploadzone"
        >
          <v-icon left dark>
            mdi-arrow-left-bold-circle
          </v-icon>
          {{ lang.Back }}
        </v-btn>
        <!-- <span class="ttile">
          {{ lang.VisitDate }} : {{ visitdata.visit_date }}
        </span> -->

        <v-btn
          color="red"
          class="filterb  white--text ma-2 Block--text "
          small
          v-if="showdeletebuttons"
          @click="removeselectedMedias"
        >
          {{ lang.RemoveSelected }}
          <v-icon right dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="12" v-if="dateview">
        <div class="d_w">
          <div
            class="dayadd d-flex child-flex"
            @click="addvisitDate"
            :title="lang.AddNewVisitingDate"
          >
            <span class="dayadd ">
              <v-icon dark large>
                mdi-plus
              </v-icon>
              <p>{{ lang.Upload }}</p>
            </span>
          </div>
          <div
            v-for="n in visitdatsss"
            :key="n.id"
            :class="
              `d-flex child-flex calenderit ${
                currentvisitId == n.id ? 'active' : ''
              }`
            "
            cols="1"
            :title="lang.OpenGallery"
          >
            <v-icon
              dark
              @click="gotoUpload(n)"
              class="uploadb"
              :title="lang.UploadMedia"
            >
              mdi-plus
            </v-icon>
            <span class="days">
              {{ n.visit_date }}
            </span>

            <span class="menufooterf">
              <span class="rt">
                <v-btn
                  class="mx-2"
                  @click="selectdate(n, 'Normal Images')"
                  tile
                  small
                  color="#fff"
                  :disabled="n.normal_images_count == 0"
                >
                  <v-icon dark left> mdi-image-multiple </v-icon>
                  {{ lang.NormalImages }}
                  <strong>{{ n.normal_images_count }}</strong>
                </v-btn>
              </span>
              <span class="rt">
                <v-btn
                  class="mx-2"
                  @click="selectdate(n, '360 Images')"
                  tile
                  small
                  color="#fff"
                  :disabled="n.panaroma_count == 0"
                >
                  <v-icon dark left> mdi-axis-z-rotate-clockwise </v-icon>
                  {{ lang.Images360 }} <strong>{{ n.panaroma_count }} </strong>
                </v-btn>
              </span>
              <span class="rt">
                <v-btn
                  class="mx-2"
                  @click="selectdate(n, 'Drone Images')"
                  tile
                  small
                  color="#fff"
                  :disabled="n.drone_images_count == 0"
                >
                  <v-icon dark left> mdi-quadcopter </v-icon>
                  {{ lang.DroneImages }}
                  <strong>{{ n.drone_images_count }}</strong>
                </v-btn>
              </span>
              <span class="rt">
                <v-btn
                  class="mx-2"
                  @click="selectdate(n, 'Drone Video')"
                  tile
                  small
                  color="#fff"
                  :disabled="n.drone_videos_count == 0"
                >
                  <v-icon dark left> mdi-video-marker </v-icon>
                  {{ lang.DroneVideos }}
                  <strong>{{ n.drone_videos_count }}</strong>
                </v-btn>
              </span>
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="12" v-if="uploadzone">
        <v-card class="formcard">
        <v-radio-group
          v-model="srtRadioButtonSelection"
          row
          class="srtRadioButton"
          v-if="selectedMediaupload == 'Drone Video'"
        >
          <v-radio
            label="Old Drone Srt File"
            value="oldSrtFile"
          ></v-radio>
          <v-radio
            label="DJI MINI 3 Pro"
            value="newSrtFile"
          ></v-radio>
          <v-radio
            label="DJI MINI 4 Pro"
            value="dji4miniSrtFile"
          ></v-radio>
          <v-radio
            label="Converted Srt File"
            value="converted"
          ></v-radio>
        </v-radio-group>
          <v-file-input
            v-model="dronefile"
            v-if="selectedMediaupload == 'Drone Video'"
            :placeholder="lang.srtfile"
            :label="lang.srtfile"
            prepend-icon="mdi-paperclip"
            @change="dronefileupload"
            :disabled="isInputDisabled"
            accept=".srt,.SRT"
          >
          </v-file-input>
          <v-text-field
            v-if="selectedMediaupload == 'Drone Video' && havedata"
              v-model="srtVideoLink"
              label="Video URL"
              prepend-icon="mdi-link"
              :rules="[rules.url]"
            ></v-text-field>
          <v-file-input
            id="fileItem"
            v-if="selectedMediaupload == 'Drone Video' && havedata"
            prepend-icon="mdi-video"
            class="droppzone"
            label="Select and upload Drone Videos"
            accept="video/mp4,video/x-m4v,video/*"
            v-model="vidoesss"
            @change="videosAdded"
          ></v-file-input>

          <v-file-input
            id="orthophoto"
            v-if="selectedMediaupload == 'Orthophoto'"
            prepend-icon="mdi-image"
            class="droppzone"
            label="Select and upload Orthophoto"
            accept=".tiff, .tif"
            v-model="Orthophoto"
            @change="OrthophotoAdded"
          ></v-file-input>
          <div>
            <vue-dropzone
              v-if="selectedMediaupload !== 'Drone Video' && selectedMediaupload !== 'Orthophoto'"
              ref="myVueDropzone"
              id="drop1"
              v-on:vdropzone-thumbnail="thumbnail"
              v-on:vdropzone-sending="sendingEvent"
              :options="dropOptions"
            ></vue-dropzone>
          </div>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        v-if="galleryview && !fulllImage"
        class="imagesSection"
      >
        <v-row>
          <v-col
            v-for="(n, index) in images"
            :key="n.id"
            class="d-flex child-flex gitem"
            cols="4"
          >
            <input
              type="checkbox"
              v-model="selected"
              :value="n.id"
              @change="selecteditem(n.id)"
              class="gitemcheck"
            />
            <span class="addUrlIconSpan" @click="openUpdateUrlPopup(n)" v-if="selectedMedia == 'Drone Video'"><v-icon class="addUrlIcon">mdi-high-definition-box</v-icon></span>
            <v-img
              :src="n.thumbnail_path == 'null' ? vthumbnail : n.thumbnail_path"
              aspect-ratio="1.5"
              class="grey lighten-2"
              @click="fullpanaromaImage(n, index)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>

      <div
        :class="
          `fullviewbox_video ${
            selectedMedia == 'Drone Video' ? 'video' : 'normal'
          }`
        "
        v-if="fulllImage"
        tabindex="0"
        class="col-md-6 col-12"
        ref="myDiv"
      >
        <v-row class="ovcheckinfo" v-if="selectedMedia == 'Drone Video'">
          <v-btn outlined class="mr-1" v-if="userdata.user_code == 2">
            <v-checkbox
              v-if="selectedMedia == 'Drone Video'"
              v-model="infoAddMode"
              :label="lang.AddVideoInfo"
            ></v-checkbox>
          </v-btn>
          <!-- <div> &nbsp; | &nbsp; </div> -->
          <v-btn outlined class="dddinfo mr-1" @click="taskboxopen">
            <v-icon>mdi-format-list-bulleted</v-icon> {{ lang.MetaList }}
          </v-btn>
        </v-row>
        <v-icon
          dark
          color="#000"
          @click="fullscreenexit()"
          class="linear-exitbuttons"
          large
        >
          mdi-close-circle
        </v-icon>
        <div class="info-items-edit-view">
          <p v-for="info in infolist" :key="info.id">
            <span
              class="info_title"
              v-if="
                activePosition >= Math.round(info.time) &&
                  activePosition - 6 <= Math.round(info.time)
              "
            >
              {{ info.title }}
            </span>
            <span
              class="info_description"
              v-if="
                activePosition >= Math.round(info.time) &&
                  activePosition - 6 <= Math.round(info.time)
              "
            >
              {{ info.description }}
            </span>
          </p>
        </div>
        <div cols="12" md="6">
          <video
            v-if="selectedMedia == 'Drone Video'"
            class="videostyle"
            id="cspd_video"
            controls="true"
            ref="custome_timelapsvideo"
          ></video>
        </div>
        <v-pannellum
          v-if="selectedMedia == '360 Images'"
          class="fullscreen"
          :src="liveimage"
        ></v-pannellum>

        <v-zoomer
          class=""
          v-if="
            selectedMedia == 'Drone Images' || selectedMedia == 'Normal Images'
          "
        >
          <img
            :src="liveimage"
            @load="loaded"
            style="width: 100%; height: 92vh"
          />
        </v-zoomer>
        <!-- <v-icon dark color="#000" @click="fullscreenexit()" class="exitbuttons" large>
                mdi-close-circle
            </v-icon> -->

        <v-btn icon @click="preImage" class="left arrow" v-if="cimi > 0">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="nextImage"
          class="right arrow next-image"
          v-if="cimi < images.length - 1"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-col cols="12" md="6" class="maps" v-if="loopcout && galleryview">
        <gmap-map
          map-type-id="satellite"
          :center="center"
          :zoom="zoom"
          class="mapite"
          ref="mapRef"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
          </gmap-info-window>

          <gmap-marker
            :key="i"
            v-for="(m, i) in markers"
            :position="m.position"
            :clickable="true"
            :icon="
              activePosition >= Math.round(m.time) &&
              activePosition - 6 <= Math.round(m.time)
                ? mapMarkerActive
                : mapMarker
            "
            @click="videocontrollers(m, i)"
          ></gmap-marker>
        </gmap-map>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="visitDatepopup"
    >
      <template>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-date-picker
                  v-model="picker"
                  full-width
                  :allowed-dates="allowedDates"
                  color="mylightbutton"
                ></v-date-picker>
              </v-col>
              <v-col cols="12">
                <v-btn class="mr-4" @click="addVisitDate" color="mylightbutton">
                  {{ lang.submit }}
                </v-btn>
                <v-btn @click="visitDatepopup = false" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="infobox"
    >
      <template>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="info_title"
                  :label="lang.DroneHeading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  solo
                  v-model="info_description"
                  :label="lang.DroneDescription"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="mr-4"
                  @click="infoboxsubmit"
                  color="mylightbutton"
                >
                  {{ lang.DroneInfoAdd }}
                </v-btn>
                <v-btn @click="infobox = false" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="updateinfobox"
    >
      <template>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="info_title"
                  :label="lang.DroneHeading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  solo
                  v-model="info_description"
                  :label="lang.DroneDescription"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="mr-4"
                  @click="updateinfoboxsubmit"
                  color="mylightbutton"
                >
                  {{ lang.Update }}
                </v-btn>
                <v-btn @click="updateinfobox = false" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar v-model="alert" top right timeout="3000">
      {{ alertmessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>
    <div class="progress" v-if="showprogrss">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="uploadPercentage"
        color="primary"
      >
        {{ uploadPercentage }}
      </v-progress-circular>
      <v-btn color="red" @click="reloadpage">
        {{ lang.Cancel }}
      </v-btn>
    </div>

    <v-col cols="12" md="6" class="infobox metadata" v-if="taskbox">
      <v-icon dark color="#000" @click="taskbox = false" class="exittask">
        mdi-close
      </v-icon>
      <div class="mt-8">
        <v-data-table
          :headers="tableheaders"
          item-key="id"
          :items="infolist"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:item.status="{ item }">
            <v-btn
              fab
              dark
              icon
              small
              v-if="userdata.user_code == 0 || userdata.user_code == 2"
              color="black"
              @click="editTask(item)"
            >
              <v-icon dark>
                mdi-pencil-box-multiple
              </v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              icon
              small
              v-if="userdata.user_code == 0 || userdata.user_code == 2"
              color="black"
              @click="deleteTask(item.id)"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-col>

      <v-dialog
        max-width="600"
        color="amber accent-3"
        v-model="updateUrlPopup"
      >
        <template>
          <v-form v-model="isFormValid">
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                 <v-text-field label="Insert URL Here" :rules="[rules.url]" outlined v-model="droneVideoURL"></v-text-field>
                <!-- </v-col>
                <v-col cols="12"> -->
                  <v-btn class="mr-4" @click="updateVideoUrl" color="mylightbutton" :disabled="!isFormValid">
                    {{ lang.submit }}
                  </v-btn>
                  <v-btn @click="updateUrlPopup = false" color="mydarkbutton">
                    {{ lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          </v-form>
        </template>
      </v-dialog>

      <v-dialog
        max-width="600"
        color="amber accent-3"
        v-model="uploadEditedVideo"
      >
        <template>
          <v-form v-model="isEditedVideoUploadFormValid">
          <v-card>
            <v-card-title class="text-h5" color="#f8ba04">
          Upload Edited Videos
        </v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                 <v-text-field label="Insert URL Here" :rules="[rules.url]" outlined v-model="editedVideoUrl"></v-text-field>
                 <v-text-field label="Add Remarks" outlined v-model="editedVideoRemarks"></v-text-field>
                  <v-btn class="mr-4" @click="updateEditedVideoInfo" color="mylightbutton" :disabled="!isEditedVideoUploadFormValid">
                    {{ lang.submit }}
                  </v-btn>
                  <v-btn @click="uploadEditedVideo = false" color="mydarkbutton">
                    {{ lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          </v-form>
        </template>
      </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import vueDropzone from "vue2-dropzone";
import exifr from "exifr";
import VuePannellum from "vue-pannellum";
import parseSRT from "parse-srt";
import vthumbnail from "../../assets/video_thumbnail.png";
import icon from "../../assets/Dot-Icon-Blue.png";
import iconactive from "../../assets/Dot-Icon-BlueActive.png";
import { locals } from "../../utils/locals";
import $ from "jquery";
import rotate from '../../assets/rotedmobilepsd.jpg';
export default {
  components: {
    Preloader,
    vueDropzone,
    VPannellum: VuePannellum
  },
  name: "CompareEvent",
  data() {
    return {
      rotate: rotate,
      vthumbnail: vthumbnail,
      preloader: false,
      files: [],
      dropOptions: {
        url: "https://api.opticvyu.com/api/project/gps/image-upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        paramName: "file",
        maxFilesize: 500, // MB
        acceptedFiles: ".jpeg,.jpg,.png",
        maxFiles: 30,
        chunkSize: 500, // Bytes
        thumbnailMethod: "contain",
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        autoProcessQueue: false,
        dictDefaultMessage:
          "Click to upload Images  <br />----------------- <br/>Drag & Drop",
        parallelUploads: 1,
        addRemoveLinks: true
      },
      thumbnailimage: [],
      indd: 0,
      pro_dir: "",
      projectid: "",
      userdata: {},
      imagesExtract: [],
      currentvisitId: "",
      visitdata: "",
      galleryview: false,
      uploadzone: false,
      visitDatepopup: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      images: [],
      loopcout: false,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      dats: [],
      center: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoom: 5,
      markers: [],
      currentPlace: null,
      coi: 0,
      valid: true,
      currentMidx: null,
      fulllImage: false,
      liveimage: "",
      cimi: 0,
      alertmessage: "",
      alert: false,
      mediatypeUpload: [
        "360 Images",
        "Drone Images",
        "Normal Images",
        "Drone Video",
        "Orthophoto"
      ],
      selectedMedia: "360 Images",
      selectedMediaupload: "360 Images",
      visitdatsss: {},
      dateview: true,
      selected: [],
      showdeletebuttons: false,
      uploadzoneb: false,
      dateviewb: false,
      galleryviewb: false,
      dronefile: "",
      content: "",
      uploadPercentage: "",
      showprogrss: false,
      mapMarkerActive: iconactive, //"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMyAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMyAxMS41QzIzIDIxLjUgMTEuNSAyOC41IDExLjUgMjguNUMxMS41IDI4LjUgMCAyMS41IDAgMTEuNUMwIDUuMTQ4NzMgNS4xNDg3MyAwIDExLjUgMEMxNy44NTEzIDAgMjMgNS4xNDg3MyAyMyAxMS41WiIgZmlsbD0iI0M3MDYyOSIvPg0KPGNpcmNsZSBjeD0iMTEuNSIgY3k9IjExLjUiIHI9IjUuNSIgZmlsbD0iIzgxMDAxNyIvPg0KPC9zdmc+DQo=",
      mapMarker: icon, //"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMyAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMyAxMS41QzIzIDIxLjUgMTEuNSAyOC41IDExLjUgMjguNUMxMS41IDI4LjUgMCAyMS41IDAgMTEuNUMwIDUuMTQ4NzMgNS4xNDg3MyAwIDExLjUgMEMxNy44NTEzIDAgMjMgNS4xNDg3MyAyMyAxMS41WiIgZmlsbD0iIzMzMzMzMyIvPg0KPGNpcmNsZSBjeD0iMTEuNSIgY3k9IjExLjUiIHI9IjUuNSIgZmlsbD0iYmxhY2siLz4NCjwvc3ZnPg0K",
      selectedKey: null,
      selectedMarker: null,
      activePosition: 0,
      havedata: false,
      showMedidatypes: false,
      mm: false,
      infoAddMode: false,
      infobox: false,
      info_description: "",
      info_title: "",
      markdata: "",
      markindex: "",
      infoxitem: "",
      infolist: [],
      tableheaders: [
        {
          text: "Heading",
          value: "title"
          // width: 150,
        },
        {
          text: "Description",
          value: "description",
          width: 150
        },
        {
          text: "Time",
          value: "time"
          // width: 60,
        },
        {
          text: "Height",
          value: "height"
          // width: 70,
        },
        {
          text: "Distance",
          value: "distance"
          // width: 70,
        },
        {
          text: "Action",
          value: "status"
          // width: 100,
        }
      ],
      taskbox: false,
      updateinfobox: false,
      info_id: 0,
      Local: locals,
      lang: {},
      focusedDiv: null,
      srtRadioButtonSelection:"",
      srtVideoLink:"",
      rules: {
        required: (value) => !!value || "This field is required.",
        url: (value) => {
          // A simple regex to validate URLs
          const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          return regex.test(value) || "Invalid URL.";
        },
      },
      updateUrlPopup:false,
      droneVideoURL:"",
      droneVideoID:"",
      isFormValid: false,
      uploadEditedVideo:false,
      editedVideoUrl:"",
      isEditedVideoUploadFormValid:false,
      editedVideoRemarks:"",
      Orthophoto : '',
      vidoesss : '',
      isOpticvyuUser: false,
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    (this.tableheaders = [
      {
        text: this.lang.DroneHeading,
        value: "title"
        // width: 150,
      },
      {
        text: this.lang.DroneDescription,
        value: "description",
        width: 150
      },
      {
        text: this.lang.DroneTime,
        value: "time"
        // width: 60,
      },
      {
        text: this.lang.DroneHeight,
        value: "height"
        // width: 70,
      },
      {
        text: this.lang.DroneDistance,
        value: "distance"
        // width: 70,
      },
      {
        text: this.lang.Actions,
        value: "status"
        // width: 100,
      }
    ]),
      (this.userdata = JSON.parse(localStorage.getItem("userdata")));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.opticvyu_user == 1) {
      this.isOpticvyuUser = true;
    }
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.pro_dir = currentProject.pro_dir;
    this.initViewer();
    // var _self = this;
    // document.body.addEventListener('keyup', e => {
    //     if (e.keyCode === 27) {
    //         _self.fullscreenexit();
    //     }
    // })
    this.keydownListener = this.handleKeydown.bind(this);
    document.addEventListener("keydown", this.keydownListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydownListener);
  },
  watch: {
    "$store.state.currentvisitId": function() {
      this.preloader = true;

      var _self = this;
      setTimeout(() => {
        _self.currentvisitId = _self.$store.state.currentvisitId;
        // this.$refs.myVueDropzone.removeAllFiles();
        _self.initViewer();
        _self.preloader = false;
      }, 5000);
    },
    $route: function() {
      //console.log(to);
      var hash = window.location.hash;
      console.log(hash);
      var result = hash.substring(1);
      this.openbyDrop(result);
    }
  },
  created() {
    window.addEventListener("keyup", e => {
      if (e.key == "Escape") {
        console.log(e.key);
      }
    });
  },
  computed:{
    isInputDisabled() {
      return this.srtRadioButtonSelection === "";
    }
  },
  methods: {
    handleKeydown(event) {
      if (event.key === "Escape" && this.$refs.myDiv?.contains(event.target)) {
        event.preventDefault();
        // do something here, like close a modal or hide a dropdown menu
      }
    },
    allowedDates: date => date <= new Date().toISOString().substr(0, 10),
    initViewer() {
      //   this.preloader = true;
      this.getVisitDates();
      console.log("Page Load", this.currentvisitId);
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("this.visitdata", this.visitdata);
      // this.dropOptions.dictDefaultMessage = "Click to upload Images for visit "+ this.visitdata.visit_date +" <br />----------------- <br/>Drag & Drop";
      //this.getImages();
    },
    sendingEvent(file, xhr, formData) {
      console.log("d", file);
      // formData.append("project_id", "2");
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("xhr", xhr);
      console.log("indd", this.indd);
      console.log("this.imagesExtract[this.indd]", this.imagesExtract);
      console.log("latitude", this.imagesExtract[this.indd].latitude);

      formData.append("latitude", this.imagesExtract[this.indd].latitude);

      formData.append("longitude", this.imagesExtract[this.indd].longitude);

      formData.append(
        "gps_date_stamp",
        this.imagesExtract[this.indd].GPSDateStamp
      );

      formData.append(
        "gps_time_stamp",
        this.imagesExtract[this.indd].GPSTimeStamp
      );

      formData.append("project_id", this.projectid);
      formData.append("proj_dir", this.pro_dir);
      formData.append("thumbnail", this.thumbnailimage[this.indd]);
      formData.append("user_id", this.userdata.id);
      formData.append("visit_date", this.visitdata.visit_date);
      formData.append("visit_id", this.visitdata.id);
      formData.append("mediatype", this.selectedMediaupload);
      console.log("formData", formData);
      this.indd++;
    },
    async thumbnail(file, dataUrl) {
      //  this.thumbnailimage = dataUrl;
      this.thumbnailimage.push(dataUrl);
      var s = await exifr.parse(file);

      console.log("sss",s);

      if (s) {
        // this.imagesExtract = s
        this.imagesExtract.push(s);
        console.log(
          s.latitude,
          s.longitude,
          s.GPSDateStamp,
          s.GPSTimeStamp,
          s.Make,
          s.Model,
          s.Software,
          file.name
        );
      } else {
        this.$refs.myVueDropzone.removeFile(file);
        this.alert = true;
        this.alertmessage = this.lang.latlongnotavailble;
      }
      // console.log("this.thumbnailimage g", this.thumbnailimage);
      var _self = this;
      setTimeout(() => {
        _self.$refs.myVueDropzone.processQueue();
      }, 3000);
    },
    uploadzoneAction() {
      this.galleryview = false;
      this.uploadzone = true;
      this.dateview = false;
      this.mm = false;
      this.galleryviewb = true;
      this.uploadzoneb = false;
      this.dateviewb = true;
      this.imagesExtract = [];
      this.thumbnailimage = [];
      this.indd = 0;
    },
    galleryviewAction() {
      this.getImages();

      this.uploadzone = false;
      this.dateview = false;
      this.mm = true;

      this.galleryviewb = false;
      this.uploadzoneb = true;
      this.dateviewb = true;
      this.imagesExtract = [];
      this.thumbnailimage = [];
      this.indd = 0;

      var _slef = this;
      setTimeout(() => {
        _slef.galleryview = true;
        _slef.storeVisitData();
      }, 1000);
    },
    addDateAction() {
      this.galleryview = false;
      this.uploadzone = false;
      this.dateview = true;
      //this.visitDatepopup = true;
      this.mm = false;
      this.galleryviewb = false;
      this.uploadzoneb = false;
      this.dateviewb = false;
      this.getVisitDates();
    },
    addvisitDate() {
      this.visitDatepopup = !this.visitDatepopup;
    },
    mediaSelect(c) {
      console.log(c);
      this.preloader = true;

      this.selectedMedia = c;
      var _self = this;
      setTimeout(() => {
        _self.getImages();
      }, 1000);
    },
    getImages() {
      this.marker = [];
      //  this.loopcout = false;
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + this.visitdata.id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success.length > 0) {
            console.log("selectedMedia", this.selectedMedia);

            var _self = this;
            this.dats = resp.data.success.filter(function(item) {
              console.log(
                "item.mediatype",
                item.mediatype,
                _self.selectedMedia
              );

              return item.mediatype == _self.selectedMedia;
            });
            console.log("visit data resp", this.dats);
            this.images = this.dats;

            this.coi = this.dats.length;

            var n = 0;
            this.markers = [];
            this.dats.forEach((i, index) => {
              if (i.latitude && i.longitude) {
                this.markers[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > ' +
                    i.id +
                    " </a>"
                };
              }
              console.log("n", n);
              n++;
              var _self = this;

              console.log("this.coi == n+1", this.coi, n);
              if (this.coi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.dats[0].latitude);
                  var lng = parseFloat(this.dats[0].longitude);
                  console.log("this.markers in", _self.markers);
                  _self.center = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoom = 15;
                }, 1000);
              }
            });
            this.preloader = false;
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = this.lang.imagesnotavailble;
          }
        })
        .catch(err => {
          this.preloader = false;
          console.log("Getting image Error ", err);
        });
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    fullscreenexit() {
      this.fulllImage = false;
      this.preloader = false;
      //console.log("hfghf", this.full);
    },
    fullpanaromaImage(d, index) {
      this.fulllImage = true;
      this.cimi = index;
      this.preloader = true;
      //  console.log("fullpanaromaImage", index);
      this.liveimage = d.image_path;
      this.infoxitem = d;
      if (this.selectedMedia == "Drone Video") {
        this.maplocationsviwer(d);
        this.maplocationsInfo(d);
      }
      var _self = this;
      setTimeout(() => {
        _self.$refs.custome_timelapsvideo.src = d.image_path;
        _self.preloader = false;
      }, 500);
      // this.$refs.custome_timelapsvideo.src = d.image_path;
    },
    Ontedd() {
      console.log(this.infoAddMode);
      this.infobox = true;

      console.log("markers", this.infoxitem);
    },
    infoboxsubmit() {
      // this.info_description;
      // this.info_title;
      // this.markdata.time;
      // this.markdata.distance;
      // this.markdata.height;
      // this.markindex;
      // this.infoxitem.id;

      var data = {
        description: this.info_description,
        title: this.info_title,
        time: this.markdata.time,
        distance: this.markdata.distance,
        height: this.markdata.height,
        pointindex: this.markindex,
        mediaid: this.infoxitem.id,
        project_id: this.projectid
      };

      console.log("data", data);

      axios({
        url: "/project/gps/drone-video-info",
        method: "post",
        data: data
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.recordadded;
          this.preloader = false;
          this.infobox = false;
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    videocontrollers(m, index) {
      if (this.selectedMedia == "Drone Video") {
        var theVideo = document.getElementById("cspd_video");
        // var vid_currentTime = theVideo.currentTime;

        if (this.infoAddMode) {
          console.log(this.infoAddMode);
          this.infobox = true;
          this.markdata = m;
          this.markindex = index;

          var ac = this.infolist.filter(function(item) {
            //  console.log("item.mediatype", item.mediatype, _self.selectedMedia);

            return item.pointindex == index;
          });

          if (ac.length > 0) {
            this.info_description = ac[0].description;
            this.info_title = ac[0].title;
            this.info_id = ac[0].id;

            this.infobox = false;
            this.updateinfobox = true;
          }
        } else {
          theVideo.currentTime = m.time;
        }
      } else {
        this.fulllImage = true;
        this.cimi = index;

        this.liveimage = this.images[index].image_path;
      }
    },
    maplocationsInfo(d) {
      console.log("d", d);
      axios({
        url: "/project/gps/drone-info",
        method: "get",
        params: {
          mediaid: d.id,
          project_id: this.projectid
        }
      })
        .then(resp => {
          console.log(resp.data.success);
          this.infolist = resp.data.success;
        })
        .catch(err => {
          console.log(err);
        });
    },
    maplocationsviwer(d) {
      this.loopcout = false;
      var _self = this;
      $.getJSON(d.gpsfile, function(mydata) {
        //  console.log(mydata);

        // console.log("mydata",d.gpsfile);
        _self.coi = mydata.length;

        var n = 0;
        _self.markers = [];
        mydata.forEach((i, index) => {
          if (i.lat && i.long) {
            // console.log("i",i);

            _self.markers[n] = {
              position: {
                lat: parseFloat(i.long),
                lng: parseFloat(i.lat)
              },
              infoText:
                '<a style="text-align:center" href="#' +
                index +
                '" > ' +
                i.id +
                " </a>",
              time: i.start,
              distance: i.distance,
              height: i.height
            };
          }
          //console.log("n", n);
          n++;

          //  console.log("this.coi == n+1", _self.coi, n);
          if (_self.coi == n) {
            setTimeout(() => {
              _self.loopcout = true;
              var lt = parseFloat(mydata[0].long);
              var lng = parseFloat(mydata[0].lat);
              // console.log("this.markers in", _self.markers);
              _self.center = {
                lat: lt,
                lng: lng
              };
              _self.zoom = 17;
            }, 1000);
          }
        });
      });

      _self.checkvideoloop();
    },
    checkvideoloop() {
      //
      // theVideo.currentTime = vid_currentTime - 5;

      //  this.getMarkers()
      var _self = this;
      //  var n = 1;
      setInterval(() => {
        var theVideo = document.getElementById("cspd_video");
        var vid_currentTime = theVideo.currentTime;
        // console.log("vid_currentTime", vid_currentTime)
        _self.activePosition = Math.round(vid_currentTime);
        //    n++;
      }, 1000);
    },
    //    getMarkers(key){

    //                 /// console.log(Math.round(key.time),this.activePosition, this.activePosition == Math.round(key.time));

    //       var _self =  this

    //             if (this.activePosition == Math.round(key.time)) {

    //         return _self.mapMarkerActive;
    //        }else{

    //          return _self.mapMarker;
    //        }

    //     },
    openbyDrop(index) {
      this.fulllImage = true;
      this.cimi = index;

      this.liveimage = this.images[index].image_path;
    },
    preImage() {
      var dataindex = this.cimi - 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage preImage", dataindex);

      var data = this.images[dataindex];

      this.liveimage = data.image_path;
    },
    nextImage() {
      var dataindex = this.cimi + 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage nextImage", dataindex);

      var data = this.images[dataindex];
      this.liveimage = data.image_path;
    },
    addVisitDate() {
      console.log("this  dater", this.picker);

      axios({
        url: "/project/" + this.projectid + "/gps/new_visit_date",
        method: "post",
        data: {
          project_id: this.projectid,
          visit_date: this.picker,
          createdby: this.userdata.id
        }
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          if (resp.data.error) {
            this.alertmessage = resp.data.error.message;
          } else {
            this.alertmessage = this.lang.visitdateadded;
            this.visitDatepopup = false;
            // this.dd = resp.data.success;
            console.log("resp.data.success.data.id", resp.data.success.data.id);
            //  this.selectVisitingDate(resp.data.success.data.id)
            this.visitdata.visit_date = resp.data.success.data.visit_date;
            this.visitdata.id = resp.data.success.data.id;
            localStorage.setItem(
              "currentvisitD",
              JSON.stringify(resp.data.success.data)
            );
            this.uploadzoneAction();
            //this.$router.go();
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },

    selectVisitingDate(d) {
      // this.storeVisitData(d)
      this.$store
        .dispatch("setVistId", d)
        .then(() => {
          console.log("d value is", d);
        })
        .catch(err => console.log(err));
    },

    getVisitDates() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      // console.log(this.currentProject);
      this.drawer = false;
      this.visitdatsss = {};
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit_date/",

        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          this.visitdatsss = resp.data.success;
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
    selectdate(event, mediatype) {
      this.selectedMedia = mediatype;
      localStorage.setItem("currentvisitD", JSON.stringify(event));
      this.visitdata = event;
      this.$store
        .dispatch("setVistId", event.id)
        .then(() => {
          //this.storeVisitData(event)

          this.galleryviewAction();
        })
        .catch(err => console.log(err));
    },
    gotoUpload(event) {
      localStorage.setItem("currentvisitD", JSON.stringify(event));
      this.visitdata = event;
      this.$store
        .dispatch("setVistId", event.id)
        .then(() => {
          //this.storeVisitData(event)

          this.uploadzoneAction();
        })
        .catch(err => console.log(err));
    },
    storeVisitData() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      var ss = this.visitdata;
      console.log("ss", ss.id);
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit/" + ss.id,
        method: "GET"
      })
        .then(resp => {
          console.log("visit data resp", resp);
          if (resp.data.success) {
            localStorage.setItem(
              "currentvisitD",
              JSON.stringify(resp.data.success)
            );
            this.visitdata = resp.data.success;
          } else {
            localStorage.setItem("currentvisitD", JSON.stringify({}));
          }
        })
        .catch(err => {
          console.log("Error in current visit data set", err);
        });
    },
    selecteditem() {
      if (this.selected.length > 0) {
        this.showdeletebuttons = true;
      } else {
        this.showdeletebuttons = false;
      }
    },
    removeselectedMedias() {
      var conf = confirm("Confirm delete this file(s).");

      //  console.log(this.selected, this.currentProject.id);

      if (conf) {
        this.preloader = true;
        axios({
          url: "/project/" + this.projectid + "/gps/remove-media-files",
          method: "post",
          data: {
            ids: this.selected,
            visit_id: this.visitdata.id,
            mediatype: this.selectedMedia
          }
        })
          .then(resp => {
            console.log("URL api data................", resp);

            this.preloader = false;

            this.alert = true;
            this.alertmessage = this.lang.selecteddelte;
            this.selected = [];
            this.showdeletebuttons = false;
            this.getImages();
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = this.lang.SomethingWentWrong;
            this.alertType = "error";
            this.preloader = false;
            this.showprogrss = false;
          });
      }
    },
    dronefileupload(f) {
      this.file = f;
      const reader = new FileReader();
      if (this.file.name.includes(".srt") || this.file.name.includes(".SRT")) {
        if (this.srtRadioButtonSelection == 'oldSrtFile') {
          const desiredKeys = Object.keys({ "id": null, "start": null, "end": null, "text": null, "long": null, "lat": null, "distance": null, "height": null });
          reader.onload = res => {
            var jsonSubs = parseSRT(res.target.result);
            const fileContentKeys = Object.keys(jsonSubs[0]);

            if (JSON.stringify(fileContentKeys) === JSON.stringify(desiredKeys)) {
              var temp = [];
              jsonSubs.forEach(item => {
                var pars = item.text.split(",");
                var lat = pars[3].split("GPS(");

                item.long = lat[1];
                item.lat = pars[4];
                item.distance = pars[6];
                item.height = pars[7];

                temp.push(item);
              });
              console.log("jsonSubs", temp);
              this.havedata = true;
              this.content = temp;
            } else {
              this.alert = true;
              this.alertmessage = "Wrong Srt File.";
            }
          };
        } else if(this.srtRadioButtonSelection ==  "newSrtFile") {
          reader.onload = res => {
            const desiredKeys = Object.keys({ "id": null, "start": null, "end": null, "text": null });
            var jsonSubs = parseSRT(res.target.result);
            const fileContentKeys = Object.keys(jsonSubs[0]);

            if (JSON.stringify(fileContentKeys) === JSON.stringify(desiredKeys)) {
              var temp = [];
               
              var cn = 0;
              
              jsonSubs.forEach((item, index) => {
                if(index % 120 == 0){
                 console.log("index",index, cn);
                var pars = item.text.split(",");
                const regex = /\[([^\]]+)\]/g;
                const matches = pars[3].match(regex);
                const result = {};

                matches.forEach(match => {
                  const [key, value] = match.slice(1, -1).split(': ');
                  result[key] = value;
                });

                item.long = result.latitude;
                item.lat = result.longitude;
                item.distance = 0;
                item.height = 0;

                temp.push(item);
                }
                cn++
              });
              console.log("jsonSubs", temp);
              this.havedata = true;
              this.content = temp;
            } else {
              this.alert = true;
              this.alertmessage = "Wrong Srt File.";
            }
          };
        }else if(this.srtRadioButtonSelection == "dji4miniSrtFile") {
          reader.onload = res => {
            const desiredKeys = Object.keys({ "id": null, "start": null, "end": null, "text": null });
            var jsonSubs = parseSRT(res.target.result);

            // console.log("res.target.result", res.target.result);
            const fileContentKeys = Object.keys(jsonSubs[0]);
             

           // console.log(JSON.stringify(fileContentKeys) === JSON.stringify(desiredKeys))

            if (JSON.stringify(fileContentKeys) === JSON.stringify(desiredKeys)) {
              var temp = [];
               
              var cnn = 0;

               console.log("jsonSubs",jsonSubs);
              
              jsonSubs.forEach((item, index) => {

                console.log("item",item);
                
                if(index % 120 == 0){
                 console.log("index",index, cnn);
                var pars = item.text.split("] [");
                 console.log("pars",pars);

                var latitude = pars[6]; //"latitude: 28.578448"
                var longitude = pars[7]; //"longitude: 77.372035"

                 var latparts = latitude.split(": ");

                 var longpart = longitude.split(": ");
             
                item.long = parseFloat(latparts[1]);
                item.lat = parseFloat(longpart[1]);
                item.distance = 0;
                item.height = 0;

                temp.push(item);
                }
                cnn++
              });
              console.log("jsonSubs", temp);
              this.havedata = true;
              this.content = temp;
            } else {
              this.alert = true;
              this.alertmessage = "Wrong Srt File.";
            }
          };
        } else if(this.srtRadioButtonSelection ==  "converted"){
         
          reader.onload = res => {
          //  const desiredKeys = Object.keys({ "id": null, "start": null, "end": null, "text": null });
            var blocks = res.target.result;

            let jsonsxd = blocks.trim().split('\n');

            console.log("jsonsxd",jsonsxd);

               this.havedata = true;

               var finalarray = [];

               jsonsxd.forEach((item, index) => {
                    var ss = JSON.parse(item);
                    
                   var c = JSON.parse(jsonsxd[index])

                   //console.log("ss",ss.);

                   ss.lat = c.long;
                   ss.long = c.lat;

                   // console.log(ss);
                
                    finalarray.push(ss);
               })

                console.log(finalarray);

              this.content = finalarray;
        
          };
        }
        reader.onerror = err => console.log(err);
        reader.readAsText(this.file);
      } else {
        this.content = "check the console for file output";
        reader.onload = res => {
          console.log(res.target.result);
        };
        reader.onerror = err => console.log(err);
        reader.readAsText(this.file);
      }
    },
    videosAdded() {
      var fileItem = document.getElementById("fileItem");
      var files = fileItem.files;
      var file = files[0];

      this.value = 0;

      console.log("---", file);

      if (file.size > 0) {
        var _self = this;
        setTimeout(() => {
          _self.getUrl(file);
          _self.value = 20;
          _self.showprogrss = true;
        }, 100);
       
      }
    },

    OrthophotoAdded() {
      var fileItem = document.getElementById("orthophoto");
      var files = fileItem.files;
      var file = files[0];

      this.value = 0;

      console.log("---", file);

      if (file.size > 0) {
        //var url = URL.createObjectURL(file);
        //console.log("d", url);
        var _self = this;
        setTimeout(() => {
          _self.getUrl(file);
          _self.value = 20;
          _self.showprogrss = true;
        }, 100);
       
      }
    },
    getUrl(file) {
      //console.log("File",  file);
      //  console.log("File Type", file.type);
      this.value = 30;
      axios({
        url: "interior/create/pre-signed-url",
        method: "post",
        data: {
          filename: file.name,
          filetype: file.type,
          proj_dir: this.pro_dir,
          zone: this.visitdata.visit_date
        }
      })
        .then(resp => {
          this.value = 50;
          //console.log("URL api data................", resp.data.success);
          var action = resp.data.success;
          if(this.selectedMediaupload == 'Orthophoto'){
            this.sendauthophotos(action, file);
          }else {
            this.sendVideo(action, file);
          }
         
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
        });
    },
    sendauthophotos(action, file) {
      this.value = 70;
      var options = {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: progressEvent => {
          var complete =
            (((progressEvent.loaded / progressEvent.total) * 100) | 0) + "%";
          this.uploadPercentage = complete;
        }
      };
      delete axios.defaults.headers.common["Authorization"];
      axios
        .put(action, file, options)
        .then(resp => {
          console.log("Upload resp", resp);

          this.value = 90;

          const token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          if(this.selectedMediaupload == 'Orthophoto'){
            this.saveDatadronimage(action);   //  it work for dron Orthophotos
          }else {
            this.saveDataUploadData(action);  // its work for dron video 
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
        });
    },
    sendVideo(action, file) {
      this.value = 70;
      var options = {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: progressEvent => {
          var complete =
            (((progressEvent.loaded / progressEvent.total) * 100) | 0) + "%";
          this.uploadPercentage = complete;
        }
      };
      delete axios.defaults.headers.common["Authorization"];
      axios
        .put(action, file, options)
        .then(resp => {
          console.log("Upload resp", resp);

          this.value = 90;

          const token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          console.log("selectedMediaupload",this.selectedMediaupload);

          if(this.selectedMediaupload == 'Orthophoto'){
            this.saveDatadronimage(action);   //  it work for dron Orthophotos
          }else {
            this.saveDataUploadData(action);  // its work for dron video 
          }
          
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
        });
    },

    saveDatadronimage(videoPath) {

//console.log("this.content", this.content);
var path = videoPath.split("?");

var orthdata = {
   project_id: this.projectid,
   proj_dir: this.pro_dir,
   user_id: this.userdata.id,
   visit_date: this.visitdata.visit_date,
   visit_id: this.visitdata.id,
   mediatype: this.selectedMediaupload,
   filename: "tif",
   path: path[0],
   gpsdata: '',
   videoUrl: '',
 } 

axios({
 url: "/project/gps/drone-video-upload",
 method: "post",
 data: {
   project_id: this.projectid,
   proj_dir: this.pro_dir,
   user_id: this.userdata.id,
   visit_date: this.visitdata.visit_date,
   visit_id: this.visitdata.id,
   mediatype: this.selectedMediaupload,
   filename: "tif",
   path: path[0],
   gpsdata: '',
   videoUrl: '',
 }
})
 .then(resp => {
   this.value = 100;
   console.log("URL api data................", resp);

   this.showprogrss = false;
   this.value = 0;
   this.vidoesss = "";
   this.alert = true;
   this.alertmessage = this.lang.videoupload;

   this.tileupload(orthdata);
   this.galleryviewAction();
 })
 .catch(err => {
   console.log(err);
   this.alert = true;
   this.alertmessage = this.lang.SomethingWentWrong;
   this.alertType = "error";
   this.preloader = false;
   this.showprogrss = false;
 });
},
tileupload(orthdata){

  delete axios.defaults.headers.common["Authorization"];

  console.log("orthdata",orthdata);

  var keypart = orthdata.path.split('001/');

  var keys = keypart[1];

  axios({
 url: "https://opticvyu.com/api/orthophotosprocess",
 method: "get",
 params : {
  key : keys,
  projectid : orthdata.project_id,
  visit_date: orthdata.visit_date
 }
  }).then(resp => {
    console.log(resp);
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }).catch (err => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    console.log(err)
  })
},
   saveDataUploadData(videoPath) {

       console.log("this.content", this.content);
      var path = videoPath.split("?");
      axios({
        url: "/project/gps/drone-video-upload",
        method: "post",
        data: {
          project_id: this.projectid,
          proj_dir: this.pro_dir,
          user_id: this.userdata.id,
          visit_date: this.visitdata.visit_date,
          visit_id: this.visitdata.id,
          mediatype: this.selectedMediaupload,
          filename: "video",
          path: path[0],
          gpsdata: this.content,
          videoUrl: this.srtVideoLink,
        }
      })
        .then(resp => {
          this.value = 100;
          console.log("URL api data................", resp);

          this.showprogrss = false;
          this.value = 0;
          this.vidoesss = "";
          this.alert = true;
          this.alertmessage = this.lang.videoupload;
          this.galleryviewAction();
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    reloadpage() {
      this.$router.go();
    },
    loaded() {
      var v = this;

      setTimeout(function() {
        v.preloader = false;
      }, 10);
    },
    taskboxopen() {
      console.log("taskboxopen", this.taskbox);
      this.taskbox = true;
    },
    editTask(data) {
      this.info_description = data.description;
      this.info_title = data.title;
      this.info_id = data.id;

      this.updateinfobox = true;
    },

    updateinfoboxsubmit() {
      this.preloader = true;
      var data = {
        description: this.info_description,
        title: this.info_title,
        info_id: this.info_id,
        project_id: this.projectid
      };

      console.log("data", data);

      axios({
        url: "/project/gps/update-drone-video-info",
        method: "post",
        data: data
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.RecordUpdatesuccessfully;
          this.preloader = false;
          this.updateinfobox = false;

          this.maplocationsInfo(this.infoxitem);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    deleteTask(id) {
      var s = confirm(this.lang.taskconfirmation);

      if (s) {
        this.preloader = true;
        //console.log("this.newTask");
        axios({
          url: "/project/gps/remove-drone-info",
          method: "post",
          data: {
            info_id: id,
            project_id: this.projectid
          }
        })
          .then(resp => {
            console.log("Remove data", resp.data.success);
            this.maplocationsInfo(this.infoxitem);
            this.alert = true;
            this.alertmessage = this.lang.recordDeleted;
            this.alertType = "success";
            this.preloader = false;
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = this.lang.SomethingWentWrong;
            this.alertType = "error";
            this.preloader = false;
          });
      }
    },
    openUpdateUrlPopup(n){
      this.droneVideoURL = n.drone_video_url;
      this.droneVideoID = n.id;
      this.updateUrlPopup = true;
    },
    updateVideoUrl(){
      this.preloader = true;
      var data = {
        droneVideoID: this.droneVideoID,
        droneVideoUrl: this.droneVideoURL
      };
      axios({
        url: "/project/gps/updateDroneDetail",
        method: "post",
        data: data
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = "URL Updated Successfully.";
          this.preloader = false;
          this.updateUrlPopup = false;

        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "Something went wrong.";
          this.alertType = "error";
          this.preloader = false;
        });
    },

    updateEditedVideoInfo(){
      this.preloader = true;
      axios({
        url: "/drone/" + this.projectid + "/add_new_edited_video",
          method: "post",
          data: {
            user_id: this.userdata.id,
            video_url: this.editedVideoUrl,
            video_remarks: this.editedVideoRemarks,
          }
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = "Info Added Successfully.";
          this.preloader = false;
          this.uploadEditedVideo = false;

        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "Something went wrong.";
          this.alertType = "error";
          this.preloader = false;
        });
    },
    setrouter: function (url) {
            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
            }

            //console.log(url);
        },
  }
};
</script>

<style>
.ttile {
  position: relative;
  top: 15px;
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
}

.media_type {
  position: relative;
  width: 166px;
  float: right;
}

.d_w {
  /*display: flex;*/

  position: relative;
  width: 100%;
  text-align: center;
  flex-direction: row;
  align-items: center;
}

.d_w div {
  background: #292929;
  /* padding: 15px 4px; */
  width: 20%;
  /* max-width: 20%; */
  max-height: 210px;
  min-height: 210px;
  align-items: center;
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
  color: #fff;
  float: left;
}

.d_w .d-flex {
  border-radius: 5px;
  position: relative;
  border: 2px solid #fff;
  transition: 0.5s;
}

.d_w div.active,
.d_w div:hover {
  border: 2px solid #f8bb03;
  background: #000;
  transition: 0.5s;
  box-shadow: inset 0 0 0px 2px #f8bb03;
}

.gitemcheck {
  position: absolute;
  top: 21px;
  left: 23px;
  z-index: 1;
}

.gitem {
  position: relative;
}

.progress {
  position: fixed;
  margin: 0;
  display: flex;
  text-align: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0000000d;
}

.fullviewbox_video.video {
  width: 50%;
  z-index: 1;
  background: #ffffff;
  /* margin-top: 116px; */
  height: 94vh;
}
.calenderit span {
  display: flex;
  align-items: center;
}
.calenderit {
  flex-direction: column;
}
span.days {
  font-size: 18px;
}
span.menufooterf {
  display: flex;
  flex-direction: column;
}

.menufooterf span.rt {
  margin: 5px 0;
  width: 115%;
}
.menufooterf span.rt button {
  width: 100%;
}
.rt strong {
  position: absolute;
  right: -10px;
  border: 1px solid;
  display: inline;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 19px;
  background: #f8bb03;
}
.uploadDrawing strong {
  position: relative;
  right: -10px;
  border: 1px solid;
  display: inline;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 19px;
}
.menufooterf .rt .v-btn--disabled.v-btn--has-bg {
  background-color: #d1c0c047 !important;
  color: #000 !important;
}
.uploadb {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
.dayadd {
  cursor: pointer;
}
.ovcheckinfo {
  /* width: 333px; */
  border-radius: 15px;
  position: ABSOLUTE;
  height: 58px;
  /* bottom: 48px; */
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
  left: 18px;
}
.ovcheckinfo button {
  background-color: #cccccc6e;
}
.info-items-edit-view {
  /* position: absolute;
    right: 10xp;
    top: 50px; */
  position: absolute;
  /* left: 5px; */
  top: 222px;
}

span.info_title {
  color: #ffc30e;
  background: #000000b3;
  font-weight: 600;
  font-size: 31px;
  padding: 3px 6px;
}

span.info_description {
  color: #fff;
  background: #000000;
  font-size: 14px;
  display: block;
  padding: 3px 6px;
}
.dddinfo {
  z-index: 99999;
  cursor: pointer;
}
.infobox {
  position: absolute;
  left: 1px;
  background: #fff;
  padding: 32px 6px;
  /* max-height: 93vh;
    min-height: 93vh; */
  top: 67px;
  z-index: 99;
  overflow: auto;
  height: 94vh;
}
.infobox .v-data-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #fff;
  left: 0;
}
.linear-exitbuttons {
  z-index: 999;
  float: right;
  top: 11px;
  right: 12px;
  position: absolute !important;
  background: #ffffff;
  border-radius: 50%;
  font-size: 24px !important;
}
.imagesSection {
  overflow: scroll;
  max-height: 94vh;
}
.maps {
  height: 94vh;
}
@media (max-width: 1348px) {
  .metadata .v-icon .notranslate .v-data-table-header__icon {
    float: right;
  }
}
/* .right .arrow .next-image{
    float: left;
} */
button.right.arrow.next-image {
  right: 52%;
}
button.linear-exitbuttons {
  right: 52%;
  /* top: 11%; */
  top: 77px;
}
.maps .vue-map-container {
  height: 86vh;
}
.srtRadioButton{
  margin-left: 44px;
}
.addUrlIconSpan i {
  font-size: 16px !important;
  color: #fec306 !important;
}
.addUrlIconSpan{
  position: absolute;
    z-index: 1;
    left: 38px;
    top: 14px;
}
.editedUploadVideoButton{
  padding-top: 22px;
  padding-bottom: 1px;
}
</style>
